import { Button } from "@flixbus/honeycomb-react";
import { mpcPathnames as pathnames } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import * as css from "./CreatePartnerButton.scss";

const CreatePartnerButton: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div data-id="creation-toolbar" className={css.creationToolbar}>
      <Button
        data-id="create-new-partner-button"
        appearance="secondary"
        onClick={() => navigate(pathnames.createPartner)}
      >
        <FormattedMessage id="navigation.createNewPartner" />
      </Button>
    </div>
  );
};

export default CreatePartnerButton;
