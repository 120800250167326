@use "variables" as *;

.outerWrapper {
  position: sticky;
  top: 0;
  z-index: 9;
  padding-top: $spacing-2;
  background-color: $bg-primary-color;
}

.outerWrapperScrolled {
  background-color: $bg-primary-color;
  box-shadow: $primary-box-shadow;
}

.innerWrapper {
  @include page-container;
}
