import { Grid, GridCol, Heading } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CreateCity from "../../../../city/createCity/CreateCity";
import FormikCitySelect from "../../../../city/formikCitySelect/FormikCitySelect";
import FormikAutocomplete from "../../../../shared/components/formikAutocomplete/FormikAutocomplete";
import { createCountryOptions } from "../../../../shared/helpers/autocompleteOptions/createOptions";
import sortOptionsByLabel from "../../../../shared/helpers/autocompleteOptions/sortOptionsByLabel";
import { useFindCountryOfCityLazyQuery } from "../../api/operations.generated";
import * as css from "./CityAndCountrySelect.scss";

export type Props = {
  readOnly: boolean;
};

const CityAndCountrySelect: React.FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();

  const [, , { setValue: setCountry }] = useField("countryCode");
  const [, , { setValue: setCity }] = useField("cityId");

  const [renderCityForm, setRenderCityForm] = React.useState(false);
  const [enterdValue, setEnteredValue] = React.useState("");

  const [getCountry] = useFindCountryOfCityLazyQuery({
    onCompleted: (data) => {
      setCountry(data?.findCity.countryCode ? data.findCity.countryCode : "");
    },
  });

  return (
    <Grid>
      <GridCol size={6}>
        <FormikCitySelect
          name="cityId"
          label={formatMessage({ id: "general.city" })}
          placeholder={formatMessage({ id: "city.select.placeholder" })}
          onCitySelected={(cityId) => getCountry({ variables: { cityId } })}
          onCreateNewCity={() => {
            setRenderCityForm(true);
          }}
          readOnly={readOnly}
          onInputChange={(value) => setEnteredValue(value)}
        />
      </GridCol>
      <GridCol size={6}>
        <FormikAutocomplete
          name="countryCode"
          label={formatMessage({ id: "general.country" })}
          placeholder={formatMessage({ id: "country.select.placeholder" })}
          options={sortOptionsByLabel(createCountryOptions())}
          readOnly={readOnly}
          required
          startsOnInputValueLength={0}
        />
      </GridCol>
      {renderCityForm ? (
        <GridCol size={12}>
          <div className={css.border}>
            <Heading sectionHeader size={4}>
              <FormattedMessage id="navigation.createNewCity" />
            </Heading>
            <CreateCity
              onCityCreated={({ id }) => {
                setCity(id);
                getCountry({ variables: { cityId: id } });
                setRenderCityForm(false);
              }}
              onCancel={() => {
                setRenderCityForm(false);
              }}
              inputValue={enterdValue}
              isNested
            />
          </div>
        </GridCol>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default CityAndCountrySelect;
