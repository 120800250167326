import { Icon, IconArrowDown, IconArrowUp } from "@flixbus/honeycomb-icons-react";
import { Button, Dropdown, DropdownItemComp } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./DropdownButton.scss";

export type DropdownButtonProps = {
  links: Array<DropdownItemComp>;
};

const DropdownButton: React.FC<DropdownButtonProps> = ({ links, children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Dropdown
      extraClasses={css.dropdown}
      links={links}
      onToggle={(isActive) => {
        setIsOpen(isActive);
      }}
    >
      <Button appearance="primary">
        {children}
        <Icon InlineIcon={isOpen ? IconArrowUp : IconArrowDown} />
      </Button>
    </Dropdown>
  );
};

export default DropdownButton;
