import {
  addNotification,
  contactEmail,
  DefaultInfoAlert,
  formatErrorMessage,
  legacyTranslate,
  NotificationType,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import { ScheduleStatus } from "../../shared/types/schema";
import { priceInvalidForScheduleVar } from "../../state/reactiveVariables/reactiveVariables";
import usePriceInvalidForSchedule from "../../state/usePriceInvalidForSchedule/usePriceInvalidForSchedule";
import {
  useFindScheduleForFooterQuery,
  usePublishPricesMutation,
  usePublishScheduleMutation,
  useUpdateScheduleStatusMutation,
  useValidateScheduleLazyQuery,
} from "./api/operations.generated";
import generateScheduleRejectedMailToLink from "./helpers/mailToGenerator";
import ScheduleFooterBar from "./ui/scheduleFooterBar/ScheduleFooterBar";

export type ScheduleFooterProps = {
  scheduleId: string;
  arePricesShown: boolean;
  onSwitchToPrices: () => void;
};

type PricePublishExtensionErrorType = {
  errorMessage: string;
  errorCode: number;
  infos: PricePublishInfoType[];
};

type PricePublishInfoType = {
  details: string[];
  message: string;
};

const ScheduleFooter: React.FC<ScheduleFooterProps> = ({
  scheduleId,
  arePricesShown,
  onSwitchToPrices,
}) => {
  const { formatMessage } = useIntl();
  const [submitForReviewTriggered, setSubmitForReviewTriggered] = React.useState(false);
  const [approveTriggered, setApproveTriggered] = React.useState(false);

  const [priceInvalidForSchedule, setPriceInvalidForSchedule] =
    usePriceInvalidForSchedule(priceInvalidForScheduleVar);

  const { data: scheduleData, error: queryError } = useFindScheduleForFooterQuery({
    variables: { scheduleId },
  });

  const [updateScheduleStatus] = useUpdateScheduleStatusMutation({
    onError: (error) =>
      addNotification({
        message: `An error occurred, please try again. ${formatErrorMessage(
          error.message
        )}`,
        type: NotificationType.Danger,
      }),
    onCompleted: (data) => {
      // Schedule rejected
      if (data.updateScheduleStatus.status === ScheduleStatus.WorkInProgress) {
        window.open(generateScheduleRejectedMailToLink(scheduleData?.findSchedule!!));

        addNotification({
          message: formatMessage({ id: "schedule.footer.reject.notification.success" }),
        });
      }

      if (data.updateScheduleStatus.status === ScheduleStatus.InReview) {
        addNotification({
          message: formatMessage({
            id: "schedule.footer.submitForReview.notification.success",
          }),
        });
      }

      if (data.updateScheduleStatus.status === ScheduleStatus.Approved) {
        addNotification({
          message: formatMessage({ id: "schedule.footer.approve.notification.success" }),
        });
      }
    },
  });

  const handleScheduleStatusUpdate = (status: ScheduleStatus): void => {
    updateScheduleStatus({ variables: { scheduleId, status } });
  };

  const [validateSchedule, { loading: validationLoading, data: validationData }] =
    useValidateScheduleLazyQuery({
      // https://github.com/apollographql/apollo-client/issues/9338#issuecomment-1059852988
      fetchPolicy: "cache-and-network",
      onError: (error) =>
        addNotification({
          message: `An error occurred, please try again. ${formatErrorMessage(
            error.message
          )}`,
          type: NotificationType.Danger,
        }),
      onCompleted: (data) => {
        if (data) {
          if (submitForReviewTriggered) {
            if (data.validateSchedule.isValid) {
              handleScheduleStatusUpdate(ScheduleStatus.InReview);
            }
          } else if (approveTriggered && data.validateSchedule.isValid) {
            handleScheduleStatusUpdate(ScheduleStatus.Approved);
          }

          setSubmitForReviewTriggered(false);
          setApproveTriggered(false);
        }
      },
    });

  const handleSubmitForReview = (): void => {
    setSubmitForReviewTriggered(true);
    validateSchedule({ variables: { scheduleId } });
  };

  const handleReject = (): void => {
    handleScheduleStatusUpdate(ScheduleStatus.WorkInProgress);
  };

  const handleApprove = (): void => {
    setApproveTriggered(true);
    validateSchedule({ variables: { scheduleId } });
  };

  const [publishSchedule] = usePublishScheduleMutation({
    onCompleted: () =>
      addNotification({
        message: formatMessage({ id: "schedule.footer.publish.notification.success" }),
      }),
    onError: (error) =>
      addNotification({
        message: `Schedule publish failed. ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
  });

  const handleSchedulePublish = () => {
    publishSchedule({ variables: { scheduleId } });
  };

  const [publishPrices] = usePublishPricesMutation({
    onCompleted: () => {
      addNotification({
        message: formatMessage({ id: "prices.footer.publish.notification.success" }),
      });
      if (priceInvalidForSchedule.scheduleId === scheduleId) {
        setPriceInvalidForSchedule({
          scheduleId: "",
          invalidPeriods: [],
          priceInvalidMessages: [],
        });
      }
    },
    onError: (error) => {
      let errorMessage = formatMessage({
        id: "prices.footer.publish.notification.failure.general",
      });
      const errorCode = error.graphQLErrors[0].extensions?.errorCode || 0;
      if (errorCode === 400) {
        // Price publish would have no effect, because prices would be mapped to no rides (user can't fix this issue)
        errorMessage = formatMessage(
          { id: "prices.footer.publish.notification.failure.noRides" },
          {
            supportEmail: contactEmail,
          }
        );
      } else if (errorCode === 422) {
        const errorPeriods = (
          error.graphQLErrors[0].extensions as PricePublishExtensionErrorType
        )?.infos
          .find((errorInfo) => errorInfo.message === "Dates")
          ?.details.map((periodString) => ({
            start: periodString.split(",")[0],
            end: periodString.split(",")[1],
          }));
        const errorMessages = (
          error.graphQLErrors[0].extensions as PricePublishExtensionErrorType
        )?.infos.find((errorInfo) => errorInfo.message === "Reasons")?.details;
        setPriceInvalidForSchedule({
          scheduleId,
          invalidPeriods: errorPeriods || [],
          priceInvalidMessages: errorMessages || [],
        });
        errorMessage = formatMessage({
          id: "prices.footer.publish.notification.failure.noPrices",
        });
      }
      addNotification({
        message: errorMessage,
        type: NotificationType.Danger,
      });
    },
  });

  const handlePricePublish = () => {
    publishPrices({ variables: { scheduleId } });
  };

  if (scheduleData) {
    return (
      <>
        <ScheduleFooterBar
          buttonLoading={validationLoading}
          schedule={scheduleData.findSchedule}
          onSubmitForReview={handleSubmitForReview}
          onReject={handleReject}
          onApprove={handleApprove}
          onSchedulePublish={handleSchedulePublish}
          onPricePublish={handlePricePublish}
          validationData={validationData?.validateSchedule}
          arePricesShown={arePricesShown}
          onSwitchToPrices={onSwitchToPrices}
        />
      </>
    );
  }

  if (queryError) {
    return (
      <DefaultInfoAlert
        message={formatMessage(
          { id: "schedule.footer.error.notAbleToLoad" },
          { scheduleId }
        )}
        translate={legacyTranslate}
      />
    );
  }

  return <></>;
};

export default ScheduleFooter;
