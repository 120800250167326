@use "variables" as *;
@use "../../helper/styles/styles.scss";

.container {
  margin-top: var(--hcr-spacing-2);
  // Will probably need to be adjusted after further development
  max-height: calc(100vh - 298px);
  border-left: styles.$border-light;
  border-right: styles.$border-light;
  overflow: hidden;
  margin-bottom: $spacing-2;
}

.controlElements {
  display: flex;
  justify-content: flex-end;
  gap: $spacing-2;
  margin-bottom: $spacing-2;
}

.scrollContainerWrapper {
  overflow: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.scrollContainer {
  height: 100%;
  overflow: clip;
  position: relative;
}

.contentWrapper {
  // Will probably need to be adjusted after further development
  height: calc(100vh - 336px);
  overflow-y: scroll;
  position: relative;
}
