@use "variables" as *;

.wrapper {
  display: grid;
  column-gap: $spacing-2;
}

.firstRow {
  grid-template-columns: auto auto 225px;
}

.row {
  grid-template-columns: auto auto;
}

.timeInputWrapper {
  display: flex;
  column-gap: $spacing-2;
}
