@use "variables" as *;

.labelWrapper {
  padding-bottom: 2px;
}

.label {
  color: $grayscale-90-color;
}

.choiceWrapper {
  > div {
    margin-bottom: 0;
  }

  label {
    display: flex !important;
  }
}
