import { MainWrapper, themeFlixDark, ThemeWrapper } from "@flixbus/honeycomb-react";
import {
  NavigationApp,
  AuthProvider,
  ErrorBoundary,
  getThemeSetting,
  isTestEnv,
  legacyTranslate,
  Navigation,
  Notification,
  StateProvider,
  TranslationProvider,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/router/Router";
import "normalize.css/normalize.css";
import typePolicies from "./state/typePolicies/typePolicies";

export const AppContent: React.FC = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary translate={legacyTranslate}>
        <StateProvider fetchPolicy="cache-first" typePolicies={typePolicies}>
          <MainWrapper full>
            <Navigation
              app={NavigationApp.MPC}
              isUsedInternal
              translate={legacyTranslate}
            />
            <Router />
          </MainWrapper>
          <Notification />
        </StateProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

const App: React.FC = () => {
  // User is Cypress
  if (isTestEnv() && process.env.NODE_ENV !== "production") {
    return (
      <ThemeWrapper theme="flix" style={{ minWidth: "1200px" }}>
        <TranslationProvider usesCookies isUsedInternal>
          <AppContent />
        </TranslationProvider>
      </ThemeWrapper>
    );
  }

  return (
    <ThemeWrapper
      id="theme-wrapper"
      theme={getThemeSetting()}
      themes={{ "flix-dark": themeFlixDark }}
      style={{ minWidth: "1200px" }}
    >
      <TranslationProvider usesCookies isUsedInternal>
        <AuthProvider translate={legacyTranslate}>
          <AppContent />
        </AuthProvider>
      </TranslationProvider>
    </ThemeWrapper>
  );
};

export default App;
