@use "variables" as *;

.link {
  color: $content-primary-color;
  text-decoration: none;
  cursor: pointer;
  &:visited {
    color: $content-primary-color;
  }
}
