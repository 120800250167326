@use "variables" as *;

.table {
  border: $border;
  border-radius: $border-radius;
  margin-top: $spacing-3;
}

.tableRow {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacing-2;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: $border;
  }

  &:hover {
    background-image: $hover-layer-color;
  }
}

.textWrapper {
  text-align: left;
}

.finePrint {
  color: $content-secondary-color;
}
