@use "variables" as *;

.nav {
  border-bottom: $border;
  margin-bottom: 0px;
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: $spacing-1;
}
