@use "../../helpers/styles/common-styles.scss";
@use "variables" as *;

.link {
  color: $content-primary-color;
  text-decoration: none;
  cursor: pointer;
  display: block;
  @include common-styles.tagOutline;

  &:visited {
    color: $content-primary-color;
  }
}
