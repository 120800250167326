@use "variables" as *;

.wrapper {
  height: 85px;
  background-color: $bg-primary-color;
  padding: $spacing-2 $spacing-4 $spacing-2 $spacing-4;
  align-items: center;
  border-top: $border;
}

.totalTripDurationWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing-2;
}

.estimatedTripDurationWrapper {
  display: flex;
  justify-content: space-between;
}

.label {
  flex-grow: 3;
  flex-basis: 0;
}

.duration {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
