@use "variables" as *;

.wrapper {
  margin-bottom: $spacing-2;
  display: flex;
  align-items: center;
}

.scheduleName {
  display: inline-block;
  margin-left: $spacing-2;
  width: 90%;
}
