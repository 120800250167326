@use "variables" as *;
@use "../../../../shared/styles/clickable-box.scss";
@use "../../../../shared/styles/list-styles.scss";

.lineGrid {
  grid-template-columns: 14% 71% 15%;
  height: 45px;
  min-width: 950px;
  border-right: 1px solid $grayscale-50-color;
  @include list-styles.list-item-grid;

  > div {
    padding: $spacing-2 $spacing-4;
    height: 45px;
  }
}

.clickableBox {
  @include clickable-box.clickableBox;
}

.lineCode {
  font-size: 16px;
  font-weight: bolder;
}

.lineDescription {
  max-width: 700px;
}

.lineTag {
  justify-self: center;
}

.tooltipContent {
  white-space: normal;
}
