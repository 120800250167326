import { useVirtualizer } from "@tanstack/react-virtual";
import * as React from "react";
import findChunk from "../../helper/findChunk/findChunk";
import {
  contentCellHeight,
  contentCellWidthDaily,
  contentCellWidthMonthly,
  headerHeight,
  stickyHeaderHeight,
} from "../../helper/styles/sizes";
import { Calendar, CalendarIntervalType } from "../../helper/types";
import CalendarContentCell from "../calendarContentCell/CalendarContentCell";
import DayHeaderCell from "../dayHeaderCell/DayHeaderCell";
import MonthHeaderCell from "../monthHeaderCell/MonthHeaderCell";
import ScrollNavigation from "../scrollNavigation/ScrollNavigation";
import StickyHeaderRow from "../stickyHeaderRow/StickyHeaderRow";
import ZoomControl from "../zoomControl/ZoomControl";
import * as css from "./CalendarVirtualizer.scss";

export type CalendarVirtualizerProps = {
  calendar: Calendar;
  displayDates: Date[];
  stickyHeaderDates: Date[];
  initialOffsetInPixel: number;
  initialOffsetIndex: number;
  onIntervalTypeChange: (selectedIntervalType: CalendarIntervalType) => void;
};

const CalendarVirtualizer: React.FC<CalendarVirtualizerProps> = ({
  calendar,
  displayDates,
  stickyHeaderDates,
  initialOffsetInPixel,
  initialOffsetIndex,
  onIntervalTypeChange,
}) => {
  const isDaily = calendar.intervalType === CalendarIntervalType.DAILY;

  const parentRef = React.useRef(null);
  const [hoveredSchedule, setHoveredSchedule] = React.useState<string>();

  const colVirtualizer = useVirtualizer({
    count: displayDates.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => (isDaily ? contentCellWidthDaily : contentCellWidthMonthly),
    overscan: 5,
    horizontal: true,
    initialOffset: initialOffsetInPixel,
  });

  const rowVirtualizer = useVirtualizer({
    count: calendar.rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => contentCellHeight,
    overscan: 1,
    horizontal: false,
  });

  const scroll = (index: number) => {
    colVirtualizer.scrollToIndex(index, {
      align: "start",
      behavior: "smooth",
    });
  };

  return (
    <>
      {colVirtualizer.range && (
        <div className={css.controlElements}>
          <ZoomControl
            onIntervalTypeChange={onIntervalTypeChange}
            selectedIntervalType={calendar.intervalType}
          />

          <ScrollNavigation
            onScroll={(steps) => {
              scroll(colVirtualizer.range!.startIndex + steps);
            }}
            scrollToInitialOffset={() => {
              scroll(initialOffsetIndex);
            }}
            initialOffsetIndex={initialOffsetIndex}
            currentStartIndex={colVirtualizer.range.startIndex}
            currentEndIndex={colVirtualizer.range.endIndex}
            lastItemIndex={displayDates.length - 1}
            fastScrollSpeed={isDaily ? 18 : 11}
          />
        </div>
      )}

      <div className={css.container}>
        <div
          className={css.scrollContainerWrapper}
          ref={parentRef}
          style={{
            height: `${rowVirtualizer.getTotalSize() + headerHeight + stickyHeaderHeight}px`,
          }}
        >
          <div
            className={css.scrollContainer}
            style={{
              width: `${colVirtualizer.getTotalSize()}px`,
            }}
          >
            <StickyHeaderRow
              displayDates={stickyHeaderDates}
              parentRef={parentRef}
              initialOffset={initialOffsetInPixel}
              intervalType={calendar.intervalType}
            />

            {colVirtualizer.getVirtualItems().map((virtualItem) => {
              if (isDaily) {
                return (
                  <DayHeaderCell
                    key={virtualItem.index}
                    currentDate={displayDates[virtualItem.index]}
                    cellWidth={virtualItem.size}
                    colStart={virtualItem.start}
                  />
                );
              }

              return (
                <MonthHeaderCell
                  key={virtualItem.index}
                  currentDate={displayDates[virtualItem.index]}
                  cellWidth={virtualItem.size}
                  colStart={virtualItem.start}
                />
              );
            })}
            <div className={css.contentWrapper}>
              {rowVirtualizer.getVirtualItems().map((virtualRowItem) => {
                return colVirtualizer.getVirtualItems().map((virtualColItem) => {
                  return (
                    <CalendarContentCell
                      key={virtualColItem.index}
                      intervalType={calendar.intervalType}
                      currentDate={displayDates[virtualColItem.index]}
                      chunk={findChunk(
                        calendar,
                        displayDates[virtualColItem.index],
                        virtualRowItem.index
                      )}
                      virtualRowItem={virtualRowItem}
                      virtualColItem={virtualColItem}
                      hoveredSchedule={hoveredSchedule}
                      setHoveredSchedule={(scheduleId) =>
                        setHoveredSchedule(scheduleId || undefined)
                      }
                    />
                  );
                });
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalendarVirtualizer;
