@use "variables" as *;

.wrapper {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: $spacing-1;
  color: $content-secondary-color;
}
