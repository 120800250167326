// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindScheduleStatusQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type FindScheduleStatusQuery = {
  __typename?: "Query";
  findSchedule: { __typename?: "Schedule"; id: string; status: Types.ScheduleStatus };
};

export const FindScheduleStatusDocument = gql`
  query FindScheduleStatus($id: ID!) {
    findSchedule(id: $id) {
      id
      status
    }
  }
`;

/**
 * __useFindScheduleStatusQuery__
 *
 * To run a query within a React component, call `useFindScheduleStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindScheduleStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindScheduleStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindScheduleStatusQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindScheduleStatusQuery,
    FindScheduleStatusQueryVariables
  > &
    ({ variables: FindScheduleStatusQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindScheduleStatusQuery,
    FindScheduleStatusQueryVariables
  >(FindScheduleStatusDocument, options);
}
export function useFindScheduleStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindScheduleStatusQuery,
    FindScheduleStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindScheduleStatusQuery,
    FindScheduleStatusQueryVariables
  >(FindScheduleStatusDocument, options);
}
export function useFindScheduleStatusSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindScheduleStatusQuery,
        FindScheduleStatusQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindScheduleStatusQuery,
    FindScheduleStatusQueryVariables
  >(FindScheduleStatusDocument, options);
}
export type FindScheduleStatusQueryHookResult = ReturnType<
  typeof useFindScheduleStatusQuery
>;
export type FindScheduleStatusLazyQueryHookResult = ReturnType<
  typeof useFindScheduleStatusLazyQuery
>;
export type FindScheduleStatusSuspenseQueryHookResult = ReturnType<
  typeof useFindScheduleStatusSuspenseQuery
>;
