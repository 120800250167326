import { IconAttentionSolid } from "@flixbus/honeycomb-icons-react";
import { Box, Button, ButtonGroup, PopupSection, Tag } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Validation422 } from "../../../helper/typeguards/typeguards";
import ImportPopupButtonWrapper from "../../importPopupButtonWrapper/ImportPopupButtonWrapper";
import ImportPopupHeader from "../../importPopupHeader/ImportPopupHeader";
import * as css from "./Validated422.scss";

export type Validated422Props = {
  errorRows: Validation422["errorsPerRow"];
  onCancel: () => void;
  onBack: () => void;
  type: "station" | "city";
};

const Validated422: React.FC<Validated422Props> = ({
  errorRows,
  onCancel,
  onBack,
  type,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <ImportPopupHeader Icon={IconAttentionSolid} color="danger">
        <FormattedMessage id={`importPopup.validation422.headline.${type}`} />
      </ImportPopupHeader>

      <PopupSection type="content">
        <div
          className={css.text}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: formatMessage(
              { id: `importPopup.validation422.text.${type}` },
              {},
              { ignoreTag: true }
            ),
          }}
        />
        <Box small extraClasses={css.box}>
          {errorRows.map(({ row, errors }) => {
            return (
              <div key={row} className={css.errorRow}>
                <Tag extraClasses={css.tag} appearance="danger" display="outlined" small>
                  <FormattedMessage
                    id="importPopup.validation422.rowIndicator"
                    values={{ row }}
                  />
                </Tag>
                <div>
                  {errors.map((error) => {
                    return <div key={error}>{error}</div>;
                  })}
                </div>
              </div>
            );
          })}
        </Box>
      </PopupSection>

      <ImportPopupButtonWrapper>
        <ButtonGroup alignment="center">
          <Button onClick={onCancel}>
            <FormattedMessage id="general.cancel" />
          </Button>
          <Button appearance="primary" onClick={onBack}>
            <FormattedMessage id="general.back" />
          </Button>
        </ButtonGroup>
      </ImportPopupButtonWrapper>
    </>
  );
};

export default Validated422;
