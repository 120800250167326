@use "variables" as *;

.panelHeader {
  justify-content: space-between;
  height: 82px;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
}

.heading {
  margin-bottom: $spacing-half;
}

.text {
  font-size: 14px;
  color: $content-secondary-color;
}

.panelContent {
  min-width: $page-min-width;
  max-width: $page-max-width;
  width: 100%;
  padding-left: $spacing-2;
  padding-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}
