import { Icon, IconCheckmarkStrong, IconDownload } from "@flixbus/honeycomb-icons-react";
import { Button, PopupSection } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ImportPopupButtonWrapper from "../../importPopupButtonWrapper/ImportPopupButtonWrapper";
import ImportPopupHeader from "../../importPopupHeader/ImportPopupHeader";

export type UploadedProps = {
  uploadedElements: number;
  onDownload: () => void;
  onClose: () => void;
  type: "station" | "city";
};

const Uploaded: React.FC<UploadedProps> = ({
  uploadedElements,
  onDownload,
  onClose,
  type,
}) => {
  const { formatPlural } = useIntl();

  return (
    <>
      <ImportPopupHeader Icon={IconCheckmarkStrong} color="success">
        <FormattedMessage
          id={`importPopup.${type}.success.headline.${formatPlural(uploadedElements)}`}
          values={{ amount: uploadedElements }}
        />
      </ImportPopupHeader>

      <PopupSection type="content">
        <Button appearance="secondary" onClick={onDownload}>
          <Icon InlineIcon={IconDownload} />
          <FormattedMessage id={`importPopup.downloadUUID.button.text.${type}`} />
        </Button>
      </PopupSection>

      <ImportPopupButtonWrapper>
        <Button onClick={onClose}>
          <FormattedMessage id="general.close" />
        </Button>
      </ImportPopupButtonWrapper>
    </>
  );
};

export default Uploaded;
