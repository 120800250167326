import {
  Icon,
  IconCheckmarkStrong,
  IconArchive,
  IconCopy,
  IconDelete,
  IconEdit,
  IconList,
  IconRefresh,
  IconSettingsSolid,
} from "@flixbus/honeycomb-icons-react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownItemComp,
} from "@flixbus/honeycomb-react";
import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import { DisplayedScheduleStatus, ScheduleStatus } from "../../../../shared/types/schema";
import getIfCreateConceptChangeIsPossible from "../../helpers/getIfCreateConceptChangeIsPossible/getIfCreateConceptChangeIsPossible";
import * as css from "./ScheduleActionsButton.scss";

export type ScheduleActionsButtonProps = {
  onActivateOnSale: () => void;
  onArchiveSchedule: () => void;
  onCopy: () => void;
  onCreateConceptChange: () => void;
  onStatusHistory: () => void;
  onDelete: () => void;
  onChangeStatus: () => void;
  scheduleStatus: ScheduleStatus;
  displayedScheduleStatus: DisplayedScheduleStatus;
  originalScheduleStatus: ScheduleStatus;
  readOnly: boolean;
};

const ScheduleActionsButton: React.FC<ScheduleActionsButtonProps> = ({
  onActivateOnSale,
  onArchiveSchedule,
  onCopy,
  onCreateConceptChange,
  onStatusHistory,
  onDelete,
  onChangeStatus,
  scheduleStatus,
  displayedScheduleStatus,
  originalScheduleStatus,
  readOnly,
}) => {
  const { formatMessage } = useIntl();

  const dropdownContent: DropdownItemComp[] = [];

  if (
    hasUserPermission(Feature.EDIT_SCHEDULE_ACTIVATE_ON_SALE) &&
    [DisplayedScheduleStatus.Hidden, DisplayedScheduleStatus.PartiallyOnSale].includes(
      displayedScheduleStatus
    )
  ) {
    dropdownContent.push(
      <DropdownItem
        key="activate-on-sale"
        data-id="schedule-action-activate-on-sale"
        Elem="button"
        InlineIcon={IconCheckmarkStrong}
        onClick={onActivateOnSale}
        extraClasses={css.borderBottom}
      >
        {formatMessage({ id: "scheduleActions.activateOnSale.label" })}
      </DropdownItem>
    );
  }
  if (hasUserPermission(Feature.CREATE_SCHEDULE_CONCEPT_CHANGE)) {
    const isCreateConceptChangePossible = getIfCreateConceptChangeIsPossible(
      scheduleStatus,
      originalScheduleStatus
    );

    dropdownContent.push(
      <DropdownItem
        key="concept-change"
        data-id="schedule-action-concept-change"
        Elem="button"
        InlineIcon={IconRefresh}
        onClick={isCreateConceptChangePossible ? onCreateConceptChange : undefined}
        disabled={!isCreateConceptChangePossible}
      >
        {formatMessage({ id: "scheduleActions.conceptChange.label" })}
      </DropdownItem>
    );
  }

  if (hasUserPermission(Feature.CREATE_SCHEDULE_COPY)) {
    dropdownContent.push(
      <DropdownItem
        key="copy-schedule"
        data-id="schedule-action-copy"
        InlineIcon={IconCopy}
        Elem="button"
        onClick={onCopy}
      >
        {formatMessage({ id: "scheduleActions.copy.label" })}
      </DropdownItem>
    );
  }

  if (
    hasUserPermission(Feature.EDIT_SCHEDULE_ARCHIVE) &&
    [
      DisplayedScheduleStatus.Hidden,
      DisplayedScheduleStatus.OnSale,
      DisplayedScheduleStatus.PartiallyOnSale,
    ].includes(displayedScheduleStatus)
  ) {
    dropdownContent.push(
      <DropdownItem
        key="archive-schedule"
        data-id="schedule-action-archive-schedule"
        InlineIcon={IconArchive}
        Elem="button"
        onClick={onArchiveSchedule}
        extraClasses={css.borderTop}
      >
        {formatMessage({ id: "scheduleActions.archiveSchedule.label" })}
      </DropdownItem>
    );
  }

  if (hasUserPermission(Feature.DELETE_SCHEDULE) && !readOnly) {
    dropdownContent.push(
      <DropdownItem
        key="delete-schedule"
        data-id="schedule-action-delete"
        InlineIcon={IconDelete}
        Elem="button"
        onClick={onDelete}
        extraClasses={css.borderTop}
      >
        {formatMessage({ id: "schedule.delete" })}
      </DropdownItem>
    );
  }

  if (hasUserPermission(Feature.VIEW_SCHEDULE_STATUS_HISTORY)) {
    dropdownContent.push(
      <DropdownItem
        key="status-history"
        data-id="schedule-action-status-history"
        InlineIcon={IconList}
        Elem="button"
        onClick={onStatusHistory}
        extraClasses={css.borderTop}
      >
        {formatMessage({ id: "scheduleActions.statusHistory.label" })}
      </DropdownItem>
    );
  }

  if (hasUserPermission(Feature.EDIT_SCHEDULE_STATUS)) {
    dropdownContent.push(
      <DropdownItem
        key="change-schedule-status"
        data-id="schedule-action-change-status"
        InlineIcon={IconEdit}
        Elem="button"
        onClick={onChangeStatus}
        disabled={false}
      >
        Change Status
      </DropdownItem>
    );
  }

  return (
    <div className={css.wrapper}>
      <Dropdown links={dropdownContent} extraClasses={css.dropdown}>
        <Button
          data-id="schedule-actions-button"
          appearance="secondary"
          display="square"
          aria-label="schedule actions"
        >
          <Icon InlineIcon={IconSettingsSolid} />
        </Button>
      </Dropdown>
    </div>
  );
};

export default ScheduleActionsButton;
