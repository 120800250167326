// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type SearchOperatorsAsOptionsQueryVariables = Types.Exact<{
  name: Types.Scalars["String"]["input"];
}>;

export type SearchOperatorsAsOptionsQuery = {
  __typename?: "Query";
  searchOperators: Array<{ __typename?: "Operator"; value: string; label: string }>;
};

export const SearchOperatorsAsOptionsDocument = gql`
  query searchOperatorsAsOptions($name: String!) {
    searchOperators(name: $name) {
      value: uuid
      label: name
    }
  }
`;

/**
 * __useSearchOperatorsAsOptionsQuery__
 *
 * To run a query within a React component, call `useSearchOperatorsAsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOperatorsAsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOperatorsAsOptionsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSearchOperatorsAsOptionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SearchOperatorsAsOptionsQuery,
    SearchOperatorsAsOptionsQueryVariables
  > &
    (
      | { variables: SearchOperatorsAsOptionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SearchOperatorsAsOptionsQuery,
    SearchOperatorsAsOptionsQueryVariables
  >(SearchOperatorsAsOptionsDocument, options);
}
export function useSearchOperatorsAsOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SearchOperatorsAsOptionsQuery,
    SearchOperatorsAsOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SearchOperatorsAsOptionsQuery,
    SearchOperatorsAsOptionsQueryVariables
  >(SearchOperatorsAsOptionsDocument, options);
}
export function useSearchOperatorsAsOptionsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        SearchOperatorsAsOptionsQuery,
        SearchOperatorsAsOptionsQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    SearchOperatorsAsOptionsQuery,
    SearchOperatorsAsOptionsQueryVariables
  >(SearchOperatorsAsOptionsDocument, options);
}
export type SearchOperatorsAsOptionsQueryHookResult = ReturnType<
  typeof useSearchOperatorsAsOptionsQuery
>;
export type SearchOperatorsAsOptionsLazyQueryHookResult = ReturnType<
  typeof useSearchOperatorsAsOptionsLazyQuery
>;
export type SearchOperatorsAsOptionsSuspenseQueryHookResult = ReturnType<
  typeof useSearchOperatorsAsOptionsSuspenseQuery
>;
