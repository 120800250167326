// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindCountryOfCityQueryVariables = Types.Exact<{
  cityId: Types.Scalars["ID"]["input"];
}>;

export type FindCountryOfCityQuery = {
  __typename?: "Query";
  findCity: { __typename?: "City"; id: string; countryCode?: string | null };
};

export const FindCountryOfCityDocument = gql`
  query FindCountryOfCity($cityId: ID!) {
    findCity(id: $cityId) {
      id
      countryCode
    }
  }
`;

/**
 * __useFindCountryOfCityQuery__
 *
 * To run a query within a React component, call `useFindCountryOfCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCountryOfCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCountryOfCityQuery({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useFindCountryOfCityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindCountryOfCityQuery,
    FindCountryOfCityQueryVariables
  > &
    ({ variables: FindCountryOfCityQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindCountryOfCityQuery,
    FindCountryOfCityQueryVariables
  >(FindCountryOfCityDocument, options);
}
export function useFindCountryOfCityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindCountryOfCityQuery,
    FindCountryOfCityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindCountryOfCityQuery,
    FindCountryOfCityQueryVariables
  >(FindCountryOfCityDocument, options);
}
export function useFindCountryOfCitySuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindCountryOfCityQuery,
        FindCountryOfCityQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindCountryOfCityQuery,
    FindCountryOfCityQueryVariables
  >(FindCountryOfCityDocument, options);
}
export type FindCountryOfCityQueryHookResult = ReturnType<
  typeof useFindCountryOfCityQuery
>;
export type FindCountryOfCityLazyQueryHookResult = ReturnType<
  typeof useFindCountryOfCityLazyQuery
>;
export type FindCountryOfCitySuspenseQueryHookResult = ReturnType<
  typeof useFindCountryOfCitySuspenseQuery
>;
