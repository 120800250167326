import { Grid, GridCol, Heading } from "@flixbus/honeycomb-react";
import { Form, Formik } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CustomFormRow from "../../shared/components/customFormRow/CustomFormRow";
import FormikInput from "../../shared/components/formikInput/FormikInput";
import { LineEditableFields } from "../../shared/helpers/getEditableFormFields/types";
import {
  CooperationType,
  CutOffSalesRule,
  GeneralSettingsInput,
  MeansOfTransport,
  Partner,
  TimeUnit,
} from "../../shared/types/schema";
import BrandSelect from "./container/brandSelect/BrandSelect";
import validationSchema from "./helpers/validationSchema/validationSchema";
import { GeneralSettingsDefaultValues, GeneralSettingsInitialValues } from "./types";
import CooperationTypeSelect from "./ui/cooperationTypeSelect/CooperationTypeSelect";
import CutOffSalesSelector from "./ui/cutOffSalesSelector/CutOffSalesSelector";
import LineCodeInput, { LineCodeOption } from "./ui/lineCodeInput/LineCodeInput";
import MeansOfTransportSelect from "./ui/meansOfTransportSelect/MeansOfTransportSelect";

export type LineGeneralSettingsFormProps = {
  onSubmit: (values: GeneralSettingsInput) => void;
  onCancel: () => void;
  defaultValues?: Partial<GeneralSettingsDefaultValues>;
  editableFields?: LineEditableFields["generalSettings"];
  isLoadingData?: boolean;
  dataLoadingHasError?: boolean;
  partnerId: Partner["id"];
  isUsedForCreation: boolean;
  hasDuplicatedLineCodeError?: boolean;
};

const LineGeneralSettingsForm: React.FC<LineGeneralSettingsFormProps> = ({
  onSubmit,
  onCancel,
  defaultValues,
  editableFields,
  children,
  isLoadingData = false,
  dataLoadingHasError = false,
  partnerId,
  isUsedForCreation,
  hasDuplicatedLineCodeError,
}) => {
  const { formatMessage } = useIntl();

  const cutOffSalesValueInMinutes =
    defaultValues?.cutOffSales?.cutOffSalesDurationInMinutes || 0;
  const cutOffSalesValueIsFullHours =
    cutOffSalesValueInMinutes > 0 && cutOffSalesValueInMinutes % 60 === 0;

  const initialValues: GeneralSettingsInitialValues = {
    networkDescription: defaultValues?.networkDescription || "",
    cooperationType: defaultValues?.cooperationType || CooperationType.S,
    brandId: defaultValues?.brandId || "",
    cutOffSales: {
      cutOffSalesRule:
        defaultValues?.cutOffSales?.cutOffSalesRule || CutOffSalesRule.None,
      cutOffSalesDuration: {
        value: defaultValues?.cutOffSales?.cutOffSalesDurationInMinutes
          ? cutOffSalesValueIsFullHours
            ? cutOffSalesValueInMinutes / 60
            : cutOffSalesValueInMinutes
          : 0,
        unit: cutOffSalesValueIsFullHours ? TimeUnit.Hours : TimeUnit.Minutes,
      },
    },
    meansOfTransport: defaultValues?.meansOfTransport,
    networkCodeType: defaultValues?.customNetworkCode
      ? LineCodeOption.Custom
      : LineCodeOption.Auto,
    customNetworkCode: defaultValues?.customNetworkCode || "",
  };

  return (
    <Formik
      validationSchema={validationSchema(
        isUsedForCreation,
        hasDuplicatedLineCodeError ? defaultValues?.customNetworkCode : undefined
      )}
      initialValues={initialValues}
      onSubmit={(values) => {
        const submittableValues: GeneralSettingsInput = {
          networkDescription: values.networkDescription,
          cooperationType: values.cooperationType,
          brandId: values.brandId || "",
          cutOffSales: {
            cutOffSalesRule: values.cutOffSales.cutOffSalesRule,
            cutOffSalesDurationInMinutes:
              values.cutOffSales.cutOffSalesDuration.unit === TimeUnit.Hours
                ? values.cutOffSales.cutOffSalesDuration.value * 60
                : values.cutOffSales.cutOffSalesDuration.value,
          },
          meansOfTransport: values.meansOfTransport || MeansOfTransport.Bus,
        };

        if (isUsedForCreation) {
          submittableValues.customNetworkCode = values.customNetworkCode
            ? values.customNetworkCode.toUpperCase()
            : null;
        }

        onSubmit(submittableValues);
      }}
      onReset={onCancel}
      enableReinitialize
      initialErrors={{
        customNetworkCode: hasDuplicatedLineCodeError
          ? formatMessage({ id: "lineForm.lineCode.input.error.duplicate" })
          : undefined,
      }}
      initialTouched={{
        customNetworkCode: hasDuplicatedLineCodeError,
      }}
    >
      <Form noValidate>
        <Grid>
          <GridCol size={12}>
            <Heading data-id="line-detail-heading" size={3}>
              <FormattedMessage id="lineForm.details.headline" />
            </Heading>
          </GridCol>

          <GridCol size={3}>
            <CustomFormRow>
              <FormikInput
                name="networkDescription"
                type="text"
                label={formatMessage({ id: "lineForm.lineDescription.input.label" })}
                placeholder={formatMessage({
                  id: "lineForm.lineDescription.input.placeholder",
                })}
                required
                readOnly={!editableFields?.networkDescription}
              />
            </CustomFormRow>
          </GridCol>

          <GridCol size={9}>
            <CustomFormRow>
              <LineCodeInput
                readOnly={!editableFields?.customNetworkCode}
                hideSelectGroup={!isUsedForCreation}
              />
            </CustomFormRow>
          </GridCol>

          <GridCol size={3}>
            <CustomFormRow>
              <BrandSelect partnerId={partnerId} readOnly={!editableFields?.brandId} />
            </CustomFormRow>
          </GridCol>

          <GridCol size={3}>
            <CustomFormRow>
              <MeansOfTransportSelect
                readOnly={!editableFields?.meansOfTransport}
                isLoading={isLoadingData}
                hasError={dataLoadingHasError}
              />
            </CustomFormRow>
          </GridCol>

          {editableFields?.cooperationType.isShown ? (
            <GridCol inline>
              <CustomFormRow>
                <CooperationTypeSelect
                  readOnly={!editableFields?.cooperationType.isEditable}
                />
              </CustomFormRow>
            </GridCol>
          ) : (
            <></>
          )}
        </Grid>
        {editableFields?.cutOffSales.isShown && (
          <Grid>
            <GridCol size={7}>
              <CustomFormRow>
                <CutOffSalesSelector readOnly={!editableFields?.cutOffSales.isEditable} />
              </CustomFormRow>
            </GridCol>
          </Grid>
        )}
        {children}
      </Form>
    </Formik>
  );
};

export default LineGeneralSettingsForm;
