import { ButtonGroup, PopupSection } from "@flixbus/honeycomb-react";
import * as React from "react";

const ImportPopupButtonWrapper: React.FC = ({ children }) => {
  return (
    <PopupSection type="actions">
      <ButtonGroup alignment="center">{children}</ButtonGroup>
    </PopupSection>
  );
};

export default ImportPopupButtonWrapper;
