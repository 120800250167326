@use "variables" as *;

.icon {
  color: $icon-primary-color;
  margin-bottom: $spacing-2;
}

.danger {
  color: $warning-dark-color;
}

.neutral {
  color: $icon-primary-color;
}

.success {
  color: $icon-secondary-color;
}
