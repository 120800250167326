import { NavHorizontal, NavItem } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import * as css from "./HorizontalNavigation.scss";

export enum HorizontalNavigationDisplayedTab {
  ONE,
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX,
}

export type HorizontalNavigationProps = {
  displayedTab: HorizontalNavigationDisplayedTab;
  onTabClicked: (clickedTab: HorizontalNavigationDisplayedTab) => void;
  labels: Array<{ text: string; position: HorizontalNavigationDisplayedTab }>;
  blockDisplay?: boolean;
};

const HorizontalNavigation: React.FC<HorizontalNavigationProps> = ({
  displayedTab,
  onTabClicked,
  labels,
  blockDisplay,
}) => {
  return (
    <NavHorizontal
      extraClasses={cx(blockDisplay ? css.block : "", css.nav)}
      aria-label={`switch between ${labels.map((label) => label.text)}`}
    >
      {labels.map((label) => {
        return (
          <NavItem
            key={label.position}
            Elem={displayedTab === label.position ? "span" : "button"}
            active={displayedTab === label.position}
            onClick={() => onTabClicked(label.position)}
          >
            {label.text}
          </NavItem>
        );
      })}
    </NavHorizontal>
  );
};

export default HorizontalNavigation;
