@use "variables" as *;
@use "../../styles/inline-edit.scss";

.flexBox {
  align-items: center;
  @include inline-edit.flex-box;
}

.inputFlex {
  @include inline-edit.input-flex;
}

.notEditableWrapper {
  @include inline-edit.not-editable-wrapper;

  > .notEditableEditIcon,
  .readOnlyEditIcon {
    line-height: 26px;
  }
}

.notEditableWrapperLoading {
  @include inline-edit.not-editable-wrapper-loading;
}

.notEditableText {
  @include inline-edit.not-editable-text;
}

.notEditableEditIcon {
  @include inline-edit.not-editable-edit-icon;
}

.readOnlyEditIcon {
  @include inline-edit.readOnly-edit-icon;
}

.readOnlyText {
  @include inline-edit.readOnly-text;
}

.input {
  @include inline-edit.input;
}

.cancelButton {
  @include inline-edit.cancel-button;
}

.cancelButton + .submitButton {
  @include inline-edit.cancel-submit-button;
}

.submitButton {
  @include inline-edit.submit-button;
}

.buttonPosition {
  position: relative;
  top: 26px;
  align-self: flex-start;

  @media (max-width: 1200px) {
    right: 0;
  }
}
