@use "variables" as *;

.checkboxWrapper {
  margin-bottom: $spacing-2;
}

.headerReadonly {
  color: $content-secondary-color;
}

.timeNotAvailable {
  line-height: $spacing-6;
}

.timeInputWrapper {
  display: flex;
  column-gap: $spacing-2;
  margin-left: calc(#{$spacing-3} + #{$spacing-2});
}
