import AD from "@flixbus/honeycomb-flags-static/dist/svg/ad.svg";
import AL from "@flixbus/honeycomb-flags-static/dist/svg/al.svg";
import AT from "@flixbus/honeycomb-flags-static/dist/svg/at.svg";
import BA from "@flixbus/honeycomb-flags-static/dist/svg/ba.svg";
import BE from "@flixbus/honeycomb-flags-static/dist/svg/be.svg";
import BG from "@flixbus/honeycomb-flags-static/dist/svg/bg.svg";
import BR from "@flixbus/honeycomb-flags-static/dist/svg/br.svg";
import BY from "@flixbus/honeycomb-flags-static/dist/svg/by.svg";
import CA from "@flixbus/honeycomb-flags-static/dist/svg/ca.svg";
import CH from "@flixbus/honeycomb-flags-static/dist/svg/ch.svg";
import CL from "@flixbus/honeycomb-flags-static/dist/svg/cl.svg";
import CZ from "@flixbus/honeycomb-flags-static/dist/svg/cz.svg";
import DE from "@flixbus/honeycomb-flags-static/dist/svg/de.svg";
import DK from "@flixbus/honeycomb-flags-static/dist/svg/dk.svg";
import EE from "@flixbus/honeycomb-flags-static/dist/svg/ee.svg";
import ES from "@flixbus/honeycomb-flags-static/dist/svg/es.svg";
import FI from "@flixbus/honeycomb-flags-static/dist/svg/fi.svg";
import FR from "@flixbus/honeycomb-flags-static/dist/svg/fr.svg";
import GB from "@flixbus/honeycomb-flags-static/dist/svg/gb.svg";
import GR from "@flixbus/honeycomb-flags-static/dist/svg/gr.svg";
import HR from "@flixbus/honeycomb-flags-static/dist/svg/hr.svg";
import HU from "@flixbus/honeycomb-flags-static/dist/svg/hu.svg";
import IE from "@flixbus/honeycomb-flags-static/dist/svg/ie.svg";
import IN from "@flixbus/honeycomb-flags-static/dist/svg/in.svg";
import IT from "@flixbus/honeycomb-flags-static/dist/svg/it.svg";
import LI from "@flixbus/honeycomb-flags-static/dist/svg/li.svg";
import LT from "@flixbus/honeycomb-flags-static/dist/svg/lt.svg";
import LU from "@flixbus/honeycomb-flags-static/dist/svg/lu.svg";
import LV from "@flixbus/honeycomb-flags-static/dist/svg/lv.svg";
import MA from "@flixbus/honeycomb-flags-static/dist/svg/ma.svg";
import MD from "@flixbus/honeycomb-flags-static/dist/svg/md.svg";
import ME from "@flixbus/honeycomb-flags-static/dist/svg/me.svg";
import MK from "@flixbus/honeycomb-flags-static/dist/svg/mk.svg";
import MT from "@flixbus/honeycomb-flags-static/dist/svg/mt.svg";
import MX from "@flixbus/honeycomb-flags-static/dist/svg/mx.svg";
import NL from "@flixbus/honeycomb-flags-static/dist/svg/nl.svg";
import NO from "@flixbus/honeycomb-flags-static/dist/svg/no.svg";
import PL from "@flixbus/honeycomb-flags-static/dist/svg/pl.svg";
import PT from "@flixbus/honeycomb-flags-static/dist/svg/pt.svg";
import RO from "@flixbus/honeycomb-flags-static/dist/svg/ro.svg";
import RS from "@flixbus/honeycomb-flags-static/dist/svg/rs.svg";
import RU from "@flixbus/honeycomb-flags-static/dist/svg/ru.svg";
import SE from "@flixbus/honeycomb-flags-static/dist/svg/se.svg";
import SI from "@flixbus/honeycomb-flags-static/dist/svg/si.svg";
import SK from "@flixbus/honeycomb-flags-static/dist/svg/sk.svg";
import SM from "@flixbus/honeycomb-flags-static/dist/svg/sm.svg";
import TR from "@flixbus/honeycomb-flags-static/dist/svg/tr.svg";
import UA from "@flixbus/honeycomb-flags-static/dist/svg/ua.svg";
import US from "@flixbus/honeycomb-flags-static/dist/svg/us.svg";
import XK from "@flixbus/honeycomb-flags-static/dist/svg/xk.svg";

export {
  AD,
  AL,
  AT,
  BA,
  BE,
  BG,
  BR,
  BY,
  CA,
  CH,
  CL,
  CZ,
  DE,
  DK,
  EE,
  ES,
  FI,
  FR,
  GB,
  GR,
  HR,
  HU,
  IE,
  IN,
  IT,
  LI,
  LT,
  LU,
  LV,
  MA,
  MD,
  ME,
  MK,
  MT,
  MX,
  NL,
  NO,
  PL,
  PT,
  RO,
  RS,
  RU,
  SE,
  SI,
  SK,
  SM,
  TR,
  UA,
  US,
  XK,
};
