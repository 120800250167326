import { Switch } from "@flixbus/honeycomb-react";
import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
} from "date-fns";
import * as React from "react";
import { useIntl } from "react-intl";
import { OriginalSchedulesOfLineQuery } from "../../api/operations.generated";
import buildDailyCalendar from "../../helper/buildCalendar/buildDailyCalendar";
import buildMonthlyCalendar from "../../helper/buildCalendar/buildMonthlyCalendar";
import {
  contentCellWidthDaily,
  contentCellWidthMonthly,
} from "../../helper/styles/sizes";
import { Calendar, CalendarIntervalType } from "../../helper/types";
import CalendarVirtualizer from "../calendarVirtualizer/CalendarVirtualizer";
import * as css from "./ScheduleCalendarShell.scss";

export type ScheduleCalendarShellProps = {
  schedules: OriginalSchedulesOfLineQuery["findOriginalSchedules"];
};

const ScheduleCalendarShell: React.FC<ScheduleCalendarShellProps> = ({ schedules }) => {
  const { formatMessage } = useIntl();
  const [selectedIntervalType, setSelectedIntervalType] = React.useState(
    CalendarIntervalType.MONTHLY
  );
  const isDaily = selectedIntervalType === CalendarIntervalType.DAILY;

  const calendar: Calendar = isDaily
    ? buildDailyCalendar(schedules)
    : buildMonthlyCalendar(schedules);

  const displayDates: Date[] = isDaily
    ? eachDayOfInterval(calendar.boundary)
    : eachMonthOfInterval(calendar.boundary);

  const initialOffset = isDaily
    ? differenceInCalendarDays(new Date(), displayDates[0]) - 9
    : differenceInCalendarMonths(new Date(), displayDates[0]) - 5;

  return (
    <>
      <div className={css.wrapper}>
        <Switch
          label={formatMessage({ id: "lineDetail.schedules.statusFilter.label" })}
          id="show-inactive"
          small
        />
      </div>

      <CalendarVirtualizer
        // Forces a rerender when the interval changes
        key={selectedIntervalType}
        calendar={calendar}
        displayDates={displayDates}
        stickyHeaderDates={
          isDaily
            ? eachMonthOfInterval(calendar.boundary)
            : eachYearOfInterval(calendar.boundary)
        }
        initialOffsetInPixel={
          isDaily
            ? initialOffset * contentCellWidthDaily
            : initialOffset * contentCellWidthMonthly
        }
        initialOffsetIndex={initialOffset}
        onIntervalTypeChange={setSelectedIntervalType}
      />
    </>
  );
};

export default ScheduleCalendarShell;
