@use "variables" as *;

.container {
  display: flex;
}

.select {
  width: 330px;
  margin-right: $spacing-2;
}
