@use "variables" as *;
@use "../../styles/detail-summary.scss";

$transition: 500ms ease-in-out;

@mixin hidden {
  transform: translateY(-100%);
  opacity: 0;
}

@mixin visible {
  transform: translateY(0%);
  opacity: 1;
}

.enter {
  @include hidden;
}

.enterActive {
  @include visible;
  transition: $transition;
}

.exit {
  @include visible;
}

.exitActive {
  @include hidden;
  transition: $transition;
}
