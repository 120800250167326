// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type LinesOfPartnersQueryVariables = Types.Exact<{
  partnerId?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
}>;

export type LinesOfPartnersQuery = {
  __typename?: "Query";
  findPartner: {
    __typename?: "Partner";
    id: string;
    lines: Array<{
      __typename?: "Line";
      id: string;
      networkCode: string;
      networkDescription: string;
      deletable: boolean;
      status: Types.LineStatus;
    }>;
  };
};

export const LinesOfPartnersDocument = gql`
  query LinesOfPartners($partnerId: ID) {
    findPartner(id: $partnerId) {
      id
      lines {
        id
        networkCode
        networkDescription
        deletable
        status
      }
    }
  }
`;

/**
 * __useLinesOfPartnersQuery__
 *
 * To run a query within a React component, call `useLinesOfPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinesOfPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinesOfPartnersQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useLinesOfPartnersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LinesOfPartnersQuery,
    LinesOfPartnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LinesOfPartnersQuery, LinesOfPartnersQueryVariables>(
    LinesOfPartnersDocument,
    options
  );
}
export function useLinesOfPartnersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LinesOfPartnersQuery,
    LinesOfPartnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LinesOfPartnersQuery,
    LinesOfPartnersQueryVariables
  >(LinesOfPartnersDocument, options);
}
export function useLinesOfPartnersSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        LinesOfPartnersQuery,
        LinesOfPartnersQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    LinesOfPartnersQuery,
    LinesOfPartnersQueryVariables
  >(LinesOfPartnersDocument, options);
}
export type LinesOfPartnersQueryHookResult = ReturnType<typeof useLinesOfPartnersQuery>;
export type LinesOfPartnersLazyQueryHookResult = ReturnType<
  typeof useLinesOfPartnersLazyQuery
>;
export type LinesOfPartnersSuspenseQueryHookResult = ReturnType<
  typeof useLinesOfPartnersSuspenseQuery
>;
