@use "variables" as *;

.wrapper {
  margin-bottom: $spacing-2;
  color: $grayscale-70-color;
  display: flex;
}

.skeletonItem {
  width: 150px;
  height: 22px;
  vertical-align: bottom;
  padding-top: 4px;
}

.skeletonDivider,
.skeletonItem {
  display: inline-block;
}

.skeleton {
  margin-bottom: 0;
}
