@use "variables" as *;

.validationEntry {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: $border;
  padding: 12px 0 12px 0;
  &:last-child {
    border-bottom: none;
  }
}

.infoText {
  margin-left: 5px;
}

.iconWarning {
  color: $warning-dark-color;
}

.list {
  text-align: left;
  margin-bottom: 0px;
  > li {
    margin-left: 18px;
  }
}
