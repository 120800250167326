import { Icon, IconCopy } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";

type Props = {
  onCopy: () => void;
  disabled: boolean;
};

const TripCopyButton: React.FC<Props> = ({ onCopy, disabled }) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      data-id="copy-trip-button"
      appearance="secondary"
      display="square"
      onClick={onCopy}
      mouse-out-visibility="hidden"
      title={formatMessage({ id: "trip.copy.button.title" })}
      disabled={disabled}
      aria-label="copy trip"
    >
      <Icon InlineIcon={IconCopy} />
    </Button>
  );
};

export default TripCopyButton;
