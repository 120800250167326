import {
  endOfMonth,
  interval,
  isWithinInterval,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { Calendar, CalendarIntervalType, ScheduleChunk } from "../types";

const findChunk = (
  calendar: Calendar,
  date: Date,
  row: number
): ScheduleChunk | undefined =>
  calendar.rows[row].chunks.find((chunk) => {
    const chunkInterval =
      calendar.intervalType === CalendarIntervalType.DAILY
        ? interval(startOfDay(chunk.uiPeriod.start), startOfDay(chunk.uiPeriod.end))
        : interval(startOfMonth(chunk.uiPeriod.start), endOfMonth(chunk.uiPeriod.end));

    return isWithinInterval(date, chunkInterval);
  });

export default findChunk;
