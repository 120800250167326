import { ButtonGroup, Grid, GridCol } from "@flixbus/honeycomb-react";
import * as React from "react";
import { TripFragment } from "../../api/operations.generated";
import ServiceDays from "../serviceDays/ServiceDays";
import TripCopyButton from "../tripCopyButton/TripCopyButton";
import TripDeleteButton from "../tripDeleteButton/TripDeleteButton";
import TripNumberInput from "../tripNumberInput/TripNumberInput";
import TripShiftButton from "../tripShiftButton/TripShiftButton";
import * as css from "./TripHeader.scss";

export type TripHeaderProps = {
  tripNumber: TripFragment["number"];
  tripDepartureTime: string;
  serviceDays: TripFragment["serviceDays"];
  readOnly: boolean;
  onDeleteTrip: () => void;
  onCopyTrip: () => void;
  onShiftTrip: (departureTime: string) => void;
  onServiceDaysChanged: (serviceDays: TripFragment["serviceDays"]) => void;
  onNumberChanged: (number: TripFragment["number"]) => void;
};

const TripHeader: React.FC<TripHeaderProps> = ({
  tripNumber,
  tripDepartureTime,
  serviceDays,
  readOnly,
  onDeleteTrip,
  onCopyTrip,
  onShiftTrip,
  onServiceDaysChanged,
  onNumberChanged,
}) => {
  return (
    <div className={css.container} data-id="trip-header">
      <Grid>
        <GridCol size={3}>
          <TripNumberInput
            value={tripNumber.toString()}
            onValueChanged={(value) => onNumberChanged(parseInt(value, 10))}
            disabled={readOnly}
          />
        </GridCol>
        <GridCol size={9}>
          <ButtonGroup alignment="end">
            <TripCopyButton onCopy={onCopyTrip} disabled={readOnly} />
            <TripShiftButton
              tripDepartureTime={tripDepartureTime}
              tripNumber={tripNumber}
              onShift={onShiftTrip}
              disabled={readOnly}
            />
            <TripDeleteButton disabled={readOnly} onDelete={onDeleteTrip} />
          </ButtonGroup>
        </GridCol>
        <GridCol size={12}>
          <div className={css.serviceDays}>
            <ServiceDays
              serviceDays={serviceDays}
              onServiceDaysChange={onServiceDaysChanged}
              disabled={readOnly}
            />
          </div>
        </GridCol>
      </Grid>
    </div>
  );
};

export default TripHeader;
