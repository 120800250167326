@use "variables" as *;

.container {
  margin-left: auto;
  margin-right: auto;
  margin-top: $spacing-8;
  width: 400px;
  text-align: center;
}

.text {
  margin-top: $spacing-2;
}

.button {
  margin-top: $spacing-3;
}
