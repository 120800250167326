@use "variables" as *;

.textTop {
  margin-bottom: $spacing-2;
}

.textBottom {
  margin-top: $spacing-4;
  margin-bottom: $spacing-2;
}
