@use "variables" as *;

.selectorWrapper {
  margin-bottom: $spacing-4;
}

.infoTextWrapper {
  display: flex;
  padding-top: $spacing-2;
}

.infoText {
  display: flex;
  align-items: center;
  height: $spacing-6;
  margin-left: 42px;
  margin-right: $spacing-2;
}

.inputWrapper {
  width: 185px;
}
