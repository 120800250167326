.serviceDays {
  ul {
    width: 100%;
    li {
      flex-grow: 1;
      label {
        justify-content: center;
        padding: 9px 6px 6px 6px !important;
      }
    }
  }
}
