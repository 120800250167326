import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";

type Props = {
  onClick: () => void;
  appearance: "primary" | "secondary" | "danger";
  loading?: boolean;
  disabled?: boolean;
  title?: string;
};

const ScheduleFooterButton: React.FC<Props> = ({
  onClick,
  appearance,
  loading = false,
  disabled = false,
  title,
  children,
}) => {
  return (
    <Button
      onClick={onClick}
      appearance={appearance}
      loading={loading}
      disabled={disabled}
      title={title}
    >
      {children}
    </Button>
  );
};

export default ScheduleFooterButton;
