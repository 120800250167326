@use "variables" as *;

.chip {
  border-radius: 9px;
  height: 30px;
  background-color: $bg-secondary-color;
  color: $content-secondary-color;
  cursor: pointer;
}

.onClickWrapper {
  display: inline-block;
  height: 30px;
  padding: 3px 0 3px $spacing-2;

  &.readOnly {
    padding: 3px $spacing-2 3px $spacing-2;
  }
}

.highlighted {
  background-color: $highlight-color;
  color: $ui-primary-dark-color;
  font-weight: $font-weight-bold;
}

.error {
  border-color: $danger-color;
  border-width: 1px;
  border-style: solid;
}

.errorIcon {
  color: $danger-color;
  margin-right: $spacing-1;
  margin-bottom: 5px;
}

.icon {
  cursor: pointer;
  fill: $icon-primary-color !important;
  margin: 0 $spacing-2 3px $spacing-half;
}
