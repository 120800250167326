@use "variables" as *;

.skeleton {
  width: 120px;
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.icon {
  color: $icon-primary-color;
}
