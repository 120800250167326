@use "variables" as *;

.skeleton {
  margin: 10px 0 0 0;
}

.input {
  margin-bottom: 9px;
}

.checklist {
  height: 192px;
  overflow: scroll;
}

.textMessage {
  font-size: $font-size-small;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  color: $content-secondary-color;
}
