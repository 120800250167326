import { useReactiveVar } from "@apollo/client";
import { DataTable, DataTableRow, Tag } from "@flixbus/honeycomb-react";
import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import buildPath from "../../../routes/buildPath/buildPath";
import DataTableError from "../../../shared/components/dataTableError/DataTableError";
import DataTableLoading from "../../../shared/components/dataTableLoading/DataTableLoading";
import DataTableNoResults from "../../../shared/components/dataTableNoResult/DataTableNoResults";
import translatePartnerTags from "../../../shared/helpers/translatePartnerTags/translatePartnerTags";
import partnerFilterVar from "../../../state/partnerFilter/partnerFilter";
import { useFilterPartnersQuery } from "../../api/operations.generated";
import { clearPartnerFilter } from "../../helper/clearFilter/clearFilter";
import { createQueryVariables } from "../../helper/partnerFilterValues/partnerFilterValues";
import { partnerTableHeaders } from "../../helper/tableHeader/tableHeader";
import ResultCount from "../../ui/resultCount/ResultCount";
import * as css from "./PartnerTable.scss";

const PartnerTable: React.FC = () => {
  const hasPartnerTagsPermission = hasUserPermission(Feature.VIEW_PARTNER_TAGS);
  const partnerFilter = useReactiveVar(partnerFilterVar);
  const navigate = useNavigate();

  const { data, loading, error } = useFilterPartnersQuery({
    variables: {
      partnerFilterInput: createQueryVariables(partnerFilter),
    },
  });

  if (loading)
    return (
      <>
        <ResultCount type="partner" loading />
        <DataTableLoading
          header={partnerTableHeaders(hasPartnerTagsPermission)}
          colCount={hasPartnerTagsPermission ? 4 : 3}
          extraClasses={css.table}
        />
      </>
    );

  if (error) return <DataTableError />;

  if (data?.filterPartners.length) {
    return (
      <div data-id="partner-table">
        <ResultCount amount={data.filterPartners.length} type="partner" loading={false} />
        <DataTable
          headers={partnerTableHeaders(hasPartnerTagsPermission)}
          extraClasses={css.table}
        >
          {data?.filterPartners.map((partner) => (
            <DataTableRow
              extraClasses={css.tableRow}
              key={partner.id}
              onClick={() => navigate(buildPath.showPartnerDetail(partner.id))}
            >
              <td>{partner.name}</td>
              <td>{partner.businessRegion.name}</td>
              <td>
                {partner.apiSettings?.apiPartner ? (
                  <FormattedMessage id="general.integrationType.api" />
                ) : (
                  <FormattedMessage id="general.integrationType.manual" />
                )}
              </td>
              {hasPartnerTagsPermission ? (
                <td>
                  {partner.tags
                    .map((tag) => translatePartnerTags(tag))
                    .sort((tag1, tag2) => tag1.localeCompare(tag2))
                    .map((tag) => (
                      <Tag key={tag} display="subtle" extraClasses={css.tag}>
                        {tag}
                      </Tag>
                    ))}
                </td>
              ) : (
                <></>
              )}
            </DataTableRow>
          ))}
        </DataTable>
      </div>
    );
  }

  return <DataTableNoResults onClearFilterSetting={clearPartnerFilter} />;
};

export default PartnerTable;
