// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindAllLegalCompaniesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FindAllLegalCompaniesQuery = {
  __typename?: "Query";
  findAllLegalCompanies: Array<{
    __typename?: "LegalCompany";
    id: string;
    name: string;
    concessionOwnerId?: string | null;
  }>;
};

export type FindAllBusinessRegionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FindAllBusinessRegionsQuery = {
  __typename?: "Query";
  findAllBusinessRegions: Array<{
    __typename?: "BusinessRegion";
    networkId: string;
    value: string;
    label: string;
  }>;
};

export const FindAllLegalCompaniesDocument = gql`
  query FindAllLegalCompanies {
    findAllLegalCompanies {
      id
      name
      concessionOwnerId
    }
  }
`;

/**
 * __useFindAllLegalCompaniesQuery__
 *
 * To run a query within a React component, call `useFindAllLegalCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllLegalCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllLegalCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllLegalCompaniesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindAllLegalCompaniesQuery,
    FindAllLegalCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindAllLegalCompaniesQuery,
    FindAllLegalCompaniesQueryVariables
  >(FindAllLegalCompaniesDocument, options);
}
export function useFindAllLegalCompaniesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindAllLegalCompaniesQuery,
    FindAllLegalCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindAllLegalCompaniesQuery,
    FindAllLegalCompaniesQueryVariables
  >(FindAllLegalCompaniesDocument, options);
}
export function useFindAllLegalCompaniesSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindAllLegalCompaniesQuery,
        FindAllLegalCompaniesQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindAllLegalCompaniesQuery,
    FindAllLegalCompaniesQueryVariables
  >(FindAllLegalCompaniesDocument, options);
}
export type FindAllLegalCompaniesQueryHookResult = ReturnType<
  typeof useFindAllLegalCompaniesQuery
>;
export type FindAllLegalCompaniesLazyQueryHookResult = ReturnType<
  typeof useFindAllLegalCompaniesLazyQuery
>;
export type FindAllLegalCompaniesSuspenseQueryHookResult = ReturnType<
  typeof useFindAllLegalCompaniesSuspenseQuery
>;
export const FindAllBusinessRegionsDocument = gql`
  query FindAllBusinessRegions {
    findAllBusinessRegions {
      value: id
      label: name
      networkId
    }
  }
`;

/**
 * __useFindAllBusinessRegionsQuery__
 *
 * To run a query within a React component, call `useFindAllBusinessRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllBusinessRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllBusinessRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllBusinessRegionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindAllBusinessRegionsQuery,
    FindAllBusinessRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindAllBusinessRegionsQuery,
    FindAllBusinessRegionsQueryVariables
  >(FindAllBusinessRegionsDocument, options);
}
export function useFindAllBusinessRegionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindAllBusinessRegionsQuery,
    FindAllBusinessRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindAllBusinessRegionsQuery,
    FindAllBusinessRegionsQueryVariables
  >(FindAllBusinessRegionsDocument, options);
}
export function useFindAllBusinessRegionsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindAllBusinessRegionsQuery,
        FindAllBusinessRegionsQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindAllBusinessRegionsQuery,
    FindAllBusinessRegionsQueryVariables
  >(FindAllBusinessRegionsDocument, options);
}
export type FindAllBusinessRegionsQueryHookResult = ReturnType<
  typeof useFindAllBusinessRegionsQuery
>;
export type FindAllBusinessRegionsLazyQueryHookResult = ReturnType<
  typeof useFindAllBusinessRegionsLazyQuery
>;
export type FindAllBusinessRegionsSuspenseQueryHookResult = ReturnType<
  typeof useFindAllBusinessRegionsSuspenseQuery
>;
