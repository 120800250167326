@use "variables" as *;

.list {
  margin-top: 0;
  padding-left: 0;
  counter-reset: bd-input;
}

.button {
  margin-left: 26px;
}
