import { Icon, IconFilter } from "@flixbus/honeycomb-icons-react";
import { Box, Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./FilterMenu.scss";

export type FilterMenuProps = {
  showBox: boolean;
  handleBoxVisibility: (value: boolean) => void;
};
const FilterMenu: React.FC<FilterMenuProps> = ({
  showBox,
  handleBoxVisibility,
  children,
}) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function closeWindowOnOutsideClick(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        handleBoxVisibility(false);
      }
    }

    if (showBox) {
      document.addEventListener("mousedown", closeWindowOnOutsideClick);
    } else {
      document.removeEventListener("mousedown", closeWindowOnOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", closeWindowOnOutsideClick);
    };
  }, [wrapperRef, showBox]);

  return (
    <div data-id="wrapper" ref={wrapperRef}>
      <Button
        appearance="secondary"
        onClick={() => {
          handleBoxVisibility(!showBox);
        }}
      >
        <Icon InlineIcon={IconFilter} />
        <FormattedMessage id="general.AddFilter" />
      </Button>
      {showBox && (
        <Box extraClasses={css.box} highlighted>
          {children}
        </Box>
      )}
    </div>
  );
};

export default FilterMenu;
