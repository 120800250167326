import { Button, ButtonGroup, FormRow } from "@flixbus/honeycomb-react";
import { Form, Formik } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikAutocomplete from "../../shared/components/formikAutocomplete/FormikAutocomplete";
import FormikInput from "../../shared/components/formikInput/FormikInput";
import FormikSelect from "../../shared/components/formikSelect/FormikSelect";
import { createCountryOptions } from "../../shared/helpers/autocompleteOptions/createOptions";
import sortOptionsByLabel from "../../shared/helpers/autocompleteOptions/sortOptionsByLabel";
import { NewCityMutationVariables } from "../createCity/api/operations.generated";
import importanceClassOptions from "./helpers/importanceClassOptions/importanceClassOptions";
import validationSchema from "./helpers/validationSchema/validationSchema";

export type CityFormProps = {
  onSubmit: (values: NewCityMutationVariables) => void;
  onCancel: () => void;
  submitLoading?: boolean;
  cityName?: string;
  isNested?: boolean;
};

const CityForm: React.FC<CityFormProps> = ({
  onSubmit,
  onCancel,
  submitLoading,
  cityName = "",
  isNested,
}) => {
  const { formatMessage } = useIntl();

  const initialValues = {
    name: cityName,
    countryCode: undefined,
    importanceClass: undefined,
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        if (values.name && values.countryCode && values.importanceClass) {
          onSubmit({
            name: values.name,
            countryCode: values.countryCode,
            importanceClass: values.importanceClass,
          });
        }
      }}
      onReset={onCancel}
    >
      <Form noValidate>
        <FormRow>
          <FormikInput
            name="name"
            label={formatMessage({ id: "general.name" })}
            placeholder="New York"
            type="text"
            required
          />
        </FormRow>
        <FormRow>
          <FormikAutocomplete
            name="countryCode"
            label={formatMessage({ id: "general.country" })}
            placeholder={formatMessage({ id: "country.select.placeholder" })}
            options={sortOptionsByLabel(createCountryOptions())}
            startsOnInputValueLength={0}
            required
          />
        </FormRow>
        <FormRow>
          <FormikSelect
            name="importanceClass"
            label={formatMessage({ id: "city.importanceClass" })}
            placeholder={formatMessage({ id: "city.importanceClass.select.placeholder" })}
            options={importanceClassOptions()}
            required
          />
        </FormRow>

        <ButtonGroup alignment="center">
          <Button type="reset">{formatMessage({ id: "general.cancel" })}</Button>
          <Button
            type="submit"
            appearance={isNested ? "secondary" : "primary"}
            loading={submitLoading}
          >
            {formatMessage({ id: "city.create" })}
          </Button>
        </ButtonGroup>
      </Form>
    </Formik>
  );
};

export default CityForm;
