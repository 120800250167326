import { parseDate } from "@flixbus-phx/marketplace-common";
import cx from "classnames";
import * as React from "react";
import ScheduleStatusTag from "../../../../shared/components/scheduleStatusTag/ScheduleStatusTag";
import { SchedulesOfLineQuery } from "../../api/operations.generated";
import calculatePeriodPosition from "../../helpers/calculatePeriodPosition/calculatePeriodPosition";
import { getCalendarPositionForMonthlyView } from "../../helpers/getCalendarPosition/getCalendarPosition";
import getOverlapPositions from "../../helpers/getOverlapPositions/getOverlapPositions";
import { CalendarIntervallType, Interval } from "../../types";
import Overlap from "../overlap/Overlap";
import ScheduleCalendarHighlightedCell from "../scheduleCalendarHighlightedCell/ScheduleCalendarHighlightedCell";
import ScheduleCalendarWeekendCell from "../scheduleCalendarWeekendCell/ScheduleCalendarWeekendCell";
import SchedulePeriodOverlapText from "../schedulePeriodOverlapText/SchedulePeriodOverlapText";
import SchedulePeriodTagLink from "../schedulePeriodTagLink/SchedulePeriodTagLink";
import TagText from "../tagText/TagText";
import * as css from "./ScheduleCalendarScheduleRow.scss";

type Props = {
  selectedPeriod: Date;
  period: SchedulesOfLineQuery["findLine"]["schedules"][number]["period"];
  displayedScheduleStatus: SchedulesOfLineQuery["findLine"]["schedules"][number]["displayedScheduleStatus"];
  isOutdated: SchedulesOfLineQuery["findLine"]["schedules"][number]["isOutdated"];
  scheduleId: SchedulesOfLineQuery["findLine"]["schedules"][number]["id"];
  selectedIntervalType: CalendarIntervallType;
  overlapIntervals: Array<Interval>;
};

const ScheduleCalendarScheduleRow: React.FC<Props> = ({
  selectedPeriod,
  period,
  displayedScheduleStatus,
  isOutdated,
  scheduleId,
  selectedIntervalType,
  overlapIntervals,
}) => {
  const startDate = parseDate(period.start);
  const endDate = parseDate(period.end);
  const isSelectedIntervalYear = selectedIntervalType === CalendarIntervallType.YEAR;

  const [colStart, startDateInPast, startDateInFuture] = calculatePeriodPosition(
    startDate,
    selectedPeriod,
    selectedIntervalType
  );

  const [colEnd, endDateInPast, endDateInFuture] = calculatePeriodPosition(
    endDate,
    selectedPeriod,
    selectedIntervalType
  );
  const schedulePeriodTagPosition = getCalendarPositionForMonthlyView(colStart, colEnd);

  const overlapPositions = getOverlapPositions(
    selectedPeriod,
    overlapIntervals,
    selectedIntervalType
  );

  const tagText = (
    <TagText
      startDate={startDate}
      endDate={endDate}
      selectedPeriod={selectedPeriod}
      selectedIntervalType={selectedIntervalType}
    />
  );

  return (
    <>
      <ScheduleCalendarHighlightedCell
        selectedPeriod={selectedPeriod}
        isSelectedIntervalYear={isSelectedIntervalYear}
      />

      {!isSelectedIntervalYear && (
        <ScheduleCalendarWeekendCell selectedPeriod={selectedPeriod} />
      )}

      <>
        <div
          data-id="wrapper-schedule-status-tag"
          style={schedulePeriodTagPosition}
          className={cx(
            css.wrapper,
            startDateInPast && endDateInPast && css.periodInPast,
            startDateInFuture && endDateInFuture && css.periodInFuture
          )}
        >
          <ScheduleStatusTag
            status={displayedScheduleStatus}
            isOutdated={isOutdated}
            extraClasses={cx(
              css.tag,
              startDateInPast && css.cutOffLeft,
              endDateInFuture && css.cutOffRight
            )}
            isSmall={false}
          >
            {overlapIntervals.length > 0 ? <></> : tagText}
          </ScheduleStatusTag>
        </div>

        {overlapIntervals.length > 0 && (
          <>
            <>
              {overlapPositions.map((overlapPosition) => {
                return (
                  <Overlap key={overlapPosition.colStart} position={overlapPosition} />
                );
              })}
            </>

            <SchedulePeriodOverlapText
              startDateInPast={startDateInPast}
              endDateInPast={endDateInPast}
              startDateInFuture={startDateInFuture}
              endDateInFuture={endDateInFuture}
              displayedScheduleStatus={displayedScheduleStatus}
              schedulePeriodTagPosition={schedulePeriodTagPosition}
            >
              {tagText}
            </SchedulePeriodOverlapText>
          </>
        )}
      </>

      <SchedulePeriodTagLink
        scheduleId={scheduleId}
        startDateInPast={startDateInPast}
        endDateInPast={endDateInPast}
        startDateInFuture={startDateInFuture}
        endDateInFuture={endDateInFuture}
        schedulePeriodTagPosition={schedulePeriodTagPosition}
      />
    </>
  );
};

export default ScheduleCalendarScheduleRow;
