@use "variables" as *;

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: $spacing-2;
  padding-top: $spacing-2;
}

.item {
  height: 63px;
}
