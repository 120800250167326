@use "variables" as *;
@use "../../shared/styles/form-elements.scss";

.minSeatCapacity {
  width: 60%;
}

.fineprint {
  @include form-elements.fineprint-below-input;
}

.circulation {
  width: 60%;
}
