@use "variables" as *;
@use "../../../../shared/styles/infobox.scss";

.infobox {
  @include infobox.infobox;
}

.accordion {
  width: 1050px;
  border: none;

  summary {
    padding: 0;
    font-size: 16px;
    font-weight: 300;

    &:hover {
      border-radius: 6px;
    }
  }
  article {
    padding-top: $spacing-2;
  }
}

.table {
  width: 1020px;
  tr {
    td:nth-child(1) {
      width: 820px;
    }

    td:nth-child(2) {
      width: 200px;
    }
  }
}
