@use "variables" as *;

.labelWrapper {
  padding-bottom: 2px;
}

.label {
  color: $grayscale-90-color;
}

.errorText {
  color: $danger-color;
}
