@use "variables" as *;

.grid {
  display: grid;
  justify-content: start;
  max-height: calc(100vh - 225px);
  overflow: scroll;
  width: fit-content;
  max-width: calc(100% - #{$spacing-6});
}

.error {
  border-color: $danger-color;
  border-style: solid;
}
