import { IconAttentionSolid } from "@flixbus/honeycomb-icons-react";
import { Button, PopupSection } from "@flixbus/honeycomb-react";
import { mpcPathnames as pathnames } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Validation400,
  Validation400ErrorReasons,
} from "../../../helper/typeguards/typeguards";
import ImportPopupButtonWrapper from "../../importPopupButtonWrapper/ImportPopupButtonWrapper";
import ImportPopupHeader from "../../importPopupHeader/ImportPopupHeader";
import * as css from "./Validated400.scss";

export type Validated400Props = {
  errors: Validation400;
  onCancel: () => void;
  onBack: () => void;
};

const Validated400: React.FC<Validated400Props> = ({ errors, onCancel, onBack }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <ImportPopupHeader Icon={IconAttentionSolid} color="danger">
        <FormattedMessage id="importPopup.validation400.headline" />
      </ImportPopupHeader>

      <PopupSection type="content">
        <ul className={css.list}>
          {errors.map((error) => {
            if (error !== Validation400ErrorReasons.FAILED_ON_SERVER) {
              return (
                <li key={error}>
                  <FormattedMessage id={`importPopup.validation400.error.${error}`} />
                </li>
              );
            }
            return (
              <li
                key={error}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: formatMessage(
                    { id: `importPopup.validation400.error.${error}` },
                    {
                      link: pathnames.serviceDesk,
                    },
                    { ignoreTag: true }
                  ),
                }}
              />
            );
          })}
        </ul>
      </PopupSection>

      <ImportPopupButtonWrapper>
        <Button onClick={onCancel}>
          <FormattedMessage id="general.cancel" />
        </Button>
        <Button appearance="primary" onClick={onBack}>
          <FormattedMessage id="general.back" />
        </Button>
      </ImportPopupButtonWrapper>
    </>
  );
};

export default Validated400;
