@use "variables" as *;
@use "../../helper/styles/styles.scss";

.scrollableColumn {
  @include styles.scrollableColumn;
  top: styles.$topHeaderHeight;
  z-index: 2;
}

.cell {
  // TODO: SHare the upper block in a mixin
  height: styles.$bottomHeaderHeight;
  line-height: calc(styles.$bottomHeaderHeight / 2);
  border-right: styles.$border-light;
  border-bottom: $border;
  background-color: $bg-primary-color;
  text-align: center;

  font-size: $font-size-small;
  line-height: styles.$bottomHeaderHeight;

  &.thisMonth {
    border-bottom: 3px solid $ui-primary-color;
    color: $ui-primary-color;
  }
}
