@use "variables" as *;

.wrapper {
  text-align: right;
}

.borderBottom {
  button {
    border-bottom: 1px solid $line-primary-color;
    padding-bottom: 9px;
  }
}

.borderTop {
  button {
    border-top: 1px solid $line-primary-color;
    padding-top: 9px;
  }
}

.dropdown {
  [disabled] {
    color: $grayscale-50-color;
    cursor: not-allowed;
  }

  ul {
    width: 275px;

    li {
      padding-top: 0px;
      padding-bottom: 0px;

      a {
        padding-top: $spacing-1;
        padding-bottom: $spacing-1;

        span:first-child {
          margin-right: $spacing-1;
        }
      }
    }

    button {
      text-align: left;
    }
  }
}
