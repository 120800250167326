// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FilterStationsQueryVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  code?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  city?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
  country?: Types.InputMaybe<Types.IsoCountry>;
  status?: Types.InputMaybe<Types.StationStatus>;
  pageSize: Types.PageSize;
  page: Types.Scalars["Int"]["input"];
}>;

export type FilterStationsQuery = {
  __typename?: "Query";
  filterStations: {
    __typename?: "PagedStationResult";
    total: number;
    items: Array<{
      __typename?: "Station";
      id: string;
      name: string;
      code?: string | null;
      countryCode?: string | null;
      updatedDate?: string | null;
      status: Types.StationStatus;
      hasNearbyStation?: boolean | null;
      latitude?: string | null;
      longitude?: string | null;
      city?: { __typename?: "City"; id: string; name: string } | null;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      totalPages: number;
      currentPage: number;
      hasNext: boolean;
      hasPrevious: boolean;
    };
  };
};

export type FilterCitiesQueryVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  country?: Types.InputMaybe<Types.IsoCountry>;
  importanceClass?: Types.InputMaybe<Types.ImportanceClass>;
  published?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
  pageSize: Types.PageSize;
  page: Types.Scalars["Int"]["input"];
}>;

export type FilterCitiesQuery = {
  __typename?: "Query";
  filterCities: {
    __typename?: "PagedCityResult";
    total: number;
    items: Array<{
      __typename?: "City";
      id: string;
      name: string;
      countryCode?: string | null;
      importanceClass?: Types.ImportanceClass | null;
      isPublished: boolean;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      totalPages: number;
      currentPage: number;
      hasNext: boolean;
      hasPrevious: boolean;
    };
  };
};

export type FindAllCitiesForFilterQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FindAllCitiesForFilterQuery = {
  __typename?: "Query";
  findAllCities: Array<{ __typename?: "City"; value: string; label: string }>;
};

export type ApproveStationMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type ApproveStationMutation = {
  __typename?: "Mutation";
  approveStation: {
    __typename?: "Station";
    id: string;
    status: Types.StationStatus;
    updatedDate?: string | null;
  };
};

export type RejectStationMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  reason: Types.Scalars["String"]["input"];
  alternativeStationId?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
}>;

export type RejectStationMutation = {
  __typename?: "Mutation";
  rejectStation: {
    __typename?: "Station";
    id: string;
    status: Types.StationStatus;
    updatedDate?: string | null;
  };
};

export const FilterStationsDocument = gql`
  query FilterStations(
    $name: String
    $code: String
    $city: ID
    $country: ISOCountry
    $status: StationStatus
    $pageSize: PageSize!
    $page: Int!
  ) {
    filterStations(
      name: $name
      code: $code
      city: $city
      country: $country
      status: $status
      pageRequest: { pageSize: $pageSize, page: $page }
    ) {
      total
      items {
        id
        name
        code
        city {
          id
          name
        }
        countryCode
        updatedDate
        status
        hasNearbyStation
        latitude
        longitude
      }
      pageInfo {
        totalPages
        currentPage
        hasNext
        hasPrevious
      }
    }
  }
`;

/**
 * __useFilterStationsQuery__
 *
 * To run a query within a React component, call `useFilterStationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterStationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterStationsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      city: // value for 'city'
 *      country: // value for 'country'
 *      status: // value for 'status'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFilterStationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FilterStationsQuery,
    FilterStationsQueryVariables
  > &
    ({ variables: FilterStationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FilterStationsQuery, FilterStationsQueryVariables>(
    FilterStationsDocument,
    options
  );
}
export function useFilterStationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FilterStationsQuery,
    FilterStationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FilterStationsQuery, FilterStationsQueryVariables>(
    FilterStationsDocument,
    options
  );
}
export function useFilterStationsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FilterStationsQuery,
        FilterStationsQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FilterStationsQuery,
    FilterStationsQueryVariables
  >(FilterStationsDocument, options);
}
export type FilterStationsQueryHookResult = ReturnType<typeof useFilterStationsQuery>;
export type FilterStationsLazyQueryHookResult = ReturnType<
  typeof useFilterStationsLazyQuery
>;
export type FilterStationsSuspenseQueryHookResult = ReturnType<
  typeof useFilterStationsSuspenseQuery
>;
export const FilterCitiesDocument = gql`
  query FilterCities(
    $name: String
    $country: ISOCountry
    $importanceClass: ImportanceClass
    $published: Boolean
    $pageSize: PageSize!
    $page: Int!
  ) {
    filterCities(
      name: $name
      country: $country
      importanceClass: $importanceClass
      published: $published
      pageRequest: { pageSize: $pageSize, page: $page }
    ) {
      total
      items {
        id
        name
        countryCode
        importanceClass
        isPublished
      }
      pageInfo {
        totalPages
        currentPage
        hasNext
        hasPrevious
      }
    }
  }
`;

/**
 * __useFilterCitiesQuery__
 *
 * To run a query within a React component, call `useFilterCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterCitiesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      country: // value for 'country'
 *      importanceClass: // value for 'importanceClass'
 *      published: // value for 'published'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFilterCitiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FilterCitiesQuery,
    FilterCitiesQueryVariables
  > &
    ({ variables: FilterCitiesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FilterCitiesQuery, FilterCitiesQueryVariables>(
    FilterCitiesDocument,
    options
  );
}
export function useFilterCitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FilterCitiesQuery,
    FilterCitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FilterCitiesQuery, FilterCitiesQueryVariables>(
    FilterCitiesDocument,
    options
  );
}
export function useFilterCitiesSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FilterCitiesQuery,
        FilterCitiesQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<FilterCitiesQuery, FilterCitiesQueryVariables>(
    FilterCitiesDocument,
    options
  );
}
export type FilterCitiesQueryHookResult = ReturnType<typeof useFilterCitiesQuery>;
export type FilterCitiesLazyQueryHookResult = ReturnType<typeof useFilterCitiesLazyQuery>;
export type FilterCitiesSuspenseQueryHookResult = ReturnType<
  typeof useFilterCitiesSuspenseQuery
>;
export const FindAllCitiesForFilterDocument = gql`
  query findAllCitiesForFilter {
    findAllCities {
      value: id
      label: name
    }
  }
`;

/**
 * __useFindAllCitiesForFilterQuery__
 *
 * To run a query within a React component, call `useFindAllCitiesForFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllCitiesForFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllCitiesForFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllCitiesForFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindAllCitiesForFilterQuery,
    FindAllCitiesForFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindAllCitiesForFilterQuery,
    FindAllCitiesForFilterQueryVariables
  >(FindAllCitiesForFilterDocument, options);
}
export function useFindAllCitiesForFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindAllCitiesForFilterQuery,
    FindAllCitiesForFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindAllCitiesForFilterQuery,
    FindAllCitiesForFilterQueryVariables
  >(FindAllCitiesForFilterDocument, options);
}
export function useFindAllCitiesForFilterSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindAllCitiesForFilterQuery,
        FindAllCitiesForFilterQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindAllCitiesForFilterQuery,
    FindAllCitiesForFilterQueryVariables
  >(FindAllCitiesForFilterDocument, options);
}
export type FindAllCitiesForFilterQueryHookResult = ReturnType<
  typeof useFindAllCitiesForFilterQuery
>;
export type FindAllCitiesForFilterLazyQueryHookResult = ReturnType<
  typeof useFindAllCitiesForFilterLazyQuery
>;
export type FindAllCitiesForFilterSuspenseQueryHookResult = ReturnType<
  typeof useFindAllCitiesForFilterSuspenseQuery
>;
export const ApproveStationDocument = gql`
  mutation ApproveStation($id: ID!) {
    approveStation(id: $id) {
      id
      status
      updatedDate
    }
  }
`;

/**
 * __useApproveStationMutation__
 *
 * To run a mutation, you first call `useApproveStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveStationMutation, { data, loading, error }] = useApproveStationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveStationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ApproveStationMutation,
    ApproveStationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ApproveStationMutation,
    ApproveStationMutationVariables
  >(ApproveStationDocument, options);
}
export type ApproveStationMutationHookResult = ReturnType<
  typeof useApproveStationMutation
>;
export const RejectStationDocument = gql`
  mutation RejectStation($id: ID!, $reason: String!, $alternativeStationId: ID) {
    rejectStation(id: $id, reason: $reason, alternativeStationId: $alternativeStationId) {
      id
      status
      updatedDate
    }
  }
`;

/**
 * __useRejectStationMutation__
 *
 * To run a mutation, you first call `useRejectStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectStationMutation, { data, loading, error }] = useRejectStationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *      alternativeStationId: // value for 'alternativeStationId'
 *   },
 * });
 */
export function useRejectStationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RejectStationMutation,
    RejectStationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RejectStationMutation,
    RejectStationMutationVariables
  >(RejectStationDocument, options);
}
export type RejectStationMutationHookResult = ReturnType<typeof useRejectStationMutation>;
