import { MapWithMarkerProps } from "../../MapWithMarker";
import getCoordinatesFromStrings from "../getCoordinatesFromStrings/getCoordinatesFromStrings";
import isValidCoordinate from "../isValidCoordinate/isValidCoordinate";
import MapPin from "../mapPin/mapPin";

export default (
  positions: {
    lat?: string | null;
    lng?: string | null;
    pin: MapPin;
    tooltipContent?: JSX.Element;
  }[]
): MapWithMarkerProps["markers"] => {
  return positions
    .map((position) => {
      if (position.lat && position.lng) {
        const coordinates = getCoordinatesFromStrings(position.lat, position.lng);

        if (isValidCoordinate(coordinates)) {
          return {
            lat: coordinates.lat,
            lng: coordinates.lng,
            pin: position.pin,
            tooltipContent: position.tooltipContent,
          };
        }
      }
      return undefined;
    })
    .filter((position) => position !== undefined) as MapWithMarkerProps["markers"];
};
