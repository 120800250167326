import { RelationType } from "../../../../shared/types/schema";
import { FindSourceAndDestinationSchedulesForImportQuery } from "../../api/operations.generated";

export default (
  destinationMatrix: FindSourceAndDestinationSchedulesForImportQuery["findDestinationSchedule"]["pricingPeriods"][number]["relationMatrix"],
  sourceMatrix: FindSourceAndDestinationSchedulesForImportQuery["findSourceSchedule"]["pricingPeriods"][number]["relationMatrix"]
): FindSourceAndDestinationSchedulesForImportQuery["findSourceSchedule"]["pricingPeriods"][number]["relationMatrix"] => {
  const mergedMatrix = JSON.parse(JSON.stringify(destinationMatrix));

  destinationMatrix.rows.forEach((destinationRow, rowIndex) => {
    destinationRow.entries.forEach((destinationEntry, colIndex) => {
      if (destinationEntry.type !== RelationType.Unserved) {
        sourceMatrix.rows.forEach((sourceRow) => {
          sourceRow.entries.forEach((sourceEntry) => {
            if (
              sourceEntry.type === RelationType.Forbidden ||
              sourceEntry.type === RelationType.Regular
            ) {
              if (
                destinationEntry.from.id === sourceEntry.from.id &&
                destinationEntry.to.id === sourceEntry.to.id
              ) {
                mergedMatrix.rows[rowIndex].entries[colIndex].price = sourceEntry.price;
                mergedMatrix.rows[rowIndex].entries[colIndex].type = sourceEntry.type;
              }
            }
          });
        });
      }
    });
  });

  return mergedMatrix;
};
