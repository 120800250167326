@use "variables" as *;

.autocompleteWrapper {
  margin-bottom: $spacing-2;
}

.labelWrapper {
  padding-bottom: $spacing-half;
}

.label {
  color: $content-secondary-color;
}

.listWrapper {
  max-height: 205px;
  overflow-y: scroll;
  border: solid $line-primary-color;
  border-radius: $border-radius;

  li {
    border-color: transparent;

    div {
      cursor: default;
    }
  }
}

.listWrapperItemContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listWrapperItemText {
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconDelete {
  cursor: pointer;
  color: $content-secondary-color;
  margin-left: auto;
}

.noOptionInfo {
  padding: $spacing-1 $spacing-2;
}
