import { Icon, IconDelete, IconPlus } from "@flixbus/honeycomb-icons-react";
import { Button, ButtonGroup } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./RowButtons.scss";

type Props = {
  onAdd: () => void;
  onDelete: () => void;
  indexOfRow: number;
  totalNumOfRows: number;
};

const RowButtons: React.FC<Props> = ({ onAdd, onDelete, indexOfRow, totalNumOfRows }) => {
  return (
    <div className={css.buttonWrapper}>
      <ButtonGroup>
        {totalNumOfRows > 1 && (
          <Button
            data-id="delete-button"
            display="square"
            aria-label="delete row"
            onClick={onDelete}
            extraClasses={css.button}
          >
            <Icon InlineIcon={IconDelete} />
          </Button>
        )}
        {indexOfRow === totalNumOfRows - 1 && (
          <Button
            data-id="add-button"
            display="square"
            aria-label="add row"
            onClick={onAdd}
            extraClasses={css.button}
          >
            <Icon InlineIcon={IconPlus} />
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
};

export default RowButtons;
