@use "variables" as *;

.nav {
  border-bottom: $border;
  margin-bottom: 0px;
}

.navItemWrapper {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: $spacing-1;
}

.wrapper {
  padding-top: $spacing-4;
}

.stationApproverButton {
  position: relative;
  float: right;
  top: -30px;
}
