@use "variables" as *;

.wrapper {
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.deleteIcon {
  cursor: pointer;
}
