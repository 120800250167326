@use "variables" as *;

.box {
  padding: $spacing-2;
}

.checkBox {
  font-weight: $font-weight-bold;
  margin-bottom: 0;
}

.minTransferTime {
  margin-top: $spacing-2;
}
