@use "variables" as *;

.button {
  color: $danger-color;
  border-color: $danger-color;
  &:hover:enabled {
    background-color: $danger-color;
    color: #fff;
  }
}
