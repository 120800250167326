@use "variables" as *;

.wrapper {
  border: 1px solid $line-primary-color;
  border-radius: $spacing-1;
  max-height: 274px;
  overflow: auto;
}

.listWrapper {
  > li {
    border-left: none;
    border-right: none;
    > span {
      padding-top: $spacing-1;
      padding-bottom: $spacing-1;
    }
  }

  > li:first-child {
    border-top: none;
  }

  > li:last-child {
    border-bottom: none;
  }
}

.arrowIconWrapper {
  display: flex;
  justify-content: center;
}

.listItemContent {
  display: grid;
  grid-template-columns: 360px 60px 360px auto;
  height: 100%;
  align-items: center;
}

.box {
  height: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $content-secondary-color;
}

.boxNoResults {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.relation {
  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
