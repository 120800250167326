import { PricingPeriodsFromQuery } from "../../types";

const getPriceMatrixIndex = (
  pricingPeriods: PricingPeriodsFromQuery,
  selectedPeriodStartDate: string | null
): number => {
  let index;

  if (selectedPeriodStartDate === null) {
    index = pricingPeriods.findIndex(({ period }) => period === null);
  } else {
    index = pricingPeriods.findIndex(
      ({ period }) => period?.start === selectedPeriodStartDate
    );
  }

  return index < 0 ? pricingPeriods.findIndex(({ period }) => period === null) : index;
};

export default getPriceMatrixIndex;
