import { Icon, IconDownload } from "@flixbus/honeycomb-icons-react";
import { Button, PopupSection } from "@flixbus/honeycomb-react";
import cityTemplate from "@flixbus-phx/marketplace-common/assets/csv-templates/city-template.xlsx";
import stationTemplate from "@flixbus-phx/marketplace-common/assets/csv-templates/station-template.xlsx";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import ImportPopupButtonWrapper from "../../importPopupButtonWrapper/ImportPopupButtonWrapper";
import ImportPopupHeader from "../../importPopupHeader/ImportPopupHeader";
import UploadButton from "../../uploadButton/UploadButton";
import * as css from "./Initial.scss";

export type InitialProps = {
  isUploading: boolean;
  onUpload: (files: FileList | null) => void;
  onCancel: () => void;
  type: "station" | "city";
};

const Initial: React.FC<InitialProps> = ({ isUploading, onUpload, onCancel, type }) => {
  return (
    <>
      <ImportPopupHeader color="neutral">
        <FormattedMessage id="station.create.upload" />
      </ImportPopupHeader>

      <PopupSection type="content">
        <div className={css.textTop}>
          <FormattedMessage id="importPopup.initial.instruction.top" />
        </div>
        <Button
          Elem="a"
          href={type === "station" ? stationTemplate : cityTemplate}
          download
          appearance="secondary"
        >
          <Icon InlineIcon={IconDownload} />
          <FormattedMessage id="importPopup.downloadTemplate.button.text" />
        </Button>
        <div className={css.textBottom}>
          <FormattedMessage id="importPopup.initial.instruction.bottom" />
        </div>
        <UploadButton fileIsUploading={isUploading} onUpload={onUpload} />
      </PopupSection>

      <ImportPopupButtonWrapper>
        <Button onClick={onCancel}>
          <FormattedMessage id="general.cancel" />
        </Button>
        <Button appearance="primary" disabled>
          <FormattedMessage id="general.confirm" />
        </Button>
      </ImportPopupButtonWrapper>
    </>
  );
};

export default Initial;
