import { getThemeSetting } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import Map, {
  LngLatLike,
  MapRef,
  Marker,
  NavigationControl,
  Popup,
} from "react-map-gl/maplibre";
import "maplibre-gl/dist/maplibre-gl.css";
import MapPin from "./helper/mapPin/mapPin";

export type MapWithMarkerProps = {
  markers: Array<{
    lat: number;
    lng: number;
    pin: MapPin;
    tooltipContent?: JSX.Element;
  }>;
};

export const MAP_PADDING = { top: 55, bottom: 0, left: 30, right: 30 };
export const INITIAL_ZOOM = 7;
export const MAP_POITION_WITHOUT_MARKERS = { lat: 48.373206, lng: 10.871791 };

const MapWithMarker: React.FC<MapWithMarkerProps> = ({ markers }) => {
  const [popupInfo, setPopupInfo] = React.useState<{
    lat: number;
    lng: number;
    content: MapWithMarkerProps["markers"][number]["tooltipContent"];
  }>();
  const mapRef = React.useRef<MapRef>(null);

  const markersOnMap = React.useMemo(
    () =>
      markers.map((marker) => (
        <Marker
          key={`${marker.lat}-${marker.lng}`}
          latitude={marker.lat}
          longitude={marker.lng}
          offset={
            marker.pin === MapPin.GREEN_PIN || marker.pin === MapPin.RED_PIN
              ? [0, -23]
              : [0, 0]
          }
        >
          <img
            src={marker.pin as unknown as string}
            alt="pin"
            onMouseEnter={() => {
              if (marker.tooltipContent) {
                setPopupInfo({
                  lat: marker.lat,
                  lng: marker.lng,
                  content: marker.tooltipContent,
                });
              }
            }}
            onMouseLeave={() => {
              setPopupInfo(undefined);
            }}
          />
        </Marker>
      )),
    [markers]
  );

  const mapBounds: [LngLatLike, LngLatLike] = React.useMemo(() => {
    return [
      {
        lng: Math.min(...markers.map((marker) => marker.lng)),
        lat: Math.min(...markers.map((marker) => marker.lat)),
      },
      {
        lng: Math.max(...markers.map((marker) => marker.lng)),
        lat: Math.max(...markers.map((marker) => marker.lat)),
      },
    ];
  }, [markers]);

  // If the markers coordinates are beeing changed we recenter the map.
  // If all markers are removed we center the map to the default position.
  React.useEffect(() => {
    if (markers.length > 0) {
      mapRef.current?.fitBounds(mapBounds, {
        padding: MAP_PADDING,
        animate: true,
        zoom: INITIAL_ZOOM,
      });
    } else {
      mapRef.current?.flyTo({
        center: [MAP_POITION_WITHOUT_MARKERS.lng, MAP_POITION_WITHOUT_MARKERS.lat],
        zoom: INITIAL_ZOOM,
        essential: true,
      });
    }
  }, [mapBounds]);

  return (
    <Map
      ref={mapRef}
      initialViewState={{
        longitude: markers.length ? markers[0].lng : MAP_POITION_WITHOUT_MARKERS.lng,
        latitude: markers.length ? markers[0].lat : MAP_POITION_WITHOUT_MARKERS.lat,
        zoom: INITIAL_ZOOM,
        // If there is more than 1 marker we want to fit the bounds of the map to the markers.
        // If there is only 1 marker we want to show the marker in the center of the map.
        // By setting bounds to undefined in the case of 1 marker the Map uses the longitude and latitude values
        bounds: markers.length > 1 && mapBounds ? mapBounds : undefined,
        fitBoundsOptions: { padding: MAP_PADDING },
      }}
      mapStyle={
        getThemeSetting() === "flix-dark"
          ? "https://styles.gis.flix.tech/v2/styles/dark"
          : "https://styles.gis.flix.tech/v2/styles/contrast"
      }
      scrollZoom={false}
    >
      <NavigationControl showZoom showCompass={false} position="top-left" />
      {markersOnMap}

      {popupInfo && (
        <>
          <Popup
            anchor="top"
            longitude={Number(popupInfo.lng)}
            latitude={Number(popupInfo.lat)}
            closeButton={false}
            style={{ fontSize: "14px" }}
          >
            {popupInfo.content}
          </Popup>
          {/* Hacking in some styles to make the spacing more "Flix Style" */}
          <style>{".maplibregl-popup-content{padding: 6px 12px}"}</style>
        </>
      )}
    </Map>
  );
};

export default MapWithMarker;
