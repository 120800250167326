@use "variables" as *;

.pagerNavigation {
  display: flex;
  flex-direction: row;
}

.button:focus {
  z-index: 2;
}

.buttonLeft {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.buttonRight {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.input {
  z-index: 1;

  div {
    width: 60px;
    border-radius: 0;
    border-color: $grayscale-50-color;
  }

  input {
    text-align: center;
  }
}
