import { ReactiveVar, useReactiveVar } from "@apollo/client";
import { InvalidPricing } from "./InvalidPricing";

const usePriceInvalidForSchedule = (
  priceInvalidForScheduleVar: ReactiveVar<InvalidPricing>
): [InvalidPricing, (invalidPricing: InvalidPricing) => void] => {
  const priceInvalidForSchedule = useReactiveVar(priceInvalidForScheduleVar);

  const setPriceInvalidForSchedule = (invalidPricing: InvalidPricing) => {
    priceInvalidForScheduleVar(invalidPricing);
  };

  return [priceInvalidForSchedule, setPriceInvalidForSchedule];
};

export default usePriceInvalidForSchedule;
