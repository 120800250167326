@use "variables" as *;

.trip {
  [mouse-out-visibility="hidden"] {
    visibility: hidden;
  }
  &:hover,
  &:focus {
    [mouse-out-visibility="hidden"] {
      visibility: visible;
    }
  }
  &:nth-child(4),
  &:nth-child(3) {
    border-bottom-left-radius: 0;
  }
}

.cells {
  &:nth-child(even) {
    background: $grayscale-10-color;
  }
}
