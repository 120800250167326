import {
  Box,
  Button,
  ButtonGroup,
  FormRow,
  Grid,
  GridCol,
} from "@flixbus/honeycomb-react";
import { API_DATE_FORMAT } from "@flixbus-phx/marketplace-common";
import { format } from "date-fns";
import { Formik, Field, FormikActions } from "formik";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import buildPath from "../../../../routes/buildPath/buildPath";
import Datepicker from "../../../../shared/components/datepicker/Datepicker";
import Input from "../../../../shared/components/input/Input";
import { Line } from "../../../../shared/types/schema";
import validationSchema from "../../helpers/validationSchemas/ScheduleForm.validationSchema";
import { NewScheduleOnForm } from "../../types";

export type ScheduleFormProps = {
  lineId: Line["id"];
  onCreateSchedule: (values: NewScheduleOnForm) => void;
  loading: boolean;
};

const ScheduleForm: React.FC<ScheduleFormProps> = ({
  onCreateSchedule,
  lineId,
  loading,
}) => {
  const { formatMessage } = useIntl();
  const initialValues: NewScheduleOnForm = {
    name: "",
    period: {
      start: undefined,
      end: undefined,
    },
  };

  const handleOnSubmit = (
    values: NewScheduleOnForm,
    formikBag: FormikActions<NewScheduleOnForm>
  ): void => {
    formikBag.setSubmitting(true);
    const formattedValues: NewScheduleOnForm = {
      name:
        values.name !== "" ? values.name : `DRAFT_${format(Date.now(), API_DATE_FORMAT)}`,
      period: {
        start: format(values.period.start, API_DATE_FORMAT),
        end: format(values.period.end, API_DATE_FORMAT),
      },
    };
    onCreateSchedule(formattedValues);
    formikBag.resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikBag) => handleOnSubmit(values, formikBag)}
      validationSchema={validationSchema}
      render={({ handleSubmit, setFieldValue, isSubmitting, errors, touched }) => (
        <Box>
          <form onSubmit={handleSubmit} noValidate>
            <FormRow>
              <Grid>
                <GridCol size={12} lg={6}>
                  <Field
                    label={formatMessage({ id: "schedule.name" })}
                    component={Input}
                    type="text"
                    placeholder={formatMessage({
                      id: "scheduleForm.name.input.placeholder",
                    })}
                    name="name"
                    tabIndex={0}
                    maxLength="255"
                  />
                </GridCol>
              </Grid>
            </FormRow>
            <FormRow>
              <Grid>
                <GridCol size={6}>
                  <Datepicker
                    leftLabel={formatMessage({ id: "calendar.period.input.start" })}
                    leftId="picker-start"
                    rightLabel={formatMessage({ id: "calendar.period.input.end" })}
                    rightId="picker-end"
                    onValuesSet={(newValues) => {
                      setFieldValue("period", newValues);
                    }}
                    hasError={!!touched.period && !!errors.period}
                  />
                </GridCol>
              </Grid>
            </FormRow>
            <FormRow>
              <Grid>
                <GridCol size={12}>
                  <ButtonGroup>
                    <Button
                      type="button"
                      appearance="secondary"
                      tabIndex={-1}
                      RouterLink={Link}
                      to={buildPath.showLineDetail(lineId)}
                    >
                      <FormattedMessage id="general.back" />
                    </Button>
                    <Button
                      disabled={isSubmitting || loading}
                      type="submit"
                      appearance="primary"
                      tabIndex={0}
                      loading={loading}
                    >
                      <FormattedMessage id="general.save" />
                    </Button>
                  </ButtonGroup>
                </GridCol>
              </Grid>
            </FormRow>
          </form>
        </Box>
      )}
    />
  );
};

export default ScheduleForm;
