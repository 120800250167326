import greenDot from "@flixbus-phx/marketplace-common/assets/map/green_dot.svg";
import greenPin from "@flixbus-phx/marketplace-common/assets/map/green_pin.svg";
import redPin from "@flixbus-phx/marketplace-common/assets/map/red_pin.svg";

enum MapPin {
  GREEN_PIN = greenPin,
  RED_PIN = redPin,
  GREEN_DOT = greenDot,
}

export default MapPin;
