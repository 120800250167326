// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type NewScheduleMutationVariables = Types.Exact<{
  name: Types.Scalars["String"]["input"];
  lineId: Types.Scalars["ID"]["input"];
  period: Types.SchedulePeriodInput;
}>;

export type NewScheduleMutation = {
  __typename?: "Mutation";
  newSchedule: { __typename?: "Schedule"; id: string };
};

export const NewScheduleDocument = gql`
  mutation NewSchedule($name: String!, $lineId: ID!, $period: SchedulePeriodInput!) {
    newSchedule(name: $name, lineId: $lineId, period: $period) {
      id
    }
  }
`;

/**
 * __useNewScheduleMutation__
 *
 * To run a mutation, you first call `useNewScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newScheduleMutation, { data, loading, error }] = useNewScheduleMutation({
 *   variables: {
 *      name: // value for 'name'
 *      lineId: // value for 'lineId'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useNewScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NewScheduleMutation,
    NewScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<NewScheduleMutation, NewScheduleMutationVariables>(
    NewScheduleDocument,
    options
  );
}
export type NewScheduleMutationHookResult = ReturnType<typeof useNewScheduleMutation>;
