@use "variables" as *;

.wrapper {
  display: grid;
  align-items: start;
  grid-gap: 0 $spacing-2;
  justify-content: start;
}

.scrollableWrapper {
  overflow-x: scroll;
  align-items: start;
  margin: 0 $spacing-2;
}

.stationCellLabel {
  margin-top: 144px;
  margin-right: -12px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  z-index: 1;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
}

.stationCellLabelCells {
  &:nth-child(odd) {
    background: $grayscale-10-color;
  }
}

.outwardContainer {
  display: flex;
  margin-bottom: $spacing-6;
}

.returnContainer {
  display: flex;
  margin-top: $spacing-12;
}

.addReturnScheduleButton {
  width: $timetable-station-box-width;
}
