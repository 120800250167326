@use "variables" as *;

.outerWrapper {
  height: 40px;
  border-bottom: $border;
}

.skeletonOuterWrapper {
  width: 100px;
  display: inline-block;
  padding: 10px 0;
}

.skeletonInnerWrapperLeft {
  border-right: $border;
  padding: 0 $spacing-2;
}

.skeletonInnerWrapperRight {
  padding: 0 $spacing-2;
}
