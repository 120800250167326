@use "variables" as *;
@use "../../styles/detail-summary.scss";

.skeletonWrapper {
  @include detail-summary.detail-summary-panel;
}

.skeletonContainer {
  @include page-container;
}

.box {
  @include detail-summary.detail-summary-box;
}

.tagSkeletonContainer {
  display: flex;
  gap: 9px;
  flex-direction: row;
  padding-left: $spacing-12;
}
.tag {
  height: 30px;
}

.skeleton {
  width: 80px;
}

.addTopMargin {
  @include detail-summary.detail-summary-extra-margin;
}
