@use "variables" as *;
@use "../../../../shared/styles/heading.scss";
@use "../../PartnerCapacityAndReportsForm.scss";

.container {
  padding-bottom: $spacing-1;
}

.emailRow {
  display: flex;
  flex-wrap: wrap;
  column-gap: $spacing-2;

  .emailInput {
    flex: 0 0 30%;
  }

  .removeEmail {
    flex: 0 0 1%;
  }

  .clearfix {
    display: block;
    flex: 0 0 69%;
  }
}

.emailRowEditable {
  row-gap: $spacing-2;
}
