import { Accordion, Infobox, Table } from "@flixbus/honeycomb-react";
import { localizeDate, mpcPathnames as pathnames } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { ScheduleDetailFragment } from "../../api/operations.generated";
import * as css from "./ConceptChangeInfoBox.scss";

type Props = {
  conceptChangeOf: ScheduleDetailFragment["conceptChangeOf"];
  conceptChanges: ScheduleDetailFragment["conceptChanges"];
};

const ConceptChangeInfoBox: React.FC<Props> = ({ conceptChangeOf, conceptChanges }) => {
  const { formatMessage } = useIntl();
  const tableHeaders = [
    <b>
      <FormattedMessage id="schedule.name" />
    </b>,
    <b>
      <FormattedMessage id="schedule.period" />
    </b>,
  ];

  const rows = conceptChanges.map((data) => {
    const rowData = [];
    rowData.push(
      <Link to={pathnames.showScheduleDetail.replace(":scheduleId", data.id)}>
        {data.name}
      </Link>
    );
    rowData.push(`${localizeDate(data.period.start)} - ${localizeDate(data.period.end)}`);

    return rowData;
  });

  if (conceptChangeOf || conceptChanges.length > 0) {
    return (
      <Infobox small extraClasses={css.infobox}>
        {conceptChangeOf && (
          <>
            <FormattedMessage id="scheduleDetail.info.conceptChange.ofOtherSchedule" />
            &nbsp;
            <Link
              to={pathnames.showScheduleDetail.replace(":scheduleId", conceptChangeOf.id)}
            >
              {conceptChangeOf.name}
            </Link>
          </>
        )}
        {conceptChangeOf && conceptChanges.length > 0 && <p />}
        {conceptChanges.length > 0 && (
          <>
            <Accordion
              title={formatMessage({
                id: "scheduleDetail.info.conceptChange.existForThisSchedule",
              })}
              extraClasses={css.accordion}
            >
              <Table headers={tableHeaders} rows={rows} extraClasses={css.table} />
            </Accordion>
          </>
        )}
      </Infobox>
    );
  }
  return <></>;
};

export default ConceptChangeInfoBox;
