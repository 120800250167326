@use "../../shared/styles/heading.scss";
@use "variables" as *;

.divider {
  margin-top: $spacing-2;
}

.circulation {
  width: 30%;
}
