@use "variables" as *;
@use "../../helpers/styles/grid-vars.scss";
@use "../../helpers/styles/common-styles.scss";

$highlight-border-size: 2px;

.cell {
  @include grid-vars.schedule-calendar-grid-cell;
}

.clickableItem {
  cursor: pointer;
  &:hover {
    @include common-styles.weekend;
  }
}

.item {
  justify-self: stretch;
  align-self: stretch;
  text-align: center;
  line-height: grid-vars.$row-height - $highlight-border-size;
  font-weight: $font-weight-bold;
}

.currentDate {
  @include common-styles.highlighted-cell;
  border-bottom: 2px solid $ui-primary-color;
}

.weekend {
  @include common-styles.weekend;
}
