import { PriceDirection, RelationType } from "../../../../shared/types/schema";
import { PricingPeriodsFromQuery } from "../../types";
import getPriceMatrixIndex from "../getPriceMatrixIndex/getPriceMatrixIndex";

export const updateRelationMatrixEntry = (
  row: number,
  col: number,
  value: number | null,
  pricingPeriods: PricingPeriodsFromQuery,
  indexOfPeriodToUpdate: number
): PricingPeriodsFromQuery => {
  const newPricingPeriods = JSON.parse(JSON.stringify(pricingPeriods));
  const { relationMatrix } = newPricingPeriods[indexOfPeriodToUpdate];

  if (row < relationMatrix.rows.length && col < relationMatrix.rows[row].entries.length) {
    if (relationMatrix.rows[row].entries[col].type === RelationType.Unserved) {
      return newPricingPeriods;
    }
    if (value === null) {
      relationMatrix.rows[row].entries[col].price = undefined;
    } else {
      relationMatrix.rows[row].entries[col].price = value;
    }
  }

  newPricingPeriods[indexOfPeriodToUpdate].relationMatrix = relationMatrix;

  return newPricingPeriods;
};

export const updateMirroredRelationMatrix = (
  row: number,
  col: number,
  value: number | null,
  pricingPeriods: PricingPeriodsFromQuery,
  indexOfPeriodToUpdate: number
): PricingPeriodsFromQuery => {
  const pricingPeriodsUpperTriangleUpdated = updateRelationMatrixEntry(
    row,
    col,
    value,
    pricingPeriods,
    indexOfPeriodToUpdate
  );

  const newPricingPeriodsMirrored = updateRelationMatrixEntry(
    col,
    row,
    value,
    pricingPeriodsUpperTriangleUpdated,
    indexOfPeriodToUpdate
  );

  return newPricingPeriodsMirrored;
};

export const clearAllRelationMatrices = (
  pricingPeriods: PricingPeriodsFromQuery
): PricingPeriodsFromQuery => {
  return pricingPeriods.map((pricingPeriod) => {
    return {
      ...pricingPeriod,
      relationMatrix: {
        rows: pricingPeriod.relationMatrix.rows.map((row) => {
          return {
            entries: row.entries.map((entry) => {
              const newEntry = { ...entry };

              newEntry.price = undefined;

              return newEntry;
            }),
          };
        }),
      },
    };
  });
};

export const mirrorRelationMatrix = (
  pricingPeriods: PricingPeriodsFromQuery,
  indexOfPeriodToUpdate: number
): PricingPeriodsFromQuery => {
  const newPricingPeriods: PricingPeriodsFromQuery = JSON.parse(
    JSON.stringify(pricingPeriods)
  );
  const { relationMatrix } = newPricingPeriods[indexOfPeriodToUpdate];

  newPricingPeriods[indexOfPeriodToUpdate].relationMatrix.rows.forEach(
    (row, rowIndex) => {
      for (let colIndex = rowIndex + 1; colIndex < row.entries.length; colIndex++) {
        const upperTriangleEntry = row.entries[colIndex];

        if (
          relationMatrix.rows[colIndex].entries[rowIndex].type !== RelationType.Unserved
        ) {
          relationMatrix.rows[colIndex].entries[rowIndex].price =
            upperTriangleEntry.price;
          relationMatrix.rows[colIndex].entries[rowIndex].type = upperTriangleEntry.type;
        }
      }
    }
  );

  newPricingPeriods[indexOfPeriodToUpdate].relationMatrix = relationMatrix;

  return newPricingPeriods;
};

export const convertPricingPeriodsToPricingPeriodsInput = (
  pricingPeriods: PricingPeriodsFromQuery
) => {
  return pricingPeriods.map((pricingPeriod) => {
    return {
      direction: pricingPeriod.direction,
      period: pricingPeriod.period
        ? {
            start: pricingPeriod.period.start,
            end: pricingPeriod.period.end,
          }
        : null,
      relationMatrix: {
        rows: pricingPeriod.relationMatrix.rows.map((row) => {
          return {
            entries: row.entries.map((entry) => {
              return {
                price: entry.price,
                type: entry.type,
                fromId: entry.from.id,
                toId: entry.to.id,
              };
            }),
          };
        }),
      },
    };
  });
};

export const changePriceDirectionOfPricingPeriod = (
  pricingPeriods: PricingPeriodsFromQuery,
  indexOfPeriodToUpdate: number,
  direction: PriceDirection
): PricingPeriodsFromQuery => {
  const newPricingPeriods = JSON.parse(JSON.stringify(pricingPeriods));
  newPricingPeriods[indexOfPeriodToUpdate].direction = direction;

  return newPricingPeriods;
};

export const filterPeriod = (
  pricingPeriods: PricingPeriodsFromQuery,
  periodToDeleteStartDate: string
) => {
  return pricingPeriods.filter(({ period }) => period?.start !== periodToDeleteStartDate);
};

export const addPeriod = (
  pricingPeriods: PricingPeriodsFromQuery,
  newPeriod: NonNullable<PricingPeriodsFromQuery[number]["period"]>
) => {
  const newPricingPeriods = JSON.parse(JSON.stringify(pricingPeriods));
  const defaultPeriodIndex = getPriceMatrixIndex(pricingPeriods, null);

  newPricingPeriods.push({
    period: newPeriod,
    direction: pricingPeriods[defaultPeriodIndex].direction,
    relationMatrix: pricingPeriods[defaultPeriodIndex].relationMatrix,
  });

  return newPricingPeriods;
};
