import { Grid, GridCol } from "@flixbus/honeycomb-react";
import {
  addNotification,
  DefaultInfoAlert,
  formatErrorMessage,
  NotificationType,
  hasUserAtLeastOnePermission,
  Feature,
  legacyTranslate,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import ScheduleStatusTagTooltip from "../../shared/components/scheduleStatusTagTooltip/ScheduleStatusTagTooltip";
import * as css from "./ScheduleHeader.scss";
import {
  useFindScheduleNameQuery,
  useUpdateScheduleNameMutation,
} from "./api/operations.generated";
import ScheduleActions from "./containers/scheduleActions/ScheduleActions";
import ScheduleHeaderSkeleton from "./ui/scheduleHeaderSkeleton/ScheduleHeaderSkeleton";
import ScheduleHeaderWrapper from "./ui/scheduleHeaderWrapper/ScheduleHeaderWrapper";
import ScheduleName from "./ui/scheduleName/ScheduleName";

type Props = {
  scheduleId: string;
};

const ScheduleHeader: React.FC<Props> = ({ scheduleId }) => {
  const { formatMessage } = useIntl();
  const { data, loading, error } = useFindScheduleNameQuery({
    variables: {
      id: scheduleId,
    },
  });

  const [updateScheduleName] = useUpdateScheduleNameMutation({
    onCompleted: () =>
      addNotification({
        message: formatMessage({ id: "scheduleDetail.name.submit.notification.success" }),
      }),
    onError: (mutationError) =>
      addNotification({
        message: `Schedule update failed: ${formatErrorMessage(mutationError.message)}`,
        type: NotificationType.Danger,
      }),
  });

  if (loading) {
    return (
      <ScheduleHeaderWrapper scheduleId={scheduleId}>
        <ScheduleHeaderSkeleton />
      </ScheduleHeaderWrapper>
    );
  }

  if (error) {
    return (
      <ScheduleHeaderWrapper scheduleId={scheduleId}>
        <DefaultInfoAlert message={error.message} translate={legacyTranslate} />
      </ScheduleHeaderWrapper>
    );
  }

  if (data?.findSchedule) {
    const schedule = data.findSchedule;

    return (
      <ScheduleHeaderWrapper scheduleId={scheduleId}>
        <Grid>
          <GridCol size={11} extraClasses={css.wrapper}>
            <ScheduleStatusTagTooltip
              scheduleId={schedule.id}
              displayedScheduleStatus={schedule.displayedScheduleStatus}
              isScheduleOutdated={schedule.isOutdated}
            />

            <div className={css.scheduleName}>
              <ScheduleName
                name={schedule.name}
                readOnly={schedule.readOnly}
                onNameChange={(newName) => {
                  updateScheduleName({
                    variables: {
                      id: schedule.id,
                      name: newName,
                    },
                  });
                }}
              />
            </div>
          </GridCol>
          {hasUserAtLeastOnePermission([
            Feature.CREATE_SCHEDULE_CONCEPT_CHANGE,
            Feature.CREATE_SCHEDULE_COPY,
            Feature.DELETE_SCHEDULE,
            Feature.VIEW_SCHEDULE_STATUS_HISTORY,
            Feature.EDIT_SCHEDULE_STATUS,
          ]) ? (
            <GridCol size={1}>
              <ScheduleActions
                scheduleId={schedule.id}
                scheduleName={schedule.name}
                scheduleStatus={schedule.status}
                displayedScheduleStatus={schedule.displayedScheduleStatus}
                originalScheduleStatus={schedule.originalSchedule.status}
                lineId={schedule.line.id}
                readOnly={schedule.readOnly}
              />
            </GridCol>
          ) : (
            <></>
          )}
        </Grid>
      </ScheduleHeaderWrapper>
    );
  }

  return <></>;
};

export default ScheduleHeader;
