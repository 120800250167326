import { Button, ButtonGroup } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DeleteButton from "../../../../shared/components/deleteButton/DeleteButton";
import FormikButtons from "../../../../shared/components/formikButtons/FormikButtons";
import * as css from "./PartnerDetailFormButtons.scss";

export type PartnerDetailFormButtonsProps = {
  isLoading: boolean;
  isEditable: boolean;
  isDeleteButtonShown: boolean;
  isDeleteButtonEnabled: boolean;
  onDelete: () => void;
  onClose: () => void;
  partnerName: string;
  isOutsideOfFormik: boolean;
};

const PartnerDetailFormButtons: React.FC<PartnerDetailFormButtonsProps> = ({
  isLoading,
  isEditable,
  isDeleteButtonShown,
  isDeleteButtonEnabled,
  onDelete,
  onClose,
  partnerName,
  isOutsideOfFormik,
}) => {
  const { formatMessage } = useIntl();

  const deleteButton = (
    <DeleteButton
      onDelete={onDelete}
      buttonText={formatMessage({ id: "partner.delete" })}
      buttonTitle={formatMessage({ id: "partnerDetail.delete.button.title" })}
      popupTitle={formatMessage({ id: "partner.delete" })}
      popupText={formatMessage(
        { id: "partnerDetail.delete.popup.text" },
        {
          partnerName,
        }
      )}
      disabled={!isDeleteButtonEnabled}
    />
  );

  if (isEditable) {
    if (isOutsideOfFormik) {
      return (
        <div className={css.nonFormikButtons}>
          {isDeleteButtonShown && (
            <div className={css.nonFormikDeleteButton}>{deleteButton}</div>
          )}
          <ButtonGroup alignment="center">
            <Button appearance="secondary" onClick={onClose}>
              <FormattedMessage id="general.cancel" />
            </Button>
            <Button appearance="primary" onClick={onClose}>
              <FormattedMessage id="partnerDetail.submit.button" />
            </Button>
          </ButtonGroup>
        </div>
      );
    }

    return (
      <FormikButtons
        submitButtonText={formatMessage({ id: "partnerDetail.submit.button" })}
        submitButtonLoading={isLoading}
        submitButtonAppearance="primary"
        resetButtonText={formatMessage({ id: "general.cancel" })}
        resetButtonAppearance="secondary"
        disableSubmitWhenNotDirty
      >
        {isDeleteButtonShown && deleteButton}
      </FormikButtons>
    );
  }

  return (
    <div className={css.cancelButton}>
      <Button appearance="primary" onClick={onClose}>
        <FormattedMessage id="general.close" />
      </Button>
    </div>
  );
};

export default PartnerDetailFormButtons;
