.timeInput {
  width: 70%;
  margin: 5px auto;
  text-align: center;
}

.popup {
  div[class*="hcr-popup__body"] {
    width: 400px;
  }
}
