@use "variables" as *;
@use "../../styles/detail-summary.scss";

.panel {
  @include detail-summary.detail-summary-panel;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.addTopMargin {
  @include detail-summary.detail-summary-extra-margin;
}

.noPointer {
  cursor: default;
}

.container {
  @include page-container;
}

.box {
  @include detail-summary.detail-summary-box;
}

.boxContent {
  width: 100%;
}

.boxIcon {
  padding-right: $spacing-2;
  margin-top: 5px;
  color: $content-primary-color;
}

.boxHeading {
  color: $heading-color;
  font-size: 28px;
  font-weight: $font-weight-bold;
  line-height: 34px;

  padding-bottom: $spacing-1;
}

.boxFirstSubtitle {
  width: 100%;
  padding-bottom: $spacing-half;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.boxSecondSubTitle {
  display: flex;
}

.separationDot {
  align-self: center;
  height: 3px;
  width: 3px;
  border-radius: 1.5px;
  background-color: $content-primary-color;
  margin: 0 $spacing-2;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.formContainer {
  height: 100%;
  width: 100vw;
  border-bottom: $border;
  margin-bottom: $spacing-4;
}

.formInnerContainer {
  @include page-container;
}

.tagWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  padding-left: $spacing-12;
}
