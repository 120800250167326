import { DefaultInfoAlert, legacyTranslate } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import InputSkeleton from "../../../../shared/components/inputSkeleton/InputSkeleton";
import Select from "../../../../shared/components/select/Select";
import { Period, Schedule } from "../../../../shared/types/schema";
import { useFindAllSchedulesForImportQuery } from "../../api/operations.generated";
import filterSchedulesForImport from "../../helpers/filterSchedulesForPriceImport/filterSchedulesForPriceImport";
import pricingPeriodImportOptions from "../../helpers/pricingPeriodImportOptions/pricingPeriodImportOptions";
import * as css from "./ImportPricingPopupBody.scss";

export type ImportPricingPopupBodyProps = {
  scheduleId: Schedule["id"];
  onScheduleSelected: (schedule: Schedule["id"]) => void;
  onPricingPeriodSelected: (periodStart: Period["start"]) => void;
};

const ImportPricingPopupBody: React.FC<ImportPricingPopupBodyProps> = ({
  scheduleId,
  onScheduleSelected,
  onPricingPeriodSelected,
}) => {
  const [selectedSchedule, setSelectedSchedule] = React.useState<Schedule["id"]>();

  const { formatMessage } = useIntl();

  const { data, loading, error } = useFindAllSchedulesForImportQuery({
    variables: { scheduleId },
  });

  const pricingPeriodsOfSelectedSchedule = React.useMemo(() => {
    if (selectedSchedule && data?.findSchedule) {
      const periods = data.findSchedule.line.schedules.find(
        (schedule) => schedule.id === selectedSchedule
      )?.pricingPeriods;

      if (periods) {
        onPricingPeriodSelected("null");
        return pricingPeriodImportOptions(periods);
      }
    }

    return [];
  }, [selectedSchedule]);

  if (error) {
    return (
      <DefaultInfoAlert
        data-id="query-error"
        message={formatMessage({ id: "general.somethingWentWrong" })}
        translate={legacyTranslate}
      />
    );
  }

  if (loading) {
    return (
      <div className={css.wrapper}>
        <InputSkeleton />
      </div>
    );
  }

  if (data?.findSchedule) {
    const schedulesForImport = filterSchedulesForImport(
      data.findSchedule.line.schedules,
      scheduleId
    );

    if (schedulesForImport.length) {
      return (
        <>
          <div className={css.wrapper}>
            <Select
              data-id="schedule-select"
              label={formatMessage({ id: "general.schedule" })}
              placeholder={formatMessage({
                id: "prices.import.selectSchedule.placeholder",
              })}
              options={schedulesForImport}
              onSelect={(selectedValue) => {
                setSelectedSchedule(selectedValue.value);
                onScheduleSelected(selectedValue.value);
              }}
            />
          </div>
          {selectedSchedule &&
          pricingPeriodsOfSelectedSchedule &&
          pricingPeriodsOfSelectedSchedule.length > 1 ? (
            <div className={css.wrapper}>
              <Select
                data-id="pricing-period-select"
                label={formatMessage({ id: "general.pricingPeriod" })}
                initialValue={pricingPeriodsOfSelectedSchedule.find(
                  (option) => option.value === "null"
                )}
                options={pricingPeriodsOfSelectedSchedule}
                onSelect={({ value }) => {
                  onPricingPeriodSelected(value);
                }}
              />
            </div>
          ) : (
            <> </>
          )}
        </>
      );
    }
    return (
      <>
        <DefaultInfoAlert
          data-id="no-schedules-found-error"
          type="info"
          message={formatMessage({ id: "prices.import.popup.info.noSchedulesFound" })}
          translate={legacyTranslate}
        />
        <style>
          {
            "#confirmation-popup-confirm-button { display: none } #import-pricing-overwriting-warning { display: none }"
          }
        </style>
      </>
    );
  }

  return <></>;
};

export default ImportPricingPopupBody;
