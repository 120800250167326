import { formatMessage } from "@flixbus-phx/marketplace-common";
import { SelectOption } from "../../../../shared/helpers/reactSelect/types";
import { FindAllSchedulesForImportQuery } from "../../api/operations.generated";
import formatPeriodToString from "../formatPeriodToString/formatPeriodToString";

export default (
  pricingPeriods: FindAllSchedulesForImportQuery["findSchedule"]["line"]["schedules"][number]["pricingPeriods"]
): Array<SelectOption> => {
  const pricingPeriodOptions: Array<SelectOption> = [];

  pricingPeriods.forEach((pricingPeriod) => {
    if (pricingPeriod.period !== null) {
      pricingPeriodOptions.push({
        value: pricingPeriod.period?.start,
        label: formatPeriodToString(pricingPeriod.period),
      });
    }
  });

  return [
    {
      label: formatMessage("pricing.period.default"),
      value: "null",
    },
    ...pricingPeriodOptions.sort((a, b) => {
      return new Date(a.value).getTime() - new Date(b.value).getTime();
    }),
  ];
};
