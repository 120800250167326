@use "variables" as *;

.textContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $timetable-trip-col-width;
  height: 100%;
  grid-row: 1 / 2;
  overflow: hidden;

  border-radius: $border-radius;
  border: 1px dashed $line-primary-color;
  background-color: $bg-primary-color;

  &:hover {
    border: none;
    background-color: $grayscale-30-color;
  }
}

.icon {
  width: 50px !important;
  height: 50px !important;
  color: $icon-primary-color;
}

.text {
  color: $icon-primary-color;
}

.iconWrapper,
.textWrapper {
  text-align: center;
}
