import { Button, ButtonGroup, PopupSection, Text } from "@flixbus/honeycomb-react";
import { Popup } from "@flixbus-phx/marketplace-common";
import { Form, Formik } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import FormikStationSelect from "../../../../shared/components/formikStationSelect/FormikStationSelect";
import FormikTextarea from "../../../../shared/components/formikTextarea/FormikTextarea";
import { StationStatus } from "../../../../shared/types/schema";
import * as css from "./StationRejectionPopup.scss";

export type StationRejectionPopupProps = {
  stationId: string;
  active: boolean;
  confirmButtonLoading?: boolean;
  onConfirm: (values: { reason: string; alternativeStationId?: string }) => void;
  onCancel: () => void;
};

const StationRejectionPopup: React.FC<StationRejectionPopupProps> = ({
  stationId,
  active,
  confirmButtonLoading,
  onConfirm,
  onCancel,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Popup active={active} onClose={onCancel}>
      <Formik
        validationSchema={Yup.object().shape({
          reason: Yup.string().required(
            formatMessage({ id: "error.validation.required" })
          ),
          alternativeStationId: Yup.string(),
        })}
        initialValues={{ reason: "", alternativeStationId: "" }}
        onSubmit={(values) =>
          onConfirm({
            reason: values.reason,
            alternativeStationId: values.alternativeStationId || undefined,
          })
        }
        onReset={onCancel}
      >
        <Form noValidate>
          <PopupSection type="title">
            <div>
              <FormattedMessage id="station.rejectionReason" />
            </div>
          </PopupSection>
          <PopupSection type="content" extraClasses={css.contentWrapper}>
            <Text Elem="div">
              <p data-id="content-first">
                <FormattedMessage id="station.rejectionPopup.content.first" />
              </p>
              <p data-id="content-second">
                <FormattedMessage id="station.rejectionPopup.content.second" />
              </p>
            </Text>
            <FormikTextarea label="Reason for Rejection" name="reason" hideLabel />
            <div className={css.stationSelectWrapper}>
              <FormikStationSelect
                label={formatMessage({ id: "station.alternativeStation.input.label" })}
                name="alternativeStationId"
                disallowedValues={[stationId]}
                disallowedStates={[StationStatus.InReview, StationStatus.Rejected]}
                createStationPossible={false}
              />
            </div>
          </PopupSection>
          <PopupSection type="actions">
            <ButtonGroup alignment="center">
              <Button type="submit" appearance="primary" loading={confirmButtonLoading}>
                <FormattedMessage id="general.confirm" />
              </Button>
              <Button type="reset">
                <FormattedMessage id="general.cancel" />
              </Button>
            </ButtonGroup>
          </PopupSection>
        </Form>
      </Formik>
    </Popup>
  );
};

export default StationRejectionPopup;
