@use "variables" as *;

.wrapper {
  border: $border;
  border-radius: $border-radius;
  text-align: center;
  height: 120px;
  line-height: 120px;
  color: $content-secondary-color;
}
