@use "variables" as *;

.errorPageContainer {
  margin-left: auto;
  margin-right: auto;
  margin-top: $spacing-8;
  width: 400px;
  text-align: center;
}

.errorPageText {
  margin-top: $spacing-2;
}

.errorPageButton {
  margin-top: $spacing-3;
}
