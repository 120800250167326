import { Icon, IconCalendar, IconCash } from "@flixbus/honeycomb-icons-react";
import { NavHorizontal, NavItem } from "@flixbus/honeycomb-react";
import { DefaultInfoAlert, legacyTranslate } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Schedule, ScheduleStatus } from "../../shared/types/schema";
import { priceInvalidForScheduleVar } from "../../state/reactiveVariables/reactiveVariables";
import usePriceInvalidForSchedule from "../../state/usePriceInvalidForSchedule/usePriceInvalidForSchedule";
import StationDetailPanel from "../../station/stationDetailPanel/StationDetailPanel";
import Layout from "../../ui/Layout";
import PriceMatrix from "../priceMatrix/PriceMatrix";
import ScheduleFooter from "../scheduleFooter/ScheduleFooter";
import Timetable from "../timetable/Timetable";
import * as css from "./ScheduleTab.scss";
import { useFindScheduleStatusQuery } from "./api/operations.generated";
import ScheduleTabSkeleton from "./ui/scheduleTabSkeleton/ScheduleTabSkeleton";

type Props = {
  scheduleId: Schedule["id"];
};

const ScheduleTab: React.FC<Props> = ({ scheduleId }) => {
  const { formatMessage } = useIntl();
  const [showPrices, setShowPrices] = React.useState(false);

  const [priceInvalidForSchedule] = usePriceInvalidForSchedule(
    priceInvalidForScheduleVar
  );

  React.useEffect(() => {
    if (priceInvalidForSchedule.scheduleId === scheduleId) {
      setShowPrices(true);
    }
  }, [priceInvalidForSchedule]);

  const { data, loading, error } = useFindScheduleStatusQuery({
    variables: { id: scheduleId },
    onCompleted: (scheduleStatusData) =>
      setShowPrices(
        [
          ScheduleStatus.Approved,
          ScheduleStatus.InGeneration,
          ScheduleStatus.Published,
          ScheduleStatus.Accepted,
          ScheduleStatus.PricesPublished,
          ScheduleStatus.OnSale,
        ].includes(scheduleStatusData.findSchedule.status)
      ),
  });

  if (loading) {
    return <ScheduleTabSkeleton />;
  }

  if (error) {
    return (
      <Layout smallTopPadding>
        <DefaultInfoAlert
          message={formatMessage(
            { id: "schedule.error.scheduleNotFound" },
            { scheduleId }
          )}
          translate={legacyTranslate}
        />
      </Layout>
    );
  }

  if (data?.findSchedule) {
    return (
      <>
        <Layout smallTopPadding>
          <NavHorizontal
            extraClasses={css.nav}
            aria-label="switch between timetable and pricing"
          >
            <NavItem
              key="timetable"
              Elem={!showPrices ? "span" : "button"}
              active={!showPrices}
              onClick={() => setShowPrices(false)}
            >
              <div className={css.iconWrapper}>
                <Icon InlineIcon={IconCalendar} extraClasses={css.icon} />
                <FormattedMessage id="general.timetable" />
              </div>
            </NavItem>
            <NavItem
              key="prices"
              Elem={showPrices ? "span" : "button"}
              active={showPrices}
              onClick={() => setShowPrices(true)}
            >
              <div className={css.iconWrapper}>
                <Icon InlineIcon={IconCash} extraClasses={css.icon} />
                <FormattedMessage id="general.prices" />
              </div>
            </NavItem>
          </NavHorizontal>
        </Layout>
        <Layout data-id="second-layout" useFullscreen extraSmallTopPadding clearRight>
          {showPrices ? (
            <PriceMatrix scheduleId={scheduleId} />
          ) : (
            <>
              <Timetable scheduleId={scheduleId} />
              <StationDetailPanel hasNavigation scheduleId={scheduleId} />
            </>
          )}
        </Layout>
        <Layout useFullscreen noTopPadding>
          <ScheduleFooter
            scheduleId={scheduleId!}
            arePricesShown={showPrices}
            onSwitchToPrices={() => setShowPrices(true)}
          />
        </Layout>
      </>
    );
  }

  return <></>;
};

export default ScheduleTab;
