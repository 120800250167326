@use "variables" as *;

.defaultContainer {
  height: 100%;
  padding-top: calc(60px + #{$spacing-2});

  // Create a block formatting context (BFC)
  display: flow-root;

  @include on-bp(sm) {
    padding-top: calc(100px + #{$spacing-2});
  }
  @include on-bp(xl) {
    padding-top: calc(60px + #{$spacing-2});
  }
}

.fullscreen {
  max-width: unset;
  height: 100%;
  margin-left: $spacing-4;
  margin-right: $spacing-4;
  padding-left: $spacing-2;
  padding-right: $spacing-2;
}

.smallTopPadding {
  padding-top: $spacing-4;
}

.extraSmallTopPadding {
  padding-top: $spacing-2;
}

.noTopPadding {
  padding-top: 0;
}

.clearRight {
  padding-right: 0px;
  margin-right: 0px;
}

.clearLeft {
  padding-left: 0px;
  margin-left: 0px;
}

// Overwriting Normalizer
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
}

[class^="hcr-icon"] svg {
  pointer-events: none;
}

b {
  font-weight: $font-weight-bold;
}
