import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Currency, PriceDirection, Schedule } from "../../../../shared/types/schema";
import {} from "../../api/operations.generated";
import getPriceMatrixIndex from "../../helpers/getPriceMatrixIndex/getPriceMatrixIndex";
import mergeRelationMatricesForImport from "../../helpers/mergeRelationMatricesForImport/mergeRelationMatricesForImport";
import { RelationMatrixFromQuery } from "../../types";
import ImportPricingPopup from "../importPricingPopup/ImportPricingPopup";

export type ImportPricingButtonProps = {
  scheduleId: Schedule["id"];
  scheduleHasPrices: Schedule["hasPrices"];
  selectedPeriod: string | null;
  onPricingImport: (
    relationMatrix: RelationMatrixFromQuery,
    priceDirection: PriceDirection,
    currency: Currency
  ) => void;
};

const ImportPricingButton: React.FC<ImportPricingButtonProps> = ({
  scheduleId,
  scheduleHasPrices,
  selectedPeriod,
  onPricingImport,
}) => {
  const { formatMessage } = useIntl();
  const [showPopup, setShowPopup] = React.useState(false);

  return (
    <>
      <Button
        data-id="open-shift-popup-button"
        onClick={() => {
          setShowPopup(true);
        }}
        title={formatMessage({ id: "prices.import.label" })}
      >
        <FormattedMessage id="prices.import.label" />
      </Button>
      {showPopup && (
        <ImportPricingPopup
          scheduleId={scheduleId}
          scheduleHasPrices={scheduleHasPrices}
          onPricingImport={(
            destinationSchedule,
            sourceSchedule,
            selectedSourcePricingPeriod
          ) => {
            const sourcePricingPeriod =
              sourceSchedule.pricingPeriods[
                getPriceMatrixIndex(
                  sourceSchedule.pricingPeriods,
                  selectedSourcePricingPeriod === "null"
                    ? null
                    : selectedSourcePricingPeriod
                )
              ];

            const destinationPricingPeriod =
              destinationSchedule.pricingPeriods[
                getPriceMatrixIndex(destinationSchedule.pricingPeriods, selectedPeriod)
              ];

            onPricingImport(
              mergeRelationMatricesForImport(
                destinationPricingPeriod.relationMatrix,
                sourcePricingPeriod.relationMatrix
              ),
              sourcePricingPeriod.direction,
              sourceSchedule.currency
            );

            setShowPopup(false);
          }}
          onCancel={() => setShowPopup(false)}
        />
      )}
    </>
  );
};

export default ImportPricingButton;
