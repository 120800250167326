// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindUsersQueryVariables = Types.Exact<{
  partnerId?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
}>;

export type FindUsersQuery = {
  __typename?: "Query";
  findUsers: Array<{
    __typename?: "User";
    id: string;
    mail: string;
    displayName: string;
    roles: Array<Types.SupportedExternalRole>;
  }>;
};

export type UpdateRolesMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  roles: Array<Types.SupportedExternalRole> | Types.SupportedExternalRole;
}>;

export type UpdateRolesMutation = {
  __typename?: "Mutation";
  updateUserRoles: {
    __typename?: "User";
    id: string;
    roles: Array<Types.SupportedExternalRole>;
  };
};

export const FindUsersDocument = gql`
  query findUsers($partnerId: ID) {
    findUsers(partnerId: $partnerId) {
      id
      mail
      displayName
      roles
    }
  }
`;

/**
 * __useFindUsersQuery__
 *
 * To run a query within a React component, call `useFindUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUsersQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useFindUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<FindUsersQuery, FindUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindUsersQuery, FindUsersQueryVariables>(
    FindUsersDocument,
    options
  );
}
export function useFindUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindUsersQuery,
    FindUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindUsersQuery, FindUsersQueryVariables>(
    FindUsersDocument,
    options
  );
}
export function useFindUsersSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<FindUsersQuery, FindUsersQueryVariables>
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<FindUsersQuery, FindUsersQueryVariables>(
    FindUsersDocument,
    options
  );
}
export type FindUsersQueryHookResult = ReturnType<typeof useFindUsersQuery>;
export type FindUsersLazyQueryHookResult = ReturnType<typeof useFindUsersLazyQuery>;
export type FindUsersSuspenseQueryHookResult = ReturnType<
  typeof useFindUsersSuspenseQuery
>;
export const UpdateRolesDocument = gql`
  mutation updateRoles($id: ID!, $roles: [SupportedExternalRole!]!) {
    updateUserRoles(id: $id, roles: $roles) {
      id
      roles
    }
  }
`;

/**
 * __useUpdateRolesMutation__
 *
 * To run a mutation, you first call `useUpdateRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRolesMutation, { data, loading, error }] = useUpdateRolesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useUpdateRolesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateRolesMutation,
    UpdateRolesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateRolesMutation, UpdateRolesMutationVariables>(
    UpdateRolesDocument,
    options
  );
}
export type UpdateRolesMutationHookResult = ReturnType<typeof useUpdateRolesMutation>;
