@use "variables" as *;

.wrapper {
  text-align: left;
  margin-bottom: $spacing-2;
}

.tag {
  margin-right: $spacing-1;
}
