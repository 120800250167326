@use "variables" as *;

.wrapper {
  display: flex;
  flex-direction: row;
}

.dots {
  margin-left: $spacing-1;
  margin-right: $spacing-1;
}

.tag {
  display: flex;
  align-items: center;
  height: 28px;
  border: 1px solid $grayscale-50-color;
  border-radius: 28px;
  line-height: 22px;
  padding: $spacing-half $spacing-2;
}
