@use "variables" as *;

.input {
  width: 100%;

  button {
    color: $icon-primary-color;
  }
}

.closeButtonPlaceholder {
  margin-left: $spacing-1;
  margin-right: $spacing-2;
  order: 4;
  min-width: $spacing-4;
}
