@use "variables" as *;

.accordion {
  width: 100%;
  border-bottom: 1px solid $line-primary-color;
}

.accordion:first-child {
  border-top: 1px solid $line-primary-color;
}

.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: $spacing-2;
}

.headerIcon {
  margin-right: $spacing-1;
}

.headerSectionContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.heading {
  padding: 0;
  margin: 0;
}

.children {
  padding: $spacing-2;
}
