@use "variables" as *;

.wrapper {
  position: absolute;
  top: 13px;
  right: 4px;
}

.button {
  min-width: unset;
  min-height: unset;
  width: 28px;
  height: 24px;
  padding: 0;
}
