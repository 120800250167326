// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindAllAvailableBrandsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FindAllAvailableBrandsQuery = {
  __typename?: "Query";
  findAllAvailableBrands: Array<{
    __typename?: "Brand";
    id: string;
    name: string;
    created?: string | null;
    updated?: string | null;
    canBeRemovedFromPartner: boolean;
  }>;
};

export const FindAllAvailableBrandsDocument = gql`
  query FindAllAvailableBrands {
    findAllAvailableBrands {
      id
      name
      created
      updated
      canBeRemovedFromPartner
    }
  }
`;

/**
 * __useFindAllAvailableBrandsQuery__
 *
 * To run a query within a React component, call `useFindAllAvailableBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllAvailableBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllAvailableBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllAvailableBrandsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindAllAvailableBrandsQuery,
    FindAllAvailableBrandsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindAllAvailableBrandsQuery,
    FindAllAvailableBrandsQueryVariables
  >(FindAllAvailableBrandsDocument, options);
}
export function useFindAllAvailableBrandsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindAllAvailableBrandsQuery,
    FindAllAvailableBrandsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindAllAvailableBrandsQuery,
    FindAllAvailableBrandsQueryVariables
  >(FindAllAvailableBrandsDocument, options);
}
export function useFindAllAvailableBrandsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindAllAvailableBrandsQuery,
        FindAllAvailableBrandsQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindAllAvailableBrandsQuery,
    FindAllAvailableBrandsQueryVariables
  >(FindAllAvailableBrandsDocument, options);
}
export type FindAllAvailableBrandsQueryHookResult = ReturnType<
  typeof useFindAllAvailableBrandsQuery
>;
export type FindAllAvailableBrandsLazyQueryHookResult = ReturnType<
  typeof useFindAllAvailableBrandsLazyQuery
>;
export type FindAllAvailableBrandsSuspenseQueryHookResult = ReturnType<
  typeof useFindAllAvailableBrandsSuspenseQuery
>;
