@use "variables" as *;

.container {
  display: flex;
  justify-content: space-between;
}

.heading {
  display: inline-block;
  padding: 0;
  margin: 0;
}
