@use "variables" as *;

.table {
  thead {
    th:first-child {
      width: 400px;
      padding-left: $spacing-2 !important;
    }
    th:last-child {
      width: 300px;
    }
  }

  tbody tr > th {
    padding-top: $spacing-2 !important;
    padding-left: $spacing-2 !important;
    vertical-align: top;
  }
}
