@use "variables" as *;

.pagerWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-3;
  margin-bottom: $spacing-4;
}

.pagerItem {
  display: flex;
  column-gap: 12px;
  align-items: center;
}

.select {
  width: 95px;
}

.label {
  font-size: $font-size-small;
  color: $content-secondary-color;
}
