@use "variables" as *;

.listItem {
  counter-increment: bd-counter;
  display: flex;
  &:first-child {
    margin-bottom: $spacing-2;
  }
  &::before {
    content: counter(bd-counter) ".";
    width: 28px;
    padding-top: 31px;
  }
  &:not(:first-child)::before {
    padding-top: 7px;
  }
}

.grid {
  display: inline-grid;
  // grid-template-columns: calc(50% - 30px) calc(50% - 30px) 36px;
  grid-template-columns: calc(50% - 20px) calc(50% - 40px) 36px;
  // grid-template-columns: 50% 50% 36px;
  column-gap: $spacing-2;
  width: 100%;
}

.buttonWrapperFirstRow {
  margin-top: 24px;
}
