@use "variables" as *;
@use "../../helper/styles/styles.scss";

.stickyColumn {
  position: sticky;
  left: 0;
  z-index: 1;
}

.scrollableColumn {
  @include styles.scrollableColumn;
  z-index: 1;
  background-color: $bg-primary-color;
  height: styles.$topHeaderHeight;
  border-left: styles.$border-light;
  left: -1px;
}

.textCell {
  border-top: $border;
  border-bottom: $border;
  height: styles.$topHeaderHeight;
  line-height: styles.$topHeaderHeight;
  white-space: nowrap;
  padding-left: $spacing-1;
}
