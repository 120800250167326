import { Grid, GridCol } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";
import MapWithMarker from "../../../../shared/components/mapWithMarker/MapWithMarker";
import getMarkerArray from "../../../../shared/components/mapWithMarker/helper/getMarkerArray/getMarkerArray";
import MapPin from "../../../../shared/components/mapWithMarker/helper/mapPin/mapPin";
import * as css from "./GeoCoordinatesInput.scss";
import "maplibre-gl/dist/maplibre-gl.css";

type Props = {
  readOnly?: boolean;
};

const GeoCoordinatesInput: React.FC<Props> = ({ readOnly = false }) => {
  const { formatMessage } = useIntl();

  const [, { value: latitude }] = useField<string>("latitude");
  const [, { value: longitude }] = useField<string>("longitude");

  return (
    <>
      <Grid>
        <GridCol size={6}>
          <FormikInput
            name="latitude"
            label={formatMessage({ id: "station.latitude" })}
            placeholder="48.366512"
            type="text"
            readOnly={readOnly}
            required
          />
        </GridCol>
        <GridCol size={6}>
          <FormikInput
            name="longitude"
            label={formatMessage({ id: "station.longitude" })}
            placeholder="10.894446"
            type="text"
            readOnly={readOnly}
            required
          />
        </GridCol>
      </Grid>

      <div className={css.map}>
        <MapWithMarker
          markers={getMarkerArray([
            {
              lat: latitude,
              lng: longitude,
              pin: MapPin.GREEN_PIN,
            },
          ])}
        />
      </div>
    </>
  );
};

export default GeoCoordinatesInput;
