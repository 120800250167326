import { localizeDate } from "@flixbus-phx/marketplace-common";
import { isSameDay } from "date-fns";
import { PricingPeriod } from "../../../../shared/types/schema";

const formatPeriodToString = (period: PricingPeriod["period"] | undefined): string => {
  if (!period) {
    return "";
  }

  const start = new Date(period.start);
  const end = new Date(period.end);

  return isSameDay(start, end)
    ? localizeDate(start)
    : `${localizeDate(start)} - ${localizeDate(end)}`;
};

export default formatPeriodToString;
