@use "variables" as *;
@use "../../helpers/styles/vars.scss";

.text {
  line-height: vars.$cell-height;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.highlightedText {
  width: 90%; // Fixes: https://jira.mfb.io/browse/PHX-980
  float: right;
  padding-left: $spacing-2 !important;
}

.icon {
  display: inline-block;
  height: vars.$cell-height;
  padding: 16px $spacing-half 0 $spacing-half;
  width: 10%; // Fixes: https://jira.mfb.io/browse/PHX-980
}

.horizontal {
  padding: 0 32px;
  text-align: center;
}

.vertical {
  padding: 0 $spacing-4;
  text-align: left;
}

.highlight {
  background-color: $highlight-color;
}

.wrapper > span[class*="hcr-tooltip-target"] {
  display: block;
}
