// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import { PricingPeriodsFragmentDoc } from "../../../shared/helpers/fragments/pricingPeriods.generated";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type StationSequenceItemFragment = {
  __typename?: "Station";
  id: string;
  name: string;
  code?: string | null;
  countryCode?: string | null;
  isPublished: boolean;
  timeZone: {
    __typename?: "StationTimeZone";
    zoneId: string;
    utcOffset: string;
    observesDaylightSavingTime: boolean;
  };
};

export type StationTimeItemFragment = {
  __typename?: "StationTime";
  arrivalTime?: string | null;
  arrivalOffsetWeekdays: number;
  departureTime?: string | null;
  departureOffsetWeekdays: number;
  timeToStation?: string | null;
  arrivalOffsetDays: number;
  station: {
    __typename?: "Station";
    id: string;
    countryCode?: string | null;
    timeZone: {
      __typename?: "StationTimeZone";
      zoneId: string;
      utcOffset: string;
      observesDaylightSavingTime: boolean;
    };
  };
};

export type TripFragment = {
  __typename?: "Trip";
  id: string;
  number: number;
  direction: Types.TripDirection;
  readOnly: boolean;
  totalTripDuration: string;
  estimatedTripDuration?: string | null;
  serviceDays: {
    __typename?: "ServiceDays";
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  stationTimes: Array<{
    __typename?: "StationTime";
    arrivalTime?: string | null;
    arrivalOffsetWeekdays: number;
    departureTime?: string | null;
    departureOffsetWeekdays: number;
    timeToStation?: string | null;
    arrivalOffsetDays: number;
    station: {
      __typename?: "Station";
      id: string;
      countryCode?: string | null;
      timeZone: {
        __typename?: "StationTimeZone";
        zoneId: string;
        utcOffset: string;
        observesDaylightSavingTime: boolean;
      };
    };
  }>;
  schedule: { __typename?: "Schedule"; id: string };
};

export type FindScheduleWithTripsAndStationsQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
}>;

export type FindScheduleWithTripsAndStationsQuery = {
  __typename?: "Query";
  findSchedule: {
    __typename?: "Schedule";
    id: string;
    readOnly: boolean;
    stations: Array<{
      __typename?: "Station";
      id: string;
      name: string;
      code?: string | null;
      countryCode?: string | null;
      isPublished: boolean;
      timeZone: {
        __typename?: "StationTimeZone";
        zoneId: string;
        utcOffset: string;
        observesDaylightSavingTime: boolean;
      };
    }>;
    trips: Array<{
      __typename?: "Trip";
      id: string;
      number: number;
      direction: Types.TripDirection;
      readOnly: boolean;
      totalTripDuration: string;
      estimatedTripDuration?: string | null;
      serviceDays: {
        __typename?: "ServiceDays";
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
        sunday: boolean;
      };
      stationTimes: Array<{
        __typename?: "StationTime";
        arrivalTime?: string | null;
        arrivalOffsetWeekdays: number;
        departureTime?: string | null;
        departureOffsetWeekdays: number;
        timeToStation?: string | null;
        arrivalOffsetDays: number;
        station: {
          __typename?: "Station";
          id: string;
          countryCode?: string | null;
          timeZone: {
            __typename?: "StationTimeZone";
            zoneId: string;
            utcOffset: string;
            observesDaylightSavingTime: boolean;
          };
        };
      }>;
      schedule: { __typename?: "Schedule"; id: string };
    }>;
  };
};

export type AddTripToScheduleMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
  direction: Types.TripDirection;
}>;

export type AddTripToScheduleMutation = {
  __typename?: "Mutation";
  newTrip: {
    __typename?: "Trip";
    id: string;
    number: number;
    direction: Types.TripDirection;
    readOnly: boolean;
    totalTripDuration: string;
    estimatedTripDuration?: string | null;
    serviceDays: {
      __typename?: "ServiceDays";
      monday: boolean;
      tuesday: boolean;
      wednesday: boolean;
      thursday: boolean;
      friday: boolean;
      saturday: boolean;
      sunday: boolean;
    };
    stationTimes: Array<{
      __typename?: "StationTime";
      arrivalTime?: string | null;
      arrivalOffsetWeekdays: number;
      departureTime?: string | null;
      departureOffsetWeekdays: number;
      timeToStation?: string | null;
      arrivalOffsetDays: number;
      station: {
        __typename?: "Station";
        id: string;
        countryCode?: string | null;
        timeZone: {
          __typename?: "StationTimeZone";
          zoneId: string;
          utcOffset: string;
          observesDaylightSavingTime: boolean;
        };
      };
    }>;
    schedule: { __typename?: "Schedule"; id: string };
  };
};

export type AddStationToScheduleMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
  stationId: Types.Scalars["ID"]["input"];
  index?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type AddStationToScheduleMutation = {
  __typename?: "Mutation";
  addStationToSchedule?: {
    __typename?: "Schedule";
    id: string;
    readOnly: boolean;
    stations: Array<{
      __typename?: "Station";
      id: string;
      name: string;
      code?: string | null;
      countryCode?: string | null;
      isPublished: boolean;
      timeZone: {
        __typename?: "StationTimeZone";
        zoneId: string;
        utcOffset: string;
        observesDaylightSavingTime: boolean;
      };
    }>;
    trips: Array<{
      __typename?: "Trip";
      id: string;
      number: number;
      direction: Types.TripDirection;
      readOnly: boolean;
      totalTripDuration: string;
      estimatedTripDuration?: string | null;
      serviceDays: {
        __typename?: "ServiceDays";
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
        sunday: boolean;
      };
      stationTimes: Array<{
        __typename?: "StationTime";
        arrivalTime?: string | null;
        arrivalOffsetWeekdays: number;
        departureTime?: string | null;
        departureOffsetWeekdays: number;
        timeToStation?: string | null;
        arrivalOffsetDays: number;
        station: {
          __typename?: "Station";
          id: string;
          countryCode?: string | null;
          timeZone: {
            __typename?: "StationTimeZone";
            zoneId: string;
            utcOffset: string;
            observesDaylightSavingTime: boolean;
          };
        };
      }>;
      schedule: { __typename?: "Schedule"; id: string };
    }>;
    pricingPeriods: Array<{
      __typename?: "PricingPeriod";
      id: string;
      direction: Types.PriceDirection;
      period?: { __typename?: "Period"; start: any; end: any } | null;
      relationMatrix: {
        __typename?: "RelationMatrix";
        rows: Array<{
          __typename?: "RelationMatrixRow";
          entries: Array<{
            __typename?: "RelationMatrixEntry";
            type: Types.RelationType;
            price?: number | null;
            from: { __typename?: "Station"; id: string };
            to: { __typename?: "Station"; id: string };
          }>;
        }>;
      };
    }>;
  } | null;
};

export type RemoveStationFromScheduleMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
  stationId: Types.Scalars["ID"]["input"];
}>;

export type RemoveStationFromScheduleMutation = {
  __typename?: "Mutation";
  removeStationFromSchedule?: {
    __typename?: "Schedule";
    id: string;
    readOnly: boolean;
    stations: Array<{
      __typename?: "Station";
      id: string;
      name: string;
      code?: string | null;
      countryCode?: string | null;
      isPublished: boolean;
      timeZone: {
        __typename?: "StationTimeZone";
        zoneId: string;
        utcOffset: string;
        observesDaylightSavingTime: boolean;
      };
    }>;
    trips: Array<{
      __typename?: "Trip";
      id: string;
      number: number;
      direction: Types.TripDirection;
      readOnly: boolean;
      totalTripDuration: string;
      estimatedTripDuration?: string | null;
      serviceDays: {
        __typename?: "ServiceDays";
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
        sunday: boolean;
      };
      stationTimes: Array<{
        __typename?: "StationTime";
        arrivalTime?: string | null;
        arrivalOffsetWeekdays: number;
        departureTime?: string | null;
        departureOffsetWeekdays: number;
        timeToStation?: string | null;
        arrivalOffsetDays: number;
        station: {
          __typename?: "Station";
          id: string;
          countryCode?: string | null;
          timeZone: {
            __typename?: "StationTimeZone";
            zoneId: string;
            utcOffset: string;
            observesDaylightSavingTime: boolean;
          };
        };
      }>;
      schedule: { __typename?: "Schedule"; id: string };
    }>;
    pricingPeriods: Array<{
      __typename?: "PricingPeriod";
      id: string;
      direction: Types.PriceDirection;
      period?: { __typename?: "Period"; start: any; end: any } | null;
      relationMatrix: {
        __typename?: "RelationMatrix";
        rows: Array<{
          __typename?: "RelationMatrixRow";
          entries: Array<{
            __typename?: "RelationMatrixEntry";
            type: Types.RelationType;
            price?: number | null;
            from: { __typename?: "Station"; id: string };
            to: { __typename?: "Station"; id: string };
          }>;
        }>;
      };
    }>;
  } | null;
};

export type UpdateTripMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  serviceDays?: Types.InputMaybe<Types.ServiceDaysInput>;
  number?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type UpdateTripMutation = {
  __typename?: "Mutation";
  updateTrip: {
    __typename?: "Trip";
    id: string;
    number: number;
    direction: Types.TripDirection;
    readOnly: boolean;
    totalTripDuration: string;
    estimatedTripDuration?: string | null;
    serviceDays: {
      __typename?: "ServiceDays";
      monday: boolean;
      tuesday: boolean;
      wednesday: boolean;
      thursday: boolean;
      friday: boolean;
      saturday: boolean;
      sunday: boolean;
    };
    stationTimes: Array<{
      __typename?: "StationTime";
      arrivalTime?: string | null;
      arrivalOffsetWeekdays: number;
      departureTime?: string | null;
      departureOffsetWeekdays: number;
      timeToStation?: string | null;
      arrivalOffsetDays: number;
      station: {
        __typename?: "Station";
        id: string;
        countryCode?: string | null;
        timeZone: {
          __typename?: "StationTimeZone";
          zoneId: string;
          utcOffset: string;
          observesDaylightSavingTime: boolean;
        };
      };
    }>;
    schedule: { __typename?: "Schedule"; id: string };
  };
};

export type CopyTripMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type CopyTripMutation = {
  __typename?: "Mutation";
  copyTrip: {
    __typename?: "Trip";
    id: string;
    number: number;
    direction: Types.TripDirection;
    readOnly: boolean;
    totalTripDuration: string;
    estimatedTripDuration?: string | null;
    serviceDays: {
      __typename?: "ServiceDays";
      monday: boolean;
      tuesday: boolean;
      wednesday: boolean;
      thursday: boolean;
      friday: boolean;
      saturday: boolean;
      sunday: boolean;
    };
    stationTimes: Array<{
      __typename?: "StationTime";
      arrivalTime?: string | null;
      arrivalOffsetWeekdays: number;
      departureTime?: string | null;
      departureOffsetWeekdays: number;
      timeToStation?: string | null;
      arrivalOffsetDays: number;
      station: {
        __typename?: "Station";
        id: string;
        countryCode?: string | null;
        timeZone: {
          __typename?: "StationTimeZone";
          zoneId: string;
          utcOffset: string;
          observesDaylightSavingTime: boolean;
        };
      };
    }>;
    schedule: { __typename?: "Schedule"; id: string };
  };
};

export type ShiftTripMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  departureTime: Types.Scalars["String"]["input"];
}>;

export type ShiftTripMutation = {
  __typename?: "Mutation";
  shiftTrip: {
    __typename?: "Trip";
    id: string;
    stationTimes: Array<{
      __typename?: "StationTime";
      arrivalTime?: string | null;
      arrivalOffsetWeekdays: number;
      departureTime?: string | null;
      departureOffsetWeekdays: number;
      timeToStation?: string | null;
      arrivalOffsetDays: number;
      station: {
        __typename?: "Station";
        id: string;
        countryCode?: string | null;
        timeZone: {
          __typename?: "StationTimeZone";
          zoneId: string;
          utcOffset: string;
          observesDaylightSavingTime: boolean;
        };
      };
    }>;
  };
};

export type UpdateTripStationDepartureTimeMutationVariables = Types.Exact<{
  tripId: Types.Scalars["ID"]["input"];
  stationId: Types.Scalars["ID"]["input"];
  departureTime: Types.Scalars["String"]["input"];
}>;

export type UpdateTripStationDepartureTimeMutation = {
  __typename?: "Mutation";
  updateTripStationDepartureTime?: {
    __typename?: "Trip";
    id: string;
    totalTripDuration: string;
    estimatedTripDuration?: string | null;
    stationTimes: Array<{
      __typename?: "StationTime";
      arrivalTime?: string | null;
      arrivalOffsetWeekdays: number;
      departureTime?: string | null;
      departureOffsetWeekdays: number;
      timeToStation?: string | null;
      arrivalOffsetDays: number;
      station: {
        __typename?: "Station";
        id: string;
        countryCode?: string | null;
        timeZone: {
          __typename?: "StationTimeZone";
          zoneId: string;
          utcOffset: string;
          observesDaylightSavingTime: boolean;
        };
      };
    }>;
    schedule: { __typename?: "Schedule"; id: string };
  } | null;
};

export type UpdateTripStationArrivalTimeMutationVariables = Types.Exact<{
  tripId: Types.Scalars["ID"]["input"];
  stationId: Types.Scalars["ID"]["input"];
  arrivalTime: Types.Scalars["String"]["input"];
}>;

export type UpdateTripStationArrivalTimeMutation = {
  __typename?: "Mutation";
  updateTripStationArrivalTime?: {
    __typename?: "Trip";
    id: string;
    totalTripDuration: string;
    estimatedTripDuration?: string | null;
    stationTimes: Array<{
      __typename?: "StationTime";
      arrivalTime?: string | null;
      arrivalOffsetWeekdays: number;
      departureTime?: string | null;
      departureOffsetWeekdays: number;
      timeToStation?: string | null;
      arrivalOffsetDays: number;
      station: {
        __typename?: "Station";
        id: string;
        countryCode?: string | null;
        timeZone: {
          __typename?: "StationTimeZone";
          zoneId: string;
          utcOffset: string;
          observesDaylightSavingTime: boolean;
        };
      };
    }>;
    schedule: { __typename?: "Schedule"; id: string };
  } | null;
};

export type UpdateTripStationArrivalOffsetDaysMutationVariables = Types.Exact<{
  tripId: Types.Scalars["ID"]["input"];
  stationId: Types.Scalars["ID"]["input"];
  arrivalOffsetDays: Types.Scalars["Int"]["input"];
}>;

export type UpdateTripStationArrivalOffsetDaysMutation = {
  __typename?: "Mutation";
  updateTripStationArrivalOffsetDays?: {
    __typename?: "Trip";
    id: string;
    totalTripDuration: string;
    estimatedTripDuration?: string | null;
    stationTimes: Array<{
      __typename?: "StationTime";
      arrivalTime?: string | null;
      arrivalOffsetWeekdays: number;
      departureTime?: string | null;
      departureOffsetWeekdays: number;
      timeToStation?: string | null;
      arrivalOffsetDays: number;
      station: {
        __typename?: "Station";
        id: string;
        countryCode?: string | null;
        timeZone: {
          __typename?: "StationTimeZone";
          zoneId: string;
          utcOffset: string;
          observesDaylightSavingTime: boolean;
        };
      };
    }>;
  } | null;
};

export type DeleteTripStationTimeMutationVariables = Types.Exact<{
  tripId: Types.Scalars["ID"]["input"];
  stationId: Types.Scalars["ID"]["input"];
}>;

export type DeleteTripStationTimeMutation = {
  __typename?: "Mutation";
  deleteTripStationTime?: {
    __typename?: "Trip";
    id: string;
    totalTripDuration: string;
    estimatedTripDuration?: string | null;
    stationTimes: Array<{
      __typename?: "StationTime";
      arrivalTime?: string | null;
      arrivalOffsetWeekdays: number;
      departureTime?: string | null;
      departureOffsetWeekdays: number;
      timeToStation?: string | null;
      arrivalOffsetDays: number;
      station: {
        __typename?: "Station";
        id: string;
        countryCode?: string | null;
        timeZone: {
          __typename?: "StationTimeZone";
          zoneId: string;
          utcOffset: string;
          observesDaylightSavingTime: boolean;
        };
      };
    }>;
    schedule: { __typename?: "Schedule"; id: string };
  } | null;
};

export type DeleteTripMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type DeleteTripMutation = { __typename?: "Mutation"; deleteTrip: boolean };

export const StationSequenceItemFragmentDoc = gql`
  fragment StationSequenceItem on Station {
    id
    name
    code
    countryCode
    timeZone {
      zoneId
      utcOffset
      observesDaylightSavingTime
    }
    isPublished
  }
`;
export const StationTimeItemFragmentDoc = gql`
  fragment StationTimeItem on StationTime {
    station {
      id
      countryCode
      timeZone {
        zoneId
        utcOffset
        observesDaylightSavingTime
      }
    }
    arrivalTime
    arrivalOffsetWeekdays
    departureTime
    departureOffsetWeekdays
    timeToStation
    arrivalOffsetDays
  }
`;
export const TripFragmentDoc = gql`
  fragment Trip on Trip {
    id
    number
    direction
    readOnly
    totalTripDuration
    estimatedTripDuration
    serviceDays {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
    stationTimes {
      ...StationTimeItem
    }
    schedule {
      id
    }
  }
  ${StationTimeItemFragmentDoc}
`;
export const FindScheduleWithTripsAndStationsDocument = gql`
  query FindScheduleWithTripsAndStations($scheduleId: ID!) {
    findSchedule(id: $scheduleId) {
      id
      readOnly
      stations {
        ...StationSequenceItem
      }
      trips {
        ...Trip
      }
    }
  }
  ${StationSequenceItemFragmentDoc}
  ${TripFragmentDoc}
`;

/**
 * __useFindScheduleWithTripsAndStationsQuery__
 *
 * To run a query within a React component, call `useFindScheduleWithTripsAndStationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindScheduleWithTripsAndStationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindScheduleWithTripsAndStationsQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useFindScheduleWithTripsAndStationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindScheduleWithTripsAndStationsQuery,
    FindScheduleWithTripsAndStationsQueryVariables
  > &
    (
      | { variables: FindScheduleWithTripsAndStationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindScheduleWithTripsAndStationsQuery,
    FindScheduleWithTripsAndStationsQueryVariables
  >(FindScheduleWithTripsAndStationsDocument, options);
}
export function useFindScheduleWithTripsAndStationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindScheduleWithTripsAndStationsQuery,
    FindScheduleWithTripsAndStationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindScheduleWithTripsAndStationsQuery,
    FindScheduleWithTripsAndStationsQueryVariables
  >(FindScheduleWithTripsAndStationsDocument, options);
}
export function useFindScheduleWithTripsAndStationsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindScheduleWithTripsAndStationsQuery,
        FindScheduleWithTripsAndStationsQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindScheduleWithTripsAndStationsQuery,
    FindScheduleWithTripsAndStationsQueryVariables
  >(FindScheduleWithTripsAndStationsDocument, options);
}
export type FindScheduleWithTripsAndStationsQueryHookResult = ReturnType<
  typeof useFindScheduleWithTripsAndStationsQuery
>;
export type FindScheduleWithTripsAndStationsLazyQueryHookResult = ReturnType<
  typeof useFindScheduleWithTripsAndStationsLazyQuery
>;
export type FindScheduleWithTripsAndStationsSuspenseQueryHookResult = ReturnType<
  typeof useFindScheduleWithTripsAndStationsSuspenseQuery
>;
export const AddTripToScheduleDocument = gql`
  mutation addTripToSchedule($scheduleId: ID!, $direction: TripDirection!) {
    newTrip(direction: $direction, scheduleId: $scheduleId) {
      ...Trip
    }
  }
  ${TripFragmentDoc}
`;

/**
 * __useAddTripToScheduleMutation__
 *
 * To run a mutation, you first call `useAddTripToScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTripToScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTripToScheduleMutation, { data, loading, error }] = useAddTripToScheduleMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useAddTripToScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddTripToScheduleMutation,
    AddTripToScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddTripToScheduleMutation,
    AddTripToScheduleMutationVariables
  >(AddTripToScheduleDocument, options);
}
export type AddTripToScheduleMutationHookResult = ReturnType<
  typeof useAddTripToScheduleMutation
>;
export const AddStationToScheduleDocument = gql`
  mutation addStationToSchedule($scheduleId: ID!, $stationId: ID!, $index: Int) {
    addStationToSchedule(scheduleId: $scheduleId, stationId: $stationId, index: $index) {
      id
      readOnly
      ...PricingPeriods
      stations {
        ...StationSequenceItem
      }
      trips {
        ...Trip
      }
    }
  }
  ${PricingPeriodsFragmentDoc}
  ${StationSequenceItemFragmentDoc}
  ${TripFragmentDoc}
`;

/**
 * __useAddStationToScheduleMutation__
 *
 * To run a mutation, you first call `useAddStationToScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStationToScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStationToScheduleMutation, { data, loading, error }] = useAddStationToScheduleMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      stationId: // value for 'stationId'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useAddStationToScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddStationToScheduleMutation,
    AddStationToScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddStationToScheduleMutation,
    AddStationToScheduleMutationVariables
  >(AddStationToScheduleDocument, options);
}
export type AddStationToScheduleMutationHookResult = ReturnType<
  typeof useAddStationToScheduleMutation
>;
export const RemoveStationFromScheduleDocument = gql`
  mutation RemoveStationFromSchedule($scheduleId: ID!, $stationId: ID!) {
    removeStationFromSchedule(scheduleId: $scheduleId, stationId: $stationId) {
      id
      readOnly
      ...PricingPeriods
      stations {
        ...StationSequenceItem
      }
      trips {
        ...Trip
      }
    }
  }
  ${PricingPeriodsFragmentDoc}
  ${StationSequenceItemFragmentDoc}
  ${TripFragmentDoc}
`;

/**
 * __useRemoveStationFromScheduleMutation__
 *
 * To run a mutation, you first call `useRemoveStationFromScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStationFromScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStationFromScheduleMutation, { data, loading, error }] = useRemoveStationFromScheduleMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      stationId: // value for 'stationId'
 *   },
 * });
 */
export function useRemoveStationFromScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveStationFromScheduleMutation,
    RemoveStationFromScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RemoveStationFromScheduleMutation,
    RemoveStationFromScheduleMutationVariables
  >(RemoveStationFromScheduleDocument, options);
}
export type RemoveStationFromScheduleMutationHookResult = ReturnType<
  typeof useRemoveStationFromScheduleMutation
>;
export const UpdateTripDocument = gql`
  mutation updateTrip($id: ID!, $serviceDays: ServiceDaysInput, $number: Int) {
    updateTrip(id: $id, serviceDays: $serviceDays, number: $number) {
      ...Trip
    }
  }
  ${TripFragmentDoc}
`;

/**
 * __useUpdateTripMutation__
 *
 * To run a mutation, you first call `useUpdateTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTripMutation, { data, loading, error }] = useUpdateTripMutation({
 *   variables: {
 *      id: // value for 'id'
 *      serviceDays: // value for 'serviceDays'
 *      number: // value for 'number'
 *   },
 * });
 */
export function useUpdateTripMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTripMutation,
    UpdateTripMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTripMutation, UpdateTripMutationVariables>(
    UpdateTripDocument,
    options
  );
}
export type UpdateTripMutationHookResult = ReturnType<typeof useUpdateTripMutation>;
export const CopyTripDocument = gql`
  mutation copyTrip($id: ID!) {
    copyTrip(id: $id) {
      ...Trip
    }
  }
  ${TripFragmentDoc}
`;

/**
 * __useCopyTripMutation__
 *
 * To run a mutation, you first call `useCopyTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTripMutation, { data, loading, error }] = useCopyTripMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCopyTripMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CopyTripMutation,
    CopyTripMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CopyTripMutation, CopyTripMutationVariables>(
    CopyTripDocument,
    options
  );
}
export type CopyTripMutationHookResult = ReturnType<typeof useCopyTripMutation>;
export const ShiftTripDocument = gql`
  mutation shiftTrip($id: ID!, $departureTime: String!) {
    shiftTrip(id: $id, departureTime: $departureTime) {
      id
      stationTimes {
        ...StationTimeItem
      }
    }
  }
  ${StationTimeItemFragmentDoc}
`;

/**
 * __useShiftTripMutation__
 *
 * To run a mutation, you first call `useShiftTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftTripMutation, { data, loading, error }] = useShiftTripMutation({
 *   variables: {
 *      id: // value for 'id'
 *      departureTime: // value for 'departureTime'
 *   },
 * });
 */
export function useShiftTripMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ShiftTripMutation,
    ShiftTripMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ShiftTripMutation, ShiftTripMutationVariables>(
    ShiftTripDocument,
    options
  );
}
export type ShiftTripMutationHookResult = ReturnType<typeof useShiftTripMutation>;
export const UpdateTripStationDepartureTimeDocument = gql`
  mutation updateTripStationDepartureTime(
    $tripId: ID!
    $stationId: ID!
    $departureTime: String!
  ) {
    updateTripStationDepartureTime(
      tripId: $tripId
      stationId: $stationId
      departureTime: $departureTime
    ) {
      id
      totalTripDuration
      estimatedTripDuration
      stationTimes {
        ...StationTimeItem
      }
      schedule {
        id
      }
    }
  }
  ${StationTimeItemFragmentDoc}
`;

/**
 * __useUpdateTripStationDepartureTimeMutation__
 *
 * To run a mutation, you first call `useUpdateTripStationDepartureTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTripStationDepartureTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTripStationDepartureTimeMutation, { data, loading, error }] = useUpdateTripStationDepartureTimeMutation({
 *   variables: {
 *      tripId: // value for 'tripId'
 *      stationId: // value for 'stationId'
 *      departureTime: // value for 'departureTime'
 *   },
 * });
 */
export function useUpdateTripStationDepartureTimeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTripStationDepartureTimeMutation,
    UpdateTripStationDepartureTimeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateTripStationDepartureTimeMutation,
    UpdateTripStationDepartureTimeMutationVariables
  >(UpdateTripStationDepartureTimeDocument, options);
}
export type UpdateTripStationDepartureTimeMutationHookResult = ReturnType<
  typeof useUpdateTripStationDepartureTimeMutation
>;
export const UpdateTripStationArrivalTimeDocument = gql`
  mutation updateTripStationArrivalTime(
    $tripId: ID!
    $stationId: ID!
    $arrivalTime: String!
  ) {
    updateTripStationArrivalTime(
      tripId: $tripId
      stationId: $stationId
      arrivalTime: $arrivalTime
    ) {
      id
      totalTripDuration
      estimatedTripDuration
      stationTimes {
        ...StationTimeItem
      }
      schedule {
        id
      }
    }
  }
  ${StationTimeItemFragmentDoc}
`;

/**
 * __useUpdateTripStationArrivalTimeMutation__
 *
 * To run a mutation, you first call `useUpdateTripStationArrivalTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTripStationArrivalTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTripStationArrivalTimeMutation, { data, loading, error }] = useUpdateTripStationArrivalTimeMutation({
 *   variables: {
 *      tripId: // value for 'tripId'
 *      stationId: // value for 'stationId'
 *      arrivalTime: // value for 'arrivalTime'
 *   },
 * });
 */
export function useUpdateTripStationArrivalTimeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTripStationArrivalTimeMutation,
    UpdateTripStationArrivalTimeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateTripStationArrivalTimeMutation,
    UpdateTripStationArrivalTimeMutationVariables
  >(UpdateTripStationArrivalTimeDocument, options);
}
export type UpdateTripStationArrivalTimeMutationHookResult = ReturnType<
  typeof useUpdateTripStationArrivalTimeMutation
>;
export const UpdateTripStationArrivalOffsetDaysDocument = gql`
  mutation updateTripStationArrivalOffsetDays(
    $tripId: ID!
    $stationId: ID!
    $arrivalOffsetDays: Int!
  ) {
    updateTripStationArrivalOffsetDays(
      tripId: $tripId
      stationId: $stationId
      arrivalOffsetDays: $arrivalOffsetDays
    ) {
      id
      totalTripDuration
      estimatedTripDuration
      stationTimes {
        ...StationTimeItem
      }
    }
  }
  ${StationTimeItemFragmentDoc}
`;

/**
 * __useUpdateTripStationArrivalOffsetDaysMutation__
 *
 * To run a mutation, you first call `useUpdateTripStationArrivalOffsetDaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTripStationArrivalOffsetDaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTripStationArrivalOffsetDaysMutation, { data, loading, error }] = useUpdateTripStationArrivalOffsetDaysMutation({
 *   variables: {
 *      tripId: // value for 'tripId'
 *      stationId: // value for 'stationId'
 *      arrivalOffsetDays: // value for 'arrivalOffsetDays'
 *   },
 * });
 */
export function useUpdateTripStationArrivalOffsetDaysMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTripStationArrivalOffsetDaysMutation,
    UpdateTripStationArrivalOffsetDaysMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateTripStationArrivalOffsetDaysMutation,
    UpdateTripStationArrivalOffsetDaysMutationVariables
  >(UpdateTripStationArrivalOffsetDaysDocument, options);
}
export type UpdateTripStationArrivalOffsetDaysMutationHookResult = ReturnType<
  typeof useUpdateTripStationArrivalOffsetDaysMutation
>;
export const DeleteTripStationTimeDocument = gql`
  mutation deleteTripStationTime($tripId: ID!, $stationId: ID!) {
    deleteTripStationTime(tripId: $tripId, stationId: $stationId) {
      id
      totalTripDuration
      estimatedTripDuration
      stationTimes {
        ...StationTimeItem
      }
      schedule {
        id
      }
    }
  }
  ${StationTimeItemFragmentDoc}
`;

/**
 * __useDeleteTripStationTimeMutation__
 *
 * To run a mutation, you first call `useDeleteTripStationTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTripStationTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTripStationTimeMutation, { data, loading, error }] = useDeleteTripStationTimeMutation({
 *   variables: {
 *      tripId: // value for 'tripId'
 *      stationId: // value for 'stationId'
 *   },
 * });
 */
export function useDeleteTripStationTimeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTripStationTimeMutation,
    DeleteTripStationTimeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteTripStationTimeMutation,
    DeleteTripStationTimeMutationVariables
  >(DeleteTripStationTimeDocument, options);
}
export type DeleteTripStationTimeMutationHookResult = ReturnType<
  typeof useDeleteTripStationTimeMutation
>;
export const DeleteTripDocument = gql`
  mutation deleteTrip($id: ID!) {
    deleteTrip(id: $id)
  }
`;

/**
 * __useDeleteTripMutation__
 *
 * To run a mutation, you first call `useDeleteTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTripMutation, { data, loading, error }] = useDeleteTripMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTripMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTripMutation,
    DeleteTripMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteTripMutation, DeleteTripMutationVariables>(
    DeleteTripDocument,
    options
  );
}
export type DeleteTripMutationHookResult = ReturnType<typeof useDeleteTripMutation>;
