import { ScheduleStatus } from "../../../../shared/types/schema";

const allowedScheduleStatus = [
  ScheduleStatus.Published,
  ScheduleStatus.Accepted,
  ScheduleStatus.PricesPublished,
  ScheduleStatus.OnSale,
  ScheduleStatus.OutOfSale,
];
const allowedOriginalScheduleStatus = ScheduleStatus.OnSale;

const getIfCreateConceptChangeIsPossible = (
  scheduleStatus: ScheduleStatus,
  originalScheduleStatus: ScheduleStatus
) => {
  if (originalScheduleStatus !== allowedOriginalScheduleStatus) return false;

  return allowedScheduleStatus.includes(scheduleStatus);
};

export default getIfCreateConceptChangeIsPossible;
