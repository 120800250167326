import { Icon, IconPlus } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import { formatDateForApi, PeriodPicker } from "@flixbus-phx/marketplace-common";
import { areIntervalsOverlapping, isBefore, isSameDay } from "date-fns";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import { PricingPeriod, Schedule } from "../../../../shared/types/schema";
import * as css from "./AddPeriodButton.scss";

export type AddPeriodButtonProps = {
  existingPeriods: NonNullable<PricingPeriod["period"]>[];
  onPeriodAdd: (newPeriod: NonNullable<PricingPeriod["period"]>) => void;
  schedulePeriod: Schedule["period"];
};

const AddPeriodButton: React.FC<AddPeriodButtonProps> = ({
  existingPeriods,
  onPeriodAdd,
  schedulePeriod,
}) => {
  const { formatMessage } = useIntl();
  const [popupIsOpen, setPopupIsOpen] = React.useState(false);
  const [selectedStartDate, setSelectedStartDate] = React.useState<Date>();
  const [selectedEndDate, setselectedEndDate] = React.useState<Date>();

  let hasError = false;

  if (selectedStartDate && selectedEndDate) {
    const selectedInterval = { start: selectedStartDate, end: selectedEndDate };
    hasError = existingPeriods.some((period) => {
      const existingInterval = {
        start: new Date(period.start),
        end: new Date(period.end),
      };

      if (
        areIntervalsOverlapping(selectedInterval, existingInterval) ||
        isSameDay(selectedInterval.start, existingInterval.end) ||
        isSameDay(selectedInterval.end, existingInterval.start)
      ) {
        return true;
      }
      return false;
    });
  }

  const closePopup = () => {
    setPopupIsOpen(false);
    setSelectedStartDate(undefined);
    setselectedEndDate(undefined);
  };

  const today = new Date();
  const schedulePeriodStart = new Date(schedulePeriod!.start);
  const schedulePeriodEnd = new Date(schedulePeriod!.end);

  return (
    <>
      <Button
        appearance="link"
        Elem="a"
        extraClasses={css.button}
        onClick={() => setPopupIsOpen(true)}
      >
        <Icon InlineIcon={IconPlus} /> <FormattedMessage id="pricing.period.add" />
      </Button>
      <ConfirmationPopup
        active={popupIsOpen}
        titleText={formatMessage({ id: "pricing.period.add" })}
        onCancel={closePopup}
        onConfirm={() => {
          onPeriodAdd({
            start: formatDateForApi(selectedStartDate!),
            end: formatDateForApi(selectedEndDate!),
          });
          closePopup();
        }}
        confirmButtonDisabled={!selectedStartDate || !selectedEndDate || hasError}
        allowOverflow
      >
        <p className={css.text}>
          <FormattedMessage id="pricing.period.popup.content" />
        </p>
        <div className={css.inputWrapper}>
          <PeriodPicker
            earliestStartDate={
              isBefore(schedulePeriodStart, today) && isBefore(today, schedulePeriodEnd)
                ? today
                : schedulePeriodStart
            }
            latestEndDate={schedulePeriodEnd}
            periodStart={selectedStartDate}
            periodEnd={selectedEndDate}
            onChange={(start, end) => {
              setSelectedStartDate(start);
              setselectedEndDate(end);
            }}
            label={formatMessage({ id: "general.period" })}
            preventAutoFocus
            errorMessage={
              hasError
                ? formatMessage({ id: "pricing.period.error.overlapping" })
                : undefined
            }
          />
        </div>
      </ConfirmationPopup>
    </>
  );
};

export default AddPeriodButton;
