.buttonWrapper {
  display: flex;
  justify-content: center;
}

.panelButton {
  flex-grow: 1;
}

.buttonGroup {
  width: 100%;
}
