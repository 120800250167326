@use "variables" as *;

.tagContainer {
  border: 1px solid $grayscale-30-color;
  padding-top: $spacing-1;
  padding-right: $spacing-2;
  border-radius: $border-radius;
}

.plusIcon {
  margin-bottom: 2px;
  margin-right: 4px;
}

.addNewTag {
  margin-left: $spacing-2;
  margin-bottom: $spacing-1;
}

.addNewActive {
  cursor: pointer;
}
