// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import { PricingPeriodsFragmentDoc } from "../../../shared/helpers/fragments/pricingPeriods.generated";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type ScheduleWithPricesFragment = {
  __typename?: "Schedule";
  id: string;
  status: Types.ScheduleStatus;
  currency: Types.Currency;
  hasPrices: boolean;
  wasCreatedViaApi: boolean;
  canBeUsedForPriceImport: boolean;
  scheduleDirection: Types.ScheduleDirection;
  period: { __typename?: "SchedulePeriod"; start: any; end: any };
  line: { __typename?: "Line"; id: string };
  stations: Array<{
    __typename?: "Station";
    id: string;
    name: string;
    code?: string | null;
  }>;
  pricingPeriods: Array<{
    __typename?: "PricingPeriod";
    id: string;
    direction: Types.PriceDirection;
    period?: { __typename?: "Period"; start: any; end: any } | null;
    relationMatrix: {
      __typename?: "RelationMatrix";
      rows: Array<{
        __typename?: "RelationMatrixRow";
        entries: Array<{
          __typename?: "RelationMatrixEntry";
          type: Types.RelationType;
          price?: number | null;
          from: { __typename?: "Station"; id: string };
          to: { __typename?: "Station"; id: string };
        }>;
      }>;
    };
  }>;
};

export type FindPricingQueryVariables = Types.Exact<{
  ID: Types.Scalars["ID"]["input"];
}>;

export type FindPricingQuery = {
  __typename?: "Query";
  findSchedule: {
    __typename?: "Schedule";
    id: string;
    status: Types.ScheduleStatus;
    currency: Types.Currency;
    hasPrices: boolean;
    wasCreatedViaApi: boolean;
    canBeUsedForPriceImport: boolean;
    scheduleDirection: Types.ScheduleDirection;
    period: { __typename?: "SchedulePeriod"; start: any; end: any };
    line: { __typename?: "Line"; id: string };
    stations: Array<{
      __typename?: "Station";
      id: string;
      name: string;
      code?: string | null;
    }>;
    pricingPeriods: Array<{
      __typename?: "PricingPeriod";
      id: string;
      direction: Types.PriceDirection;
      period?: { __typename?: "Period"; start: any; end: any } | null;
      relationMatrix: {
        __typename?: "RelationMatrix";
        rows: Array<{
          __typename?: "RelationMatrixRow";
          entries: Array<{
            __typename?: "RelationMatrixEntry";
            type: Types.RelationType;
            price?: number | null;
            from: { __typename?: "Station"; id: string };
            to: { __typename?: "Station"; id: string };
          }>;
        }>;
      };
    }>;
  };
};

export type UpdatePricingPeriodsMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
  currency: Types.Currency;
  pricingPeriodInputs: Array<Types.PricingPeriodInput> | Types.PricingPeriodInput;
}>;

export type UpdatePricingPeriodsMutation = {
  __typename?: "Mutation";
  updatePricingPeriods: {
    __typename?: "Schedule";
    id: string;
    status: Types.ScheduleStatus;
    currency: Types.Currency;
    hasPrices: boolean;
    wasCreatedViaApi: boolean;
    canBeUsedForPriceImport: boolean;
    scheduleDirection: Types.ScheduleDirection;
    period: { __typename?: "SchedulePeriod"; start: any; end: any };
    line: { __typename?: "Line"; id: string };
    stations: Array<{
      __typename?: "Station";
      id: string;
      name: string;
      code?: string | null;
    }>;
    pricingPeriods: Array<{
      __typename?: "PricingPeriod";
      id: string;
      direction: Types.PriceDirection;
      period?: { __typename?: "Period"; start: any; end: any } | null;
      relationMatrix: {
        __typename?: "RelationMatrix";
        rows: Array<{
          __typename?: "RelationMatrixRow";
          entries: Array<{
            __typename?: "RelationMatrixEntry";
            type: Types.RelationType;
            price?: number | null;
            from: { __typename?: "Station"; id: string };
            to: { __typename?: "Station"; id: string };
          }>;
        }>;
      };
    }>;
  };
};

export type FindAllSchedulesForImportQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
}>;

export type FindAllSchedulesForImportQuery = {
  __typename?: "Query";
  findSchedule: {
    __typename?: "Schedule";
    id: string;
    line: {
      __typename?: "Line";
      schedules: Array<{
        __typename?: "Schedule";
        id: string;
        name: string;
        canBeUsedForPriceImport: boolean;
        pricingPeriods: Array<{
          __typename?: "PricingPeriod";
          period?: { __typename?: "Period"; start: any; end: any } | null;
        }>;
      }>;
    };
  };
};

export type FindSourceAndDestinationSchedulesForImportQueryVariables = Types.Exact<{
  sourceScheduleId: Types.Scalars["ID"]["input"];
  destinationScheduleId: Types.Scalars["ID"]["input"];
}>;

export type FindSourceAndDestinationSchedulesForImportQuery = {
  __typename?: "Query";
  findSourceSchedule: {
    __typename?: "Schedule";
    id: string;
    currency: Types.Currency;
    pricingPeriods: Array<{
      __typename?: "PricingPeriod";
      id: string;
      direction: Types.PriceDirection;
      period?: { __typename?: "Period"; start: any; end: any } | null;
      relationMatrix: {
        __typename?: "RelationMatrix";
        rows: Array<{
          __typename?: "RelationMatrixRow";
          entries: Array<{
            __typename?: "RelationMatrixEntry";
            type: Types.RelationType;
            price?: number | null;
            from: { __typename?: "Station"; id: string };
            to: { __typename?: "Station"; id: string };
          }>;
        }>;
      };
    }>;
  };
  findDestinationSchedule: {
    __typename?: "Schedule";
    id: string;
    currency: Types.Currency;
    pricingPeriods: Array<{
      __typename?: "PricingPeriod";
      id: string;
      direction: Types.PriceDirection;
      period?: { __typename?: "Period"; start: any; end: any } | null;
      relationMatrix: {
        __typename?: "RelationMatrix";
        rows: Array<{
          __typename?: "RelationMatrixRow";
          entries: Array<{
            __typename?: "RelationMatrixEntry";
            type: Types.RelationType;
            price?: number | null;
            from: { __typename?: "Station"; id: string };
            to: { __typename?: "Station"; id: string };
          }>;
        }>;
      };
    }>;
  };
};

export const ScheduleWithPricesFragmentDoc = gql`
  fragment ScheduleWithPrices on Schedule {
    id
    status
    currency
    hasPrices
    wasCreatedViaApi
    canBeUsedForPriceImport
    scheduleDirection
    period {
      start
      end
    }
    line {
      id
    }
    stations {
      id
      name
      code
    }
    ...PricingPeriods
  }
  ${PricingPeriodsFragmentDoc}
`;
export const FindPricingDocument = gql`
  query FindPricing($ID: ID!) {
    findSchedule(id: $ID) {
      ...ScheduleWithPrices
    }
  }
  ${ScheduleWithPricesFragmentDoc}
`;

/**
 * __useFindPricingQuery__
 *
 * To run a query within a React component, call `useFindPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPricingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useFindPricingQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindPricingQuery,
    FindPricingQueryVariables
  > &
    ({ variables: FindPricingQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindPricingQuery, FindPricingQueryVariables>(
    FindPricingDocument,
    options
  );
}
export function useFindPricingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindPricingQuery,
    FindPricingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindPricingQuery, FindPricingQueryVariables>(
    FindPricingDocument,
    options
  );
}
export function useFindPricingSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindPricingQuery,
        FindPricingQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<FindPricingQuery, FindPricingQueryVariables>(
    FindPricingDocument,
    options
  );
}
export type FindPricingQueryHookResult = ReturnType<typeof useFindPricingQuery>;
export type FindPricingLazyQueryHookResult = ReturnType<typeof useFindPricingLazyQuery>;
export type FindPricingSuspenseQueryHookResult = ReturnType<
  typeof useFindPricingSuspenseQuery
>;
export const UpdatePricingPeriodsDocument = gql`
  mutation UpdatePricingPeriods(
    $scheduleId: ID!
    $currency: Currency!
    $pricingPeriodInputs: [PricingPeriodInput!]!
  ) {
    updatePricingPeriods(
      scheduleId: $scheduleId
      currency: $currency
      pricingPeriodInputs: $pricingPeriodInputs
    ) {
      ...ScheduleWithPrices
    }
  }
  ${ScheduleWithPricesFragmentDoc}
`;

/**
 * __useUpdatePricingPeriodsMutation__
 *
 * To run a mutation, you first call `useUpdatePricingPeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePricingPeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePricingPeriodsMutation, { data, loading, error }] = useUpdatePricingPeriodsMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      currency: // value for 'currency'
 *      pricingPeriodInputs: // value for 'pricingPeriodInputs'
 *   },
 * });
 */
export function useUpdatePricingPeriodsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePricingPeriodsMutation,
    UpdatePricingPeriodsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdatePricingPeriodsMutation,
    UpdatePricingPeriodsMutationVariables
  >(UpdatePricingPeriodsDocument, options);
}
export type UpdatePricingPeriodsMutationHookResult = ReturnType<
  typeof useUpdatePricingPeriodsMutation
>;
export const FindAllSchedulesForImportDocument = gql`
  query FindAllSchedulesForImport($scheduleId: ID!) {
    findSchedule(id: $scheduleId) {
      id
      line {
        schedules {
          id
          name
          canBeUsedForPriceImport
          pricingPeriods {
            period {
              start
              end
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFindAllSchedulesForImportQuery__
 *
 * To run a query within a React component, call `useFindAllSchedulesForImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllSchedulesForImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllSchedulesForImportQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useFindAllSchedulesForImportQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindAllSchedulesForImportQuery,
    FindAllSchedulesForImportQueryVariables
  > &
    (
      | { variables: FindAllSchedulesForImportQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindAllSchedulesForImportQuery,
    FindAllSchedulesForImportQueryVariables
  >(FindAllSchedulesForImportDocument, options);
}
export function useFindAllSchedulesForImportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindAllSchedulesForImportQuery,
    FindAllSchedulesForImportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindAllSchedulesForImportQuery,
    FindAllSchedulesForImportQueryVariables
  >(FindAllSchedulesForImportDocument, options);
}
export function useFindAllSchedulesForImportSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindAllSchedulesForImportQuery,
        FindAllSchedulesForImportQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindAllSchedulesForImportQuery,
    FindAllSchedulesForImportQueryVariables
  >(FindAllSchedulesForImportDocument, options);
}
export type FindAllSchedulesForImportQueryHookResult = ReturnType<
  typeof useFindAllSchedulesForImportQuery
>;
export type FindAllSchedulesForImportLazyQueryHookResult = ReturnType<
  typeof useFindAllSchedulesForImportLazyQuery
>;
export type FindAllSchedulesForImportSuspenseQueryHookResult = ReturnType<
  typeof useFindAllSchedulesForImportSuspenseQuery
>;
export const FindSourceAndDestinationSchedulesForImportDocument = gql`
  query FindSourceAndDestinationSchedulesForImport(
    $sourceScheduleId: ID!
    $destinationScheduleId: ID!
  ) {
    findSourceSchedule: findSchedule(id: $sourceScheduleId) {
      id
      currency
      ...PricingPeriods
    }
    findDestinationSchedule: findSchedule(id: $destinationScheduleId) {
      id
      currency
      ...PricingPeriods
    }
  }
  ${PricingPeriodsFragmentDoc}
`;

/**
 * __useFindSourceAndDestinationSchedulesForImportQuery__
 *
 * To run a query within a React component, call `useFindSourceAndDestinationSchedulesForImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSourceAndDestinationSchedulesForImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSourceAndDestinationSchedulesForImportQuery({
 *   variables: {
 *      sourceScheduleId: // value for 'sourceScheduleId'
 *      destinationScheduleId: // value for 'destinationScheduleId'
 *   },
 * });
 */
export function useFindSourceAndDestinationSchedulesForImportQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindSourceAndDestinationSchedulesForImportQuery,
    FindSourceAndDestinationSchedulesForImportQueryVariables
  > &
    (
      | {
          variables: FindSourceAndDestinationSchedulesForImportQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindSourceAndDestinationSchedulesForImportQuery,
    FindSourceAndDestinationSchedulesForImportQueryVariables
  >(FindSourceAndDestinationSchedulesForImportDocument, options);
}
export function useFindSourceAndDestinationSchedulesForImportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindSourceAndDestinationSchedulesForImportQuery,
    FindSourceAndDestinationSchedulesForImportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindSourceAndDestinationSchedulesForImportQuery,
    FindSourceAndDestinationSchedulesForImportQueryVariables
  >(FindSourceAndDestinationSchedulesForImportDocument, options);
}
export function useFindSourceAndDestinationSchedulesForImportSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindSourceAndDestinationSchedulesForImportQuery,
        FindSourceAndDestinationSchedulesForImportQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindSourceAndDestinationSchedulesForImportQuery,
    FindSourceAndDestinationSchedulesForImportQueryVariables
  >(FindSourceAndDestinationSchedulesForImportDocument, options);
}
export type FindSourceAndDestinationSchedulesForImportQueryHookResult = ReturnType<
  typeof useFindSourceAndDestinationSchedulesForImportQuery
>;
export type FindSourceAndDestinationSchedulesForImportLazyQueryHookResult = ReturnType<
  typeof useFindSourceAndDestinationSchedulesForImportLazyQuery
>;
export type FindSourceAndDestinationSchedulesForImportSuspenseQueryHookResult =
  ReturnType<typeof useFindSourceAndDestinationSchedulesForImportSuspenseQuery>;
