import { Period, SchedulePeriod } from "../../../../shared/types/schema";
import { PricingPeriodsFromQuery } from "../../types";

const isActivePricingPeriodInvalid = (
  pricingPeriods: PricingPeriodsFromQuery[number][],
  activeIndex: number,
  schedulePeriod: SchedulePeriod | undefined | null,
  invalidPeriods: Period[]
) => {
  if (!pricingPeriods[activeIndex]) {
    return false;
  }
  if (!schedulePeriod) {
    return false;
  }

  if (pricingPeriods[activeIndex].period === null) {
    // default pricing period is based on the schedule period
    return invalidPeriods.some(
      (period) =>
        period.start === schedulePeriod.start && period.end === schedulePeriod.end
    );
  }

  // check if pricing period is affected by invalid periods
  return invalidPeriods.some(
    (period) =>
      period.start === pricingPeriods[activeIndex].period?.start &&
      period.end === pricingPeriods[activeIndex].period?.end
  );
};

export default isActivePricingPeriodInvalid;
