import {
  Icon,
  IconCheckmark,
  IconClose,
  IconEditSolid,
  IconLockSolid,
} from "@flixbus/honeycomb-icons-react";
import { Button, Heading } from "@flixbus/honeycomb-react";
import cx from "classnames";
import { Field, Formik } from "formik";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { Maybe } from "../../types/schema";
import Input from "../input/Input";
import * as css from "./EditableInput.scss";

type Props = {
  value?: Maybe<string | number>;
  validationType: Yup.StringSchema | Yup.NumberSchema;
  onValueChanged: (value: any) => void;
  readOnly?: boolean;
  info?: string;
  isLoading?: boolean;
  isHeading?: boolean;
  label?: string;
};

const EditableInput: React.FC<Props> = ({
  value,
  validationType,
  onValueChanged,
  readOnly = false,
  info,
  isLoading = false,
  isHeading = false,
  label = "",
}) => {
  const [editable, setEditable] = React.useState(false);
  const open = () => setEditable(true);
  const close = () => setEditable(false);
  const randomNumber = () => Math.floor(Math.random() * 99999) + 1;
  const inputIdRef = React.useRef(`editableInputValue${randomNumber()}`);

  const isValueEmpty = (): boolean => {
    if (value === undefined || value === null) return true;

    return typeof value === "string" && value.length === 0;
  };

  React.useEffect(() => {
    const elem = document.getElementById(inputIdRef.current);
    if (elem !== null) elem.focus();
  }, [editable]);

  if (editable && !readOnly) {
    return (
      <Formik
        validationSchema={Yup.object({
          editableInputValue: validationType,
        })}
        initialValues={{ editableInputValue: value != null ? value : "" }}
        onSubmit={async (values, formikBag) => {
          formikBag.setSubmitting(true);
          onValueChanged(values.editableInputValue);
          formikBag.setSubmitting(false);
          close();
        }}
        onReset={close}
        render={({ handleSubmit, handleReset }) => (
          <form noValidate onSubmit={handleSubmit} onReset={handleReset} onBlur={close}>
            <div className={css.flexBox}>
              <div
                className={cx(
                  css.inputFlex,
                  info ? css.inputInfoText : "",
                  isHeading ? css.inputFlexHeading : ""
                )}
              >
                <Field
                  type="text"
                  name="editableInputValue"
                  ariaLabel="Value"
                  required
                  render={(props: any) => (
                    <Input
                      {...props}
                      id={inputIdRef.current}
                      ariaLabel="Value"
                      maxLength="255"
                      info={info}
                      extraClasses={cx(css.input, isHeading ? css.inputHeading : "")}
                    />
                  )}
                />
              </div>
              <Button
                type="reset"
                appearance="danger"
                display="square"
                extraClasses={cx(css.cancelButton, isHeading ? css.buttonHeading : "")}
                aria-label="reset"
              >
                <Icon InlineIcon={IconClose} />
              </Button>
              <Button
                type="submit"
                appearance="secondary"
                display="square"
                // @ts-ignore
                onMouseDown={handleSubmit}
                extraClasses={cx(css.submitButton, isHeading ? css.buttonHeading : "")}
                aria-label="submit"
              >
                <Icon InlineIcon={IconCheckmark} />
              </Button>
            </div>
          </form>
        )}
      />
    );
  }

  const labelSuffix = label ? ": " : "";

  return (
    <>
      <div
        className={cx(
          css.notEditableWrapper,
          css.inputFlex,
          isLoading ? css.notEditableWrapperLoading : "",
          isHeading ? css.notEditableWrapperHeading : ""
        )}
        onClick={open}
        data-id="on-click-editable"
      >
        {isHeading ? (
          <Heading
            size={1}
            extraClasses={readOnly ? css.readOnlyText : css.notEditableText}
          >
            {isValueEmpty() ? (
              <i>
                <FormattedMessage id="general.input.placeholder" />
              </i>
            ) : (
              `${label}${labelSuffix}${value}`
            )}
          </Heading>
        ) : (
          <span
            className={readOnly ? css.readOnlyText : css.notEditableText}
            data-id="editable-text"
          >
            {isValueEmpty() ? (
              <i>
                <FormattedMessage id="general.input.placeholder" />
              </i>
            ) : (
              value
            )}
          </span>
        )}
        <span
          className={readOnly ? css.readOnlyEditIcon : css.notEditableEditIcon}
          data-id="edit-icon"
        >
          <Icon
            InlineIcon={readOnly ? IconLockSolid : IconEditSolid}
            size={isHeading ? 6 : 4}
          />
        </span>
      </div>
      {info && (
        <div data-id="infoText" className={css.infoText}>
          {info}
        </div>
      )}
    </>
  );
};

export default EditableInput;
