@use "variables" as *;

$icon-height: 24px;

.iconContainer {
  text-align: right;
  outline: none;
  height: $icon-height;
  margin-bottom: -$icon-height;
  position: relative;
  float: right;
}

.meatballIconContainer {
  float: right;
  margin-bottom: -$icon-height;
  cursor: pointer;
  border-bottom-left-radius: 5px;
  &:hover,
  &:focus {
    background-color: $grayscale-30-color;
    .meatballIcon {
      color: #fff;
    }
    > .menuContainer {
      display: block;
    }
  }
}

.meatballIcon {
  width: $icon-height;
  height: $icon-height;
}

.menuContainer {
  display: none;
  position: absolute;
  margin-top: -30px;
  margin-left: 25px;
  z-index: 1;
  text-align: left;
  white-space: nowrap;
  background-color: $grayscale-30-color;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
  border-radius: $border-radius;
  border-top-left-radius: 0px;
  overflow: hidden;
}

.menuItem {
  padding: $spacing-1 $spacing-2;
  background-color: $bg-primary-color;
  border-bottom: $border;
  &:last-child {
    border-bottom: none;
  }
  &:hover,
  &:focus {
    padding-left: 8px;
    border-left: 4px solid $ui-primary-light-color;
  }
}
