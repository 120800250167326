// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type NewLineMutationVariables = Types.Exact<{
  partnerId: Types.Scalars["ID"]["input"];
  generalSettings: Types.GeneralSettingsInput;
  capacityAndAmenities: Types.CapacityAndAmenitiesInput;
  forbiddenRelations: Array<Types.ForbiddenRelationInput> | Types.ForbiddenRelationInput;
}>;

export type NewLineMutation = {
  __typename?: "Mutation";
  newLine: { __typename?: "Line"; id: string };
};

export type FindPartnerDetailsForLineCreationQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type FindPartnerDetailsForLineCreationQuery = {
  __typename?: "Query";
  findPartner: {
    __typename?: "Partner";
    id: string;
    meansOfTransport?: Types.MeansOfTransport | null;
    defaultCirculation?: {
      __typename?: "Circulation";
      id: string;
      uuid: string;
      name: string;
      activeConfiguration?: {
        __typename?: "CirculationConfiguration";
        seatCapacity: number;
        bikeCapacity: number;
        wheelchairCapacity: number;
      } | null;
    } | null;
  };
};

export const NewLineDocument = gql`
  mutation NewLine(
    $partnerId: ID!
    $generalSettings: GeneralSettingsInput!
    $capacityAndAmenities: CapacityAndAmenitiesInput!
    $forbiddenRelations: [ForbiddenRelationInput!]!
  ) {
    newLine(
      partnerId: $partnerId
      generalSettings: $generalSettings
      capacityAndAmenities: $capacityAndAmenities
      forbiddenRelations: $forbiddenRelations
    ) {
      id
    }
  }
`;

/**
 * __useNewLineMutation__
 *
 * To run a mutation, you first call `useNewLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newLineMutation, { data, loading, error }] = useNewLineMutation({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      generalSettings: // value for 'generalSettings'
 *      capacityAndAmenities: // value for 'capacityAndAmenities'
 *      forbiddenRelations: // value for 'forbiddenRelations'
 *   },
 * });
 */
export function useNewLineMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NewLineMutation,
    NewLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<NewLineMutation, NewLineMutationVariables>(
    NewLineDocument,
    options
  );
}
export type NewLineMutationHookResult = ReturnType<typeof useNewLineMutation>;
export const FindPartnerDetailsForLineCreationDocument = gql`
  query findPartnerDetailsForLineCreation($id: ID!) {
    findPartner(id: $id) {
      id
      meansOfTransport
      defaultCirculation {
        id
        uuid
        name
        activeConfiguration {
          seatCapacity
          bikeCapacity
          wheelchairCapacity
        }
      }
    }
  }
`;

/**
 * __useFindPartnerDetailsForLineCreationQuery__
 *
 * To run a query within a React component, call `useFindPartnerDetailsForLineCreationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPartnerDetailsForLineCreationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPartnerDetailsForLineCreationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindPartnerDetailsForLineCreationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindPartnerDetailsForLineCreationQuery,
    FindPartnerDetailsForLineCreationQueryVariables
  > &
    (
      | { variables: FindPartnerDetailsForLineCreationQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindPartnerDetailsForLineCreationQuery,
    FindPartnerDetailsForLineCreationQueryVariables
  >(FindPartnerDetailsForLineCreationDocument, options);
}
export function useFindPartnerDetailsForLineCreationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindPartnerDetailsForLineCreationQuery,
    FindPartnerDetailsForLineCreationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindPartnerDetailsForLineCreationQuery,
    FindPartnerDetailsForLineCreationQueryVariables
  >(FindPartnerDetailsForLineCreationDocument, options);
}
export function useFindPartnerDetailsForLineCreationSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindPartnerDetailsForLineCreationQuery,
        FindPartnerDetailsForLineCreationQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindPartnerDetailsForLineCreationQuery,
    FindPartnerDetailsForLineCreationQueryVariables
  >(FindPartnerDetailsForLineCreationDocument, options);
}
export type FindPartnerDetailsForLineCreationQueryHookResult = ReturnType<
  typeof useFindPartnerDetailsForLineCreationQuery
>;
export type FindPartnerDetailsForLineCreationLazyQueryHookResult = ReturnType<
  typeof useFindPartnerDetailsForLineCreationLazyQuery
>;
export type FindPartnerDetailsForLineCreationSuspenseQueryHookResult = ReturnType<
  typeof useFindPartnerDetailsForLineCreationSuspenseQuery
>;
