import { Button, ButtonGroup, PopupSection } from "@flixbus/honeycomb-react";
import { Popup } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import StationSelect from "../../../../shared/components/stationSelect/StationSelect";
import { Station } from "../../../../shared/types/schema";
import * as css from "./StationAddPopup.scss";

export type StationAddPopupProps = {
  disallowedValues: Array<Station["id"]>;
  previousStationName: string;
  active: boolean;
  onClose: () => void;
  onConfirm: (stationId: Station["id"]) => void;
  onStationCreate: (inputValue?: string) => void;
};

const StationAddPopup: React.FC<StationAddPopupProps> = ({
  disallowedValues,
  previousStationName,
  active,
  onClose,
  onConfirm,
  onStationCreate,
}) => {
  const { formatMessage } = useIntl();
  const [selectedStation, setSelectedStation] = React.useState<Station["id"]>();

  const handleOnClose = () => {
    setSelectedStation(undefined);
    onClose();
  };

  const handleOnConfirm = () => {
    setSelectedStation(undefined);
    onConfirm(selectedStation!!);
  };

  return (
    <Popup active={active} onClose={handleOnClose} allowOverflow>
      <PopupSection type="title">
        <FormattedMessage
          id="timetable.addStation.popup.subtitle"
          values={{ previousStationName }}
        />
      </PopupSection>
      <PopupSection type="content">
        <div className={css.container}>
          <StationSelect
            label={formatMessage({ id: "timetable.addStation.label" })}
            onStationSelected={setSelectedStation}
            disallowedValues={disallowedValues}
            onFocus={() => {
              if (selectedStation) {
                setSelectedStation(undefined);
              }
            }}
            onStationCreateWithinPopup={(inputValue) => {
              onStationCreate(inputValue);
            }}
          />
        </div>
      </PopupSection>
      <PopupSection type="actions">
        <ButtonGroup alignment="center">
          <Button data-id="cancel-button" appearance="secondary" onClick={handleOnClose}>
            <FormattedMessage id="general.cancel" />
          </Button>
          <Button
            data-id="confirm-button"
            appearance="primary"
            disabled={!selectedStation}
            onClick={handleOnConfirm}
          >
            <FormattedMessage id="general.confirm" />
          </Button>
        </ButtonGroup>
      </PopupSection>
    </Popup>
  );
};

export default StationAddPopup;
