@use "variables" as *;

.headline {
  display: inline;
  margin-right: $spacing-2;
}

.formRow {
  padding-bottom: 24px;
}
