@use "../../../../shared/styles/form-elements";
@use "variables" as *;

.options {
  margin-top: $spacing-2;
}

.fineprint {
  @include form-elements.fineprint-below-input;
}
