@use "variables" as *;
@use "../../shared/styles/infobox.scss";

.wrapper {
  margin-bottom: $spacing-3;
  @include default-page-with-container;
}

.editButtonWrapper {
  padding-bottom: $spacing-2;
  border-bottom: $border;
  display: flex;
  justify-content: space-between;
}

.infoboxWrapper {
  @include default-page-with-container;
  margin-bottom: $spacing-4;
}

.infobox {
  @include infobox.infobox;
}
