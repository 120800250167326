import { makeVar } from "@apollo/client";
import { InvalidPricing } from "../usePriceInvalidForSchedule/InvalidPricing";

export const selectedStationVar = makeVar("");

export const priceInvalidForScheduleVar = makeVar<InvalidPricing>({
  scheduleId: "",
  invalidPeriods: [],
  priceInvalidMessages: [],
});

export const pricesInEditModeVar = makeVar<boolean>(false);

export const selectedBrandVar = makeVar("");
