@use "variables" as *;

.infoText {
  color: $content-secondary-color;
  display: flex;
}

.icon {
  margin-right: 3px;
}
