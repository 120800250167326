// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type ScheduleFragment = {
  __typename?: "Schedule";
  id: string;
  name: string;
  displayedScheduleStatus: Types.DisplayedScheduleStatus;
  deletable: boolean;
  isOutdated: boolean;
  period: { __typename?: "SchedulePeriod"; start: any; end: any };
};

export type SchedulesOfLineQueryVariables = Types.Exact<{
  lineId: Types.Scalars["ID"]["input"];
}>;

export type SchedulesOfLineQuery = {
  __typename?: "Query";
  findLine: {
    __typename?: "Line";
    id: string;
    schedules: Array<{
      __typename?: "Schedule";
      id: string;
      name: string;
      displayedScheduleStatus: Types.DisplayedScheduleStatus;
      deletable: boolean;
      isOutdated: boolean;
      conceptChangeOf?: { __typename?: "Schedule"; id: string } | null;
      conceptChanges: Array<{
        __typename?: "Schedule";
        publishedDate?: string | null;
        id: string;
        name: string;
        displayedScheduleStatus: Types.DisplayedScheduleStatus;
        deletable: boolean;
        isOutdated: boolean;
        period: { __typename?: "SchedulePeriod"; start: any; end: any };
      }>;
      period: { __typename?: "SchedulePeriod"; start: any; end: any };
    }>;
  };
};

export const ScheduleFragmentDoc = gql`
  fragment Schedule on Schedule {
    id
    name
    period {
      start
      end
    }
    displayedScheduleStatus
    deletable
    isOutdated
  }
`;
export const SchedulesOfLineDocument = gql`
  query SchedulesOfLine($lineId: ID!) {
    findLine(id: $lineId) {
      id
      schedules {
        ...Schedule
        conceptChangeOf {
          id
        }
        conceptChanges {
          ...Schedule
          publishedDate
        }
      }
    }
  }
  ${ScheduleFragmentDoc}
`;

/**
 * __useSchedulesOfLineQuery__
 *
 * To run a query within a React component, call `useSchedulesOfLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulesOfLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulesOfLineQuery({
 *   variables: {
 *      lineId: // value for 'lineId'
 *   },
 * });
 */
export function useSchedulesOfLineQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SchedulesOfLineQuery,
    SchedulesOfLineQueryVariables
  > &
    ({ variables: SchedulesOfLineQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SchedulesOfLineQuery, SchedulesOfLineQueryVariables>(
    SchedulesOfLineDocument,
    options
  );
}
export function useSchedulesOfLineLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SchedulesOfLineQuery,
    SchedulesOfLineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SchedulesOfLineQuery,
    SchedulesOfLineQueryVariables
  >(SchedulesOfLineDocument, options);
}
export function useSchedulesOfLineSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        SchedulesOfLineQuery,
        SchedulesOfLineQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    SchedulesOfLineQuery,
    SchedulesOfLineQueryVariables
  >(SchedulesOfLineDocument, options);
}
export type SchedulesOfLineQueryHookResult = ReturnType<typeof useSchedulesOfLineQuery>;
export type SchedulesOfLineLazyQueryHookResult = ReturnType<
  typeof useSchedulesOfLineLazyQuery
>;
export type SchedulesOfLineSuspenseQueryHookResult = ReturnType<
  typeof useSchedulesOfLineSuspenseQuery
>;
