@use "variables" as *;

.container {
  margin-top: $spacing-2;
}

.dataTable thead th {
  font-size: 14px;
}

.noResultsTable {
  border: 1px solid var(--hcr-line-primary-color);
  border-radius: var(--hcr-primary-border-radius);
  height: 150px;
  text-align: center;
  padding-top: $spacing-2;
  padding-left: 200px;
  padding-right: 200px;
}

.heading,
.noResultsTable {
  color: var(--hcr-content-secondary-color);
}

.noBrandsText {
  margin-top: 0;
}
