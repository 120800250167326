@use "variables" as *;

.button {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;

  height: $input-height-desktop;
  min-width: 142px;
  max-width: 100%;

  padding: 0 $spacing-2;
  border-radius: $border-radius;

  font-weight: $font-weight-semibold;
  line-height: $input-height-desktop;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  background: $button-primary-color;
  transition: background 250ms ease;
  cursor: pointer;
  &:hover {
    background-image: $hover-layer-color;
  }
}

.input {
  display: none;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 63px;
  column-gap: $spacing-1;
}

.uploadInfo {
  color: $content-secondary-color;
  font-size: $font-size-small;
  margin-top: $spacing-half;
}
