@use "variables" as *;

.errorMessage {
  color: $danger-dark-color;
  display: inline !important;
}

.editableInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.calendarWrapper {
  padding-top: $spacing-1;
}

.editableInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.calendarWrapper {
  padding-top: $spacing-1;
}
