@use "variables" as *;

.unitInputLabel {
  color: $content-secondary-color;
  padding-bottom: 2px;
}

.input {
  border-radius: $border-radius 0 0 $border-radius;
}

.errorText {
  color: $danger-dark-color;
}

.finePrint {
  font-size: $font-size-small;
}
