import * as React from "react";
import MapWithMarker from "../../../../shared/components/mapWithMarker/MapWithMarker";
import getMarkerArray from "../../../../shared/components/mapWithMarker/helper/getMarkerArray/getMarkerArray";
import MapPin from "../../../../shared/components/mapWithMarker/helper/mapPin/mapPin";
import { NearbyStations } from "../../../../shared/hooks/useNearbyStations/useNearbyStations";
import * as css from "./NearbyStationMap.scss";

type Props = {
  stations: NearbyStations;
  latitude: string;
  longitude: string;
};

const NearbyStationMap: React.FC<Props> = ({ stations, latitude, longitude }) => {
  return (
    <div className={css.map}>
      <MapWithMarker
        markers={getMarkerArray([
          { lat: latitude, lng: longitude, pin: MapPin.GREEN_PIN },
          ...stations.map((station) => ({
            lat: station.latitude,
            lng: station.longitude,
            pin: MapPin.GREEN_DOT,
            tooltipContent: <div>{station.name}</div>,
          })),
        ])}
      />
    </div>
  );
};

export default NearbyStationMap;
