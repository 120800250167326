import { formatMessage } from "@flixbus-phx/marketplace-common";
import * as Yup from "yup";
import { priceRegex } from "../../../../shared/helpers/priceInputFormatter/priceInputFormatter";
import {
  BookableLuggagePolicyInput,
  Currency,
  DisabilitySupportRegistrationType,
  DisabilitySupportType,
  LengthUnit,
  LuggageFeeType,
  PointOfSaleType,
  PolicyType,
  TimeUnit,
  WeightUnit,
} from "../../../../shared/types/schema";

const yesNoSelect = Yup.boolean().nullable();

const positiveIntegerValueSchema = () =>
  Yup.number()
    .positive(() => formatMessage("error.validation.equalOrGreaterThanOne"))
    .integer(() => formatMessage("error.validation.numberNoDecimals"))
    .typeError(() => formatMessage("error.validation.mustBeNumber"));

const maxWeightSchema = Yup.object().shape({
  value: positiveIntegerValueSchema(),
});

const maxDimensionSchema = () =>
  Yup.object().shape({
    value: Yup.string().matches(
      /^(([1-9][0-9]*\s?[x]{1}\s?){2}[1-9][0-9]*)$/,
      formatMessage("error.validation.doesNotFitFormatX", {
        format: formatMessage("policies.maxDimension.info"),
      })
    ),
  });

export const includedLuggageSchema = () =>
  Yup.object().shape({
    inTicketIncluded: yesNoSelect,
    luggageSpecification: Yup.object().when("inTicketIncluded", (inTicketIncluded) => {
      if (inTicketIncluded) {
        return Yup.object().shape({
          maxWeight: maxWeightSchema,
          maxDimension: maxDimensionSchema(),
          maxPieces: positiveIntegerValueSchema(),
        });
      }

      return Yup.object();
    }),
  });

const pointOfSaleSchema = Yup.string().oneOf(Object.values(PointOfSaleType));

const bookableUntilSchema = () =>
  Yup.object().shape({
    value: Yup.number()
      .min(0, formatMessage("error.validation.equalOrGreaterThanZero"))
      .max(9999, formatMessage("error.validation.equalOrLessThanX", { value: 9999 }))
      .integer(formatMessage("error.validation.numberNoDecimals"))
      .typeError(formatMessage("error.validation.mustBeNumber")),
    unit: Yup.mixed().oneOf(
      [TimeUnit.Hours, TimeUnit.Days],
      formatMessage("error.validation.noTimeUnitSelected")
    ),
  });

const feePriceSchema = () =>
  Yup.object().shape({
    value: Yup.string().matches(
      priceRegex,
      formatMessage("error.validation.doesNotFitFormatX", {
        format: "--,--",
      })
    ),
  });

const feeTypeSchema = Yup.string().oneOf(Object.values(LuggageFeeType));

const getLuggageSpecificationSchema = (
  configuration: Array<
    keyof NonNullable<BookableLuggagePolicyInput["luggageSpecification"]>
  >
) => {
  return Yup.object()
    .shape({
      maxWeight: maxWeightSchema,
      maxDimension: maxDimensionSchema(),
      feeType: feeTypeSchema,
      feePrice: feePriceSchema(),
      pointOfSale: pointOfSaleSchema,
      bookableUntil: Yup.object().when("pointOfSale", {
        is: PointOfSaleType.OnBoard,
        then: () => Yup.object(),
        otherwise: bookableUntilSchema,
      }),
    })
    .pick(configuration);
};

export const getBookableLuggageSchema = (
  configuration: Array<
    keyof NonNullable<BookableLuggagePolicyInput["luggageSpecification"]>
  >
) => {
  return Yup.object().shape({
    bookable: yesNoSelect,
    luggageSpecification: Yup.object().when("bookable", (bookable) => {
      if (bookable) {
        return getLuggageSpecificationSchema(configuration);
      }

      return Yup.object();
    }),
  });
};

export const getUnaccompaniedMinorSpecificationSchema = (isAllowed: boolean) => {
  if (isAllowed) {
    return Yup.object().shape({
      interconnectionTravelAllowed: Yup.boolean(),
      interconnectionTravel: Yup.object().nullable().shape({
        parentalConsentRequired: Yup.boolean(),
      }),
      nightTravelAllowed: Yup.boolean(),
      nightTravel: Yup.object().nullable().shape({
        parentalConsentRequired: Yup.boolean(),
      }),
      internationalTravelAllowed: Yup.boolean(),
      internationalTravel: Yup.object().nullable().shape({
        parentalConsentRequired: Yup.boolean(),
      }),
      minAgeYears: Yup.number()
        .min(0, formatMessage("error.validation.greaterThanZero"))
        .max(20, formatMessage("error.validation.equalOrLessThanX", { value: "20" }))
        .integer(formatMessage("error.validation.numberNoDecimals"))
        .typeError(formatMessage("error.validation.mustBeNumber"))
        .nullable()
        .optional(),
    });
  }
  return Yup.object();
};

export const getPassengerWithDisabilitySpecificationSchema = (isSupported: boolean) => {
  if (isSupported) {
    return Yup.object().shape({
      supportingServices: Yup.array().of(
        Yup.string().oneOf(Object.values(DisabilitySupportType))
      ),
      registrationInstruction: Yup.string()
        .nullable()
        .oneOf([...Object.values(DisabilitySupportRegistrationType), null])
        .optional(),
      registrationUntil: Yup.object().when("registrationInstruction", {
        is: DisabilitySupportRegistrationType.ContactCustomerService,
        then: bookableUntilSchema,
        otherwise: () => Yup.object(),
      }),
      proofOfDisabilityRequired: Yup.boolean(),
    });
  }

  return Yup.object();
};

export const getPolicySchema = () => {
  return Yup.object().shape({
    luggageAllowance: Yup.object().shape({
      handLuggagePolicy: includedLuggageSchema(),
      checkInLuggagePolicy: includedLuggageSchema(),
      additionalLuggagePolicy: getBookableLuggageSchema([
        "pointOfSale",
        "maxWeight",
        "maxDimension",
        "feePrice",
        "feeType",
      ]),
    }),
    specialLuggageAndBuggies: Yup.object().shape({
      specialLuggagePolicy: getBookableLuggageSchema([
        "pointOfSale",
        "bookableUntil",
        "maxWeight",
        "maxDimension",
        "feePrice",
        "feeType",
      ]),
      pushchairPolicy: getBookableLuggageSchema([
        "pointOfSale",
        "bookableUntil",
        "feePrice",
        "feeType",
      ]),
    }),
    unaccompaniedMinors: Yup.object().shape({
      allowed: Yup.boolean(),
      unaccompaniedMinorSpecification: Yup.object().when("allowed", (allowed) =>
        getUnaccompaniedMinorSpecificationSchema(allowed as unknown as boolean)
      ),
    }),
    passengerWithDisability: Yup.object().shape({
      supported: Yup.boolean().nullable(),
      passengerWithDisabilitySpecification: Yup.object().when("supported", (supported) =>
        getPassengerWithDisabilitySpecificationSchema(supported as unknown as boolean)
      ),
    }),
    bikesSurfboardsAndSkies: Yup.object().shape({
      bikePolicy: getBookableLuggageSchema(["pointOfSale", "bookableUntil", "feePrice"]),
      surfboardPolicy: getBookableLuggageSchema([
        "pointOfSale",
        "bookableUntil",
        "feePrice",
      ]),
      skiEquipmentPolicy: getBookableLuggageSchema([
        "pointOfSale",
        "bookableUntil",
        "feePrice",
      ]),
    }),
    pets: Yup.object().shape({
      petPolicy: getBookableLuggageSchema(["pointOfSale", "bookableUntil", "feePrice"]),
    }),
  });
};

export default () => {
  return Yup.object().shape({
    units: Yup.object().shape({
      weightUnit: Yup.string()
        .oneOf([WeightUnit.Kilograms, WeightUnit.Pounds])
        .required(formatMessage("error.validation.required")),
      lengthUnit: Yup.string()
        .oneOf([LengthUnit.Centimeters, LengthUnit.Inches])
        .required(formatMessage("error.validation.required")),
      currency: Yup.string()
        .oneOf(Object.values(Currency))
        .required(formatMessage("error.validation.required")),
    }),
    policies: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().oneOf(Object.values(PolicyType)),
        policy: getPolicySchema(),
        additionalInformation: Yup.string().max(
          255,
          formatMessage("error.validation.maxCharacters", { numCharacters: 255 })
        ),
      })
    ),
  });
};
